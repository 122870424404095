var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ev-orders" },
    [
      _c(
        "div",
        { staticClass: "title-row d-flex flex-row align-items-center py-3" },
        [
          _c("h4", { staticClass: "mx-2 my-2 mb-0" }, [
            _vm._v("\n      Orders\n    ")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "text-muted" }, [
            _vm._v(
              "Showing " +
                _vm._s(_vm.lowerBound) +
                " - " +
                _vm._s(_vm.upperBound) +
                " results of "
            ),
            _vm.upperBound < _vm.currentOffset + _vm.resultsPerPage
              ? _c("span", [_vm._v(_vm._s(_vm.upperBound))])
              : _c("span", [_vm._v("Many")])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "form form-inline ml-auto",
              attrs: { id: "zn-page-select" }
            },
            [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "resultsPerPage" } }, [
                  _vm._v("Results per page:")
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.resultsPerPage,
                        expression: "resultsPerPage"
                      }
                    ],
                    staticClass: "form-control input-sm",
                    attrs: { name: "resultsPerPage" },
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.resultsPerPage = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.changeResultsPerPage
                      ]
                    }
                  },
                  [
                    _c("option", [_vm._v("20")]),
                    _vm._v(" "),
                    _c("option", [_vm._v("50")]),
                    _vm._v(" "),
                    _c("option", [_vm._v("100")]),
                    _vm._v(" "),
                    _c("option", [_vm._v("200")]),
                    _vm._v(" "),
                    _c("option", [_vm._v("1000")])
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.previousPageUrl
                ? _c("span", [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-outline-secondary px-2 ml-2",
                        attrs: { href: _vm.previousPageUrl }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-chevron-left pr-2",
                          attrs: { "aria-hidden": "true" }
                        }),
                        _vm._v("Previous")
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.nextPageUrl
                ? _c("span", [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-outline-secondary px-2 ml-2",
                        attrs: { href: _vm.nextPageUrl }
                      },
                      [
                        _vm._v("Next"),
                        _c("i", {
                          staticClass: "fa fa-chevron-right pl-2",
                          attrs: { "aria-hidden": "true" }
                        })
                      ]
                    )
                  ])
                : _vm._e()
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoadingOrders,
            expression: "isLoadingOrders"
          }
        ]
      }),
      _vm._v(" "),
      _c("div", { staticClass: "table-responsive" }, [
        _c("table", { staticClass: "table", attrs: { id: "orders-table" } }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "tbody",
            [
              _vm.orders.length > 0
                ? _vm._l(_vm.orders, function(order) {
                    return _c("tr", [
                      _c("td", { staticClass: "text-nowrap" }, [
                        _c("div", { staticClass: "btn-group" }, [
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-outline-primary btn-sm d-flex align-items-center",
                              staticStyle: {
                                "font-family": "monospace",
                                "font-size": "12px"
                              },
                              attrs: {
                                type: "button",
                                href:
                                  "/" +
                                  _vm.user.zincUserId +
                                  "/orders/" +
                                  order.request_id
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.shortenStr(order.request_id, 22))
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(1, true),
                          _vm._v(" "),
                          _c("div", { staticClass: "dropdown-menu" }, [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: {
                                  href:
                                    "https://" +
                                    _vm.currentClientToken +
                                    ":@api.zinc.io/v1/orders/" +
                                    order.request_id,
                                  target: "_blank"
                                }
                              },
                              [_vm._v("View JSON")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    _vm.copyToClip(order.request_id)
                                  }
                                }
                              },
                              [_vm._v("Copy ID")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    _vm.copyToClip(
                                      JSON.stringify(
                                        _vm.getData(
                                          order,
                                          "request.client_notes",
                                          ""
                                        )
                                      )
                                    )
                                  }
                                }
                              },
                              [_vm._v("Copy Client Note")]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        order.cancellation_request_ids
                          ? _c(
                              "div",
                              { staticClass: "pt-2" },
                              [
                                _c("strong", [
                                  _vm._v("Cancellation request IDs:")
                                ]),
                                _vm._v(" "),
                                _vm._l(order.cancellation_request_ids, function(
                                  cancellation_request_id
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-family": "monospace",
                                        "font-size": "smaller"
                                      }
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "https://" +
                                              _vm.currentClientToken +
                                              ":@api.zinc.io/v1/cancellations/" +
                                              cancellation_request_id,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(cancellation_request_id)
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        order.return_request_ids
                          ? _c(
                              "div",
                              { staticClass: "pt-2" },
                              [
                                _c("strong", [_vm._v("Return request IDs:")]),
                                _vm._v(" "),
                                _vm._l(order.return_request_ids, function(
                                  return_id
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-family": "monospace",
                                        "font-size": "smaller"
                                      }
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            target: "_blank",
                                            href:
                                              "https://" +
                                              _vm.currentClientToken +
                                              ":@api.zinc.io/v1/returns/" +
                                              return_id
                                          }
                                        },
                                        [_vm._v(_vm._s(return_id))]
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("td", { staticStyle: { "white-space": "nowrap" } }, [
                        _vm._v(
                          _vm._s(
                            order.request
                              ? _vm.getPrettyDate(order.request._created_at)
                              : _vm.getPrettyDate(order._created_at)
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", { staticStyle: { "white-space": "nowrap" } }, [
                        _vm._v(
                          _vm._s(
                            order._created_at
                              ? _vm.getPrettyDate(order._created_at)
                              : ""
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", {
                          staticStyle: {
                            width: "75px",
                            display: "inline-block"
                          },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.getPrettyMarketName(
                                _vm.getData(order, "request.retailer", "")
                              )
                            )
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "zn-price" }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.getPrettyOrderTotal(order))
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "zn-price" }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.getPrettyMarketPrice(
                                _vm.getData(order, "request.retailer", ""),
                                _vm.getData(order, "request.max_price", 0)
                              )
                            )
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "zn-order-state",
                          class: _vm.getOrderRowStyles(order),
                          attrs: {
                            title:
                              (order.message
                                ? order.message
                                : order.data
                                ? order.data.message ||
                                  order.data.msg ||
                                  order.data.details
                                : "") || ""
                          }
                        },
                        [
                          _c("b", [
                            _vm._v(_vm._s(_vm.getPrettyOrderStateString(order)))
                          ]),
                          _vm._v(" "),
                          order.code
                            ? _c("div", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(order.code) +
                                    "\n              "
                                )
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "zn-price" }, [
                        _vm._v(
                          _vm._s(
                            _vm.getPrettyClientNotes(
                              _vm.getData(order, "request.client_notes", "")
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "zn-price" }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.getTrackingLinks(order))
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "zn-price" }, [
                        _vm._v(
                          _vm._s(
                            order.merchant_order_ids &&
                              order.merchant_order_ids[0]
                              ? order.merchant_order_ids[0].merchant_order_id
                              : ""
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("div", { staticClass: "dropdown" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-secondary btn-sm dropdown-toggle",
                              attrs: {
                                type: "button",
                                "data-toggle": "dropdown",
                                "aria-expanded": "false"
                              }
                            },
                            [
                              _vm._v(
                                "\n                  Actions\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "dropdown-menu" }, [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: {
                                  href:
                                    "/" +
                                    _vm.user.zincUserId +
                                    "/orders/" +
                                    order.request_id
                                }
                              },
                              [_vm._v("View")]
                            ),
                            _vm._v(" "),
                            order._type == "error" &&
                            _vm.getData(order, "code", "") ==
                              "request_processing"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item text-danger",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        _vm.abortOrder(order.request_id)
                                      }
                                    }
                                  },
                                  [_vm._v("Abort")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            order._type == "error" &&
                            _vm.getData(order, "code", "") !=
                              "request_processing"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        _vm.retryOrder(order.request_id)
                                      }
                                    }
                                  },
                                  [_vm._v("Retry")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            (_vm.getData(order, "request.retailer", "") ==
                              "amazon" ||
                              _vm.getData(order, "request.retailer", "") ==
                                "amazon_uk") &&
                            order._type == "order_response"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item text-danger",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        _vm.cancelOrder(order.request_id)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v("Cancel"),
                                    order.cancellation_request_ids
                                      ? _c("span", [_vm._v(" Again")])
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e()
                          ])
                        ])
                      ])
                    ])
                  })
                : [
                    _c("h4", { staticClass: "pt-3 mt-3" }, [
                      _vm._v("No orders "),
                      _vm.currentOffset > 0
                        ? _c("span", [_vm._v("on this page")])
                        : _vm._e()
                    ])
                  ]
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: {
            id: "createOrderModal",
            tabindex: "-1",
            role: "dialog",
            "aria-labelledby": "createOrderModalLabel",
            "aria-hidden": "true"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-lg",
              attrs: { role: "document" }
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body" }, [
                  _c("div", { staticClass: "form m-2" }, [
                    _vm._m(3),
                    _vm._v(" "),
                    _vm._m(4),
                    _vm._v(" "),
                    _vm._m(5),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row py-2" }, [
                      _vm._m(6),
                      _c("br"),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "Alternatively you can fill out the form and copy a curl request"
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("tree-view", {
                            attrs: {
                              data: _vm.createOrderJSON,
                              options: { modifiable: true }
                            },
                            on: { "change-data": _vm.onChangeData }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _vm._m(7)
                  ])
                ]),
                _vm._v(" "),
                _vm._m(8)
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Zinc Order ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Submitted At")]),
        _vm._v(" "),
        _c("th", [_vm._v("Finished At")]),
        _vm._v(" "),
        _c("th", [_vm._v("Retailer")]),
        _vm._v(" "),
        _c("th", { staticStyle: { "text-align": "center" } }, [
          _vm._v("Order Total")
        ]),
        _vm._v(" "),
        _c("th", { staticStyle: { "text-align": "center" } }, [
          _vm._v("Max Price")
        ]),
        _vm._v(" "),
        _c("th", { staticStyle: { "text-align": "center" } }, [
          _vm._v("Order Status")
        ]),
        _vm._v(" "),
        _c("th", [_vm._v("Client Note")]),
        _vm._v(" "),
        _c("th", [_vm._v("Tracking")]),
        _vm._v(" "),
        _c("th", [_vm._v("Merchant Order IDs")]),
        _vm._v(" "),
        _c("th", [_vm._v("Actions")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass:
          "btn btn-sm btn-outline-primary dropdown-toggle dropdown-toggle-split",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Toggle Dropdown")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "createOrderModalLabel" } },
        [_vm._v("Create a Zinc Order Request")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row py-2" }, [
      _c("h4", [_vm._v("Pick your product")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row py-2" }, [
      _c(
        "div",
        { staticClass: "col wolf-bg", staticStyle: { height: "150px" } },
        [
          _c("div", { staticClass: "mt-3 bg-dark text-light align-bottom" }, [
            _vm._v("\n                  Wolf Shirt (Small)"),
            _c("br"),
            _vm._v("\n                  $ 8.92"),
            _c("br"),
            _vm._v("\n                  ASIN: B002HJ377A\n                ")
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col whip-bg", staticStyle: { height: "150px" } },
        [
          _c("div", { staticClass: "mt-3 bg-dark text-white align-bottom" }, [
            _vm._v("\n                  Premium Suede Hand Whip"),
            _c("br"),
            _vm._v("\n                  $ 13.51"),
            _c("br"),
            _vm._v("\n                  ASIN: B00BYH5PG2\n                ")
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col rogaine-bg", staticStyle: { height: "150px" } },
        [
          _c("div", { staticClass: "mt-3 bg-dark text-light align-bottom" }, [
            _vm._v("\n                  Hair Loss Treatment"),
            _c("br"),
            _vm._v("\n                  $ 19.52"),
            _c("br"),
            _vm._v("\n                  ASIN: B0012BNVE8\n                ")
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row py-2" }, [
      _c("h4", [_vm._v("Do you use managed accounts?")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("div", { staticClass: "form-check form-check-inline" }, [
          _c("input", {
            staticClass: "form-check-input",
            attrs: {
              type: "radio",
              name: "inlineRadioOptions",
              id: "inlineRadio1",
              value: "option1"
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "form-check-label", attrs: { for: "inlineRadio1" } },
            [_vm._v("Yes, I use Managed Accounts")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-check form-check-inline" }, [
          _c("input", {
            staticClass: "form-check-input",
            attrs: {
              type: "radio",
              name: "inlineRadioOptions",
              id: "inlineRadio2",
              value: "option2"
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "form-check-label", attrs: { for: "inlineRadio2" } },
            [
              _vm._v(
                "No, I would like to use my own Amazon Account and\n                    Payment Method"
              )
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Submitting the form below will place a "),
      _c("strong", [_vm._v("live order")]),
      _vm._v(" on Amazon")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row py-2" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-success px-3 mx-2",
          attrs: { type: "button", disabled: "" }
        },
        [_vm._v("Submit Amazon Order")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-info px-3 mx-2",
          attrs: { type: "button", disabled: "" }
        },
        [_vm._v("Copy CURL request")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-secondary",
          attrs: { type: "button", "data-dismiss": "modal" }
        },
        [_vm._v("Close")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }