import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/login/Login.vue'
import Home from '../components/home/Home.vue'
import Orders from '../components/orders/Orders.vue'
import ViewOrder from '../components/view-order/ViewOrder.vue'
import Usage from '../components/billing/Usage.vue'
import Balance from '../components/balance/Balance.vue'
import Coyote from '../components/coyote/Coyote.vue'
import Billing from '../components/billing/Billing.vue'
import AdminOrderFinder from '../components/admin_order_finder/AdminOrderFinder.vue'
import AdminOrderDebugger from '../components/admin_order_debugger/AdminOrderDebugger.vue'
import AdminManageUsers from '../components/admin_manage_users/AdminManageUsers.vue'
import Logout from '../components/logout/Logout.vue'
import Signup from '../components/signup/Signup.vue'
import PendingApproval from '../components/pending-approval/PendingApproval.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    // Each of these routes are loaded asynchronously, when a user first navigates to each corresponding endpoint.
    // The route will load once into memory, the first time it's called, and no more on future calls.
    // This behavior can be observed on the network tab of your browser dev tools.
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
    },
    {
      path: '/pending-approval',
      name: 'pending-approval',
      component: PendingApproval,
      beforeEnter: guardRoute,
    },
    {
      path: '/:zincUserId/home',
      name: 'home',
      component: Home,
      beforeEnter: guardRoute,
    },
    {
      path: '/:zincUserId/orders',
      name: 'orders',
      component: Orders,
      beforeEnter: guardRoute,
    },
    {
      path: '/orders',
      name: 'orders',
      component: Orders,
      beforeEnter: guardRoute,
    },
    {
      path: '/:zincUserId/usage',
      name: 'usage',
      component: Usage,
      beforeEnter: guardRoute,
    },
    {
      path: '/:zincUserId/orders/:zincOrderId',
      name: 'view-order',
      component: ViewOrder,
      beforeEnter: guardRoute,
    },
    {
      path: '/orders/:zincOrderId',
      name: 'view-order',
      component: ViewOrder,
      beforeEnter: guardRoute,
    },
    {
      path: '/:zincUserId/addax/balance',
      name: 'balance',
      component: Balance,
      beforeEnter: guardRoute,
    },
    {
      path: '/:zincUserId/coyote',
      name: 'coyote',
      component: Coyote,
      beforeEnter: guardRoute,
    },
    {
      path: '/:zincUserId/admin',
      name: 'admin-order-finder',
      component: AdminOrderFinder,
      beforeEnter: guardRoute,
    },
    {
      path: '/:zincUserId/billing',
      name: 'billing',
      component: Billing,
      beforeEnter: guardRoute,
    },
    {
      path: '/:zincUserId/debugger',
      name: 'admin-order-debugger',
      component: AdminOrderDebugger,
      beforeEnter: guardRoute,
    },
    {
      path: '/:zincUserId/admin-manage-users',
      name: 'admin-manage-users',
      component: AdminManageUsers,
      beforeEnter: guardRoute,
    },
    {
      path: '*',
      name: 'notfound',
      redirect: '/login',
      beforeEnter: guardRoute,
    },
  ],
})

/**
 * Called to make sure the user is logged in a and loaded
 * @param {Route} to see https://router.vuejs.org/en/api/options.html#routes
 * @param {Route} from see https://router.vuejs.org/en/api/options.html#routes
 * @param {func} next callback
 */
function guardRoute(to, from, next) {
  // work-around to get to the Vuex store (as of Vue 2.0)
  const auth = router.app.$options.store.state.auth
  var currentZincUserId = _.get(to, 'params.zincUserId', '-')
  //make sure the use is logged in
  if (!auth.isLoggedIn) {
    router.app.$auth
      .getLoginUser(to.fullPath)
      .then(guardRoute.bind(this, to, from, next))
    return
  }
  if (to.name == 'pending-approval') {
    next()
    return
  }
  // At this point in exec stack, user is definitely logged in
  const loginUser = router.app.$options.store.state.loginUser
  const currentUser = router.app.$options.store.state.currentUser
  // show pending approval page if isLoggedin and missing api_user role
  if (
    !['api_user', 'api_user_limited'].some(role =>
      loginUser.roles.includes(role)
    )
  ) {
    router.push('/pending-approval')
    return
  }
  // if we don't have the currentZincUserId in the url add it
  if (currentZincUserId === '-') {
    router.push(
      to.path.replace(new RegExp('^/-/|^/'), `/${loginUser.zincUserId}/`)
    )
    return
  }
  // is the user trying to impersonate?
  if (loginUser.zincUserId != currentZincUserId && loginUser.isAdmin) {
    router.app.$auth.getCurrentUser(currentZincUserId, to.fullPath).then(response => {
      next()
    })
    return
  }
  // is the user trying to access another users data?
  if (loginUser.zincUserId != currentZincUserId) {
    router.push('/logout')
    return
  }
  //We are good g2g! Render the page!
  console.log('setting current user to loginUser', loginUser)
  _.merge(currentUser, loginUser)
  router.app.$auth.setCurrentUser(currentUser)
  next()
}

export default router
