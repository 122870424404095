<template>
  <div class="card mt-0" :class="cssClass">
    <div class="card-body">
      <h6 v-if="title" class="text-muted font-weight-normal mb-3">{{ title }}</h6>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: {
      type: String,
      required: false,
    },
    cssClass: {
      type: String,
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped>
small,
.small {
  font-size: 90% !important;
}
</style>
