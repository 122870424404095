<template>
<div class="ev-admin">

    <div class="card">
        <div class="card-header" @click="toggleShowSearchBox()">
          <i v-if="showSearchBox" class="fa fa-chevron-down"></i>
          <i v-if="!showSearchBox" class="fa fa-chevron-up"></i>
          Debug an order by ID: <b>{{title}}</b>
        </div>
        <div v-if="showSearchBox" class="card-block p-3">
            <spinner v-show="isLoading"></spinner>
            <div class="col-2">
                <form>
                    <div class="form-group">
                        <label for="searchOrderId">Order ID</label>
                        <input v-model="searchOrderId" type="text" size="50" class="form-control" id="searchOrderId" placeholder="Enter Zinc Order ID">
                    </div>
                    <div class="mt-3">
                        <button type="submit" class="btn btn-primary btn-sm" @click.prevent="getOrderData(searchOrderId)">Search</button>
                    </div>
                </form>

            </div>
        </div>
    <div v-if="foundOrder">
        <div v-if="errorBlock">
          <div class="card-header" @click="toggleShowErrorBlock()">
            <i v-if="showErrorBlock" class="fa fa-chevron-down"></i>
            <i v-if="!showErrorBlock" class="fa fa-chevron-up"></i>
            Error Block
          </div>
          <div v-if="showErrorBlock" id="errorblock-content">
            <div class="card-block p-3">
              <pre>{{errorBlock}}</pre>
            </div>
          </div>
        </div>
        <div v-if="screenshots">
          <div class="card-header" @click="toggleShowScreenshots()">
            <i v-if="showScreenshots" class="fa fa-chevron-down"></i>
            <i v-if="!showScreenshots" class="fa fa-chevron-up"></i>
            Screenshots
          </div>
          <div v-if="showScreenshots" id="screenshot-content">
            <div v-for="screenshot in screenshots" v-bind:key="screenshot">
              <div class="card-header">
                <a :href="screenshot">{{screenshot}}</a>
              </div>
              <div class="card-block p-3">
                <img :src="screenshot" style="width:40%"/>
              </div>
            </div>
          </div>
        </div>
        <div v-if="logContent">
          <div class="card-header">
            Log <a :href="logPath">{{logPath}}</a>
          </div>
          <div class="card-block p-3">
            <pre>{{logContent}}</pre>
          </div>
        </div>
    </div>
  </div>
</div>
</template>

<script>
import Auth from '@/auth'
import Spinner from '../common/Spinner.vue'
import router from '@/router'

export default {
  name: 'admin-order-debugger',
  components: { Spinner },
  props: [
    'order_id',
  ],
  data() {
    return {
      auth: this.$store.state.auth,
      user: this.$store.state.currentUser,
      currentClientToken: this.$store.state.currentUser.zincClientToken,
      isLoading: false,
      searchOrderId: '',
      foundOrder: '',
      showScreenshots: false,
      screenshots: [],
      showErrorBlock: false,
      errorBlock: null,
      showSearchBox: true,
      title: '',
      logContent: '',
      logPath: '',
    }
  },
  created() {
    if (this.$route.query.order_id) {
      this.searchOrderId = this.$route.query.order_id
    }

    if (
      this.searchOrderId
    ) {
      this.getOrderData(
        this.searchOrderId
      )
    }
  },
  methods: {
    get: _.get,
    has: _.has,
    moment: moment,
    toggleShowSearchBox() {
      this.showSearchBox = !this.showSearchBox
    },
    toggleShowScreenshots() {
      this.showScreenshots = !this.showScreenshots
    },
    toggleShowErrorBlock() {
      this.showErrorBlock = !this.showErrorBlock
    },
    getLogPath(order) {
      try {
        return (order.order_response || order.error_response)._zinc_logfile
          .split('/')
          .slice(-1)[0]
      } catch (e) {
        return null
      }
    },
    getOrderData(
      searchOrderId
    ) {
      if (
        !(searchOrderId)
      ) {
        return false
      }

      this.isLoading = true

      var routerArgs = {}

      var qsaBits = []
      if (searchOrderId) {
        qsaBits.push(`order_id=${searchOrderId}`)
        routerArgs['order_id'] = searchOrderId
      }
      router.push({ path: this.$route.path, query: routerArgs })

      var qsa = qsaBits.join('&')

      var headers = {
        Authorization: `Basic ${btoa(this.currentClientToken)}:`,
      }

      this.$http
        .get(`/api/v0/adminFindOrder?${qsa}`)
        .then(response => {
          this.showSearchBox = false
          this.isLoading = false
          this.foundOrder = response.body
          if (this.foundOrder.error_response) {
            if (this.foundOrder.error_response.screenshot_urls) {
              this.screenshots = [...this.foundOrder.error_response.screenshot_urls].reverse()
            }
            this.errorBlock = {
              code: this.foundOrder.error_response.code,
              message: this.foundOrder.error_response.message,
              data: this.foundOrder.error_response.data
            }
            this.showErrorBlock = true
            this.title = `${searchOrderId} [ERROR: ${this.errorBlock.code}]`
          } else if (this.foundOrder.order_response && this.foundOrder.order_response.screenshot_urls) {
            this.screenshots = [...this.foundOrder.order_response.screenshot_urls].reverse()
            this.title = `${searchOrderId} [SUCCESS]`
          }
          this.showScreenshots = true
          this.logPath = `https://arsenic-logs.znsrv.com/v0/logs/${searchOrderId}?secret=murmurponchoaurahazejigspruce`
          this.$http
            .get(this.logPath)
            .then(response => {
              this.logContent = response.body
            })
            .catch(response => {
              this.logContent = `Unable to grab log at: ${this.logPath}`
            })
        })
        .catch(response => {
          this.$auth.logout()
        })
    }
  },
}
</script>


<style lang="scss">

</style>
