import { render, staticRenderFns } from "./CasesModal.vue?vue&type=template&id=d4161c8e&scoped=true&"
import script from "./CasesModal.vue?vue&type=script&lang=js&"
export * from "./CasesModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4161c8e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/zincadm/zinc-api-dash/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('d4161c8e', component.options)
    } else {
      api.reload('d4161c8e', component.options)
    }
    module.hot.accept("./CasesModal.vue?vue&type=template&id=d4161c8e&scoped=true&", function () {
      api.rerender('d4161c8e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "client/components/cases/CasesModal.vue"
export default component.exports