var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ev-login col-sm-4 offset-sm-4" },
    [
      _c("spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loggingIn,
            expression: "loggingIn"
          }
        ],
        attrs: { message: "Logging in..." }
      }),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _c("p", [_vm._v(_vm._s(_vm.error))])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("h4", [
        _vm._v("Redirecting to " + _vm._s(this.getLoginURL()) + " ... ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }