<template>
    <Card title="Prepaid Billing Transactions">
      <table class="table">
        <thead class="text-muted">
          <tr>
            <th>Time</th>
            <th>Description</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr class="m-3" v-for="line in prepaidLedger.ledger">
            <td>{{formatDate(line.created_at)}}</td>
            <td>{{line.description}}</td>
            <td>{{getPrettyPrice('$', line.amount)}} {{line.unit}}</td>
          </tr>
        </tbody>
      </table>
      <div class="clearfix">
        <button v-if="offset()!=0" class="btn btn-outline-primary float-left" @click.prevent="() => prevPage()">Prev</button>
        <button v-if="prepaidLedger.ledger.length==10" class="btn btn-outline-primary float-right" @click.prevent="() => nextPage()">Next</button>
      </div>
    </Card>
  </template>

  <script>
  import Card from '../common/Card.vue'
  import router from '@/router'
  export default {
    name: 'PrepaidLedger',
    components: { Card },
    data() {
      return {
        user: this.$store.state.currentUser,
        prepaidLedger: {
          balance: 0,
          ledger: [],
        },
      }
    },
    mounted() {
      console.log(this.$store.state.currentUser.roles)
      this.getPrepaidLedger(this.user.zincUserId).then(prepaidLedger =>{
        Object.assign(this.prepaidLedger, prepaidLedger)
      })
    },
    computed: {},
    methods: {
      offset(){
        let offset = parseInt(this.$route.query.prepaidOffset);
        return isNaN(offset) ? 0 : offset;
      },
      nextPage(){
        router.push({ path: this.$route.path, query: { prepaidOffset: this.offset()+10 } })
        this.getPrepaidLedger(this.user.zincUserId).then(prepaidLedger =>{
          Object.assign(this.prepaidLedger, prepaidLedger)
        })
      },
      prevPage(){
        router.push({ path: this.$route.path, query: { prepaidOffset: Math.max(this.offset()-10,0) } })
        this.getPrepaidLedger(this.user.zincUserId).then(prepaidLedger =>{
          Object.assign(this.prepaidLedger, prepaidLedger)
        })
      },
      async getPrepaidLedger(userId) {
        let limit = parseInt(this.$route.query.prepaidLimit);
        limit = isNaN(limit) ? undefined : limit;
        let url = `/api/v0/prepaidLedger/${userId}?offset=${this.offset()}&limit=${limit||10}`
        let res = await fetch(url, { method: 'GET' })
        return await res.json()
      },
      getPrettyPrice: function(
        currencySymbol = '$',
        num,
        divisor = 1,
        numDecimals = 2,
        targetLength = 10
      ) {
        let currencyString = `${(num / divisor).toFixed(numDecimals)}`
        return `${currencySymbol} ${currencyString.padStart(targetLength)}`
      },
      formatDate: function(date) {
        return moment(date).format('YYYY-MM-DD hh:mm a')
      }
    },
  }
  </script>


  <style scoped lang="scss">
  tbody > tr {
    font-weight: 400;
  }
  </style>
