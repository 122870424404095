<template>
  <div class="ev-billing mb-2">
    <div class="flex flex-row justify-content-between align-items-center form-group form-inline">
      <h3 class="p-3 pt-4 ml-3" style="display: inline-block">
        Zinc API Usage
      </h3>
      <select class="form-control mr-3" style="min-width: 300px" v-model="selectedDate">
        <option v-for="month in this.months" v-bind:value="month.date">{{ month.readable }}</option>
      </select>
    </div>
    <withUtilizar :date="this.selectedDate">
      <template slot="utilizar-view" slot-scope="usage">
        <div class="row">
          <div class="col-md-12 col-lg-4 col-xl-3">
            <UsagePullIn class="" :data="usage.data" />
          </div>
          <div class="col-md-12 col-lg-8 col-xl-9">
            <BillingPreview :usage="usage.data" />
          </div>
        </div>
        <UsageGraph :data="usage.data" />
      </template>
    </withUtilizar>
  </div>
</template>

<script>
import Auth from '@/auth'
import Spinner from '../common/Spinner.vue'
import Card from '../common/Card.vue'
import UsagePullIn from './UsagePullIn.vue'
import UsageGraph from './UsageGraph.vue'
import BillingPreview from './BillingPreview.vue'
import withUtilizar from './UtilizarHOC.vue'
import router from '@/router'
const addaxCurrencies = require('../common/addaxCurrencies.data.js')
const marketplaces = require('../common/marketplaces.data.js')
const tz = moment.tz.guess()

const todayUtc = moment.utc()
const currentMonth = todayUtc.format('MM')
const currentYear = todayUtc.format('YYYY')

export default {
  name: 'billing',
  components: {
    Spinner,
    withUtilizar,
    UsagePullIn,
    Card,
    UsageGraph,
    BillingPreview,
  },
  data() {
    return {
      auth: this.$store.state.auth,
      user: this.$store.state.currentUser,
      currentClientToken: this.$store.state.currentUser.zincClientToken,
      hasApiBillingEnabled: true,
      isLoading: false,
      today: moment(),
      months: [],
      selectedDate: moment().format('YYYY-MM'),
    }
  },
  computed: {},
  created() {
    let dateStart = moment('2018-09-01')
    this.months = []
    while (
      this.today > dateStart ||
      dateStart.format('M') === this.today.format('M')
    ) {
      this.months.push({
        date: dateStart.format('YYYY-MM'),
        readable: dateStart.format('MMMM YYYY'),
      })
      dateStart.add(1, 'month')
    }

    this.months.reverse()

    this.getUsage()
  },
  methods: {
    get: _.get,
    getPrettyDateFromUnixInUTC: function (unixTimestamp) {
      return moment
        .unix(unixTimestamp)
        .tz('Etc/UTC')
        .format('l LTS')
    },
    getPrettyMonthFromUnixInUTC: function (unixTimestamp) {
      return moment
        .unix(unixTimestamp)
        .tz('Etc/UTC')
        .format('MMMM YYYY')
    },
    getPrettyPrice: function (
      currencySymbol = '$',
      num,
      divisor = 100,
      numDecimals = 2,
      targetLength = 10
    ) {
      let currencyString = `${(num / divisor).toFixed(numDecimals)}`
      return `${currencySymbol} ${currencyString.padStart(targetLength)}`
    },
    getUsage: function () {
      this.isLoading = false
    },
    getZigguratCostInCents: function (numCalls, perCallFee, divisor) {
      return Math.floor(numCalls * perCallFee / divisor)
    },
  },
}
</script>

<style lang="css"></style>
