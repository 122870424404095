var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ev-view-order mt-2" },
    [
      _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "orders",
                      params: { zincUserId: _vm.user.zincUserId }
                    }
                  }
                },
                [_vm._v("Orders ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              staticClass: "breadcrumb-item active",
              attrs: { "aria-current": "page" }
            },
            [_vm._v(_vm._s(_vm.zincOrderId))]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-3" }, [
        _c("div", { staticClass: "col-12" }, [
          _vm.order._type == "order_response"
            ? _c("div", { staticClass: "alert alert-success col-sm" }, [
                _vm._v(
                  "\n              This order was successfully submitted to marketplace "
                ),
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.getPrettyMarketName()) }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.order._type == "error" && _vm.order.code != "request_processing"
            ? _c("div", { staticClass: "alert alert-danger col-sm" }, [
                _vm._v(
                  "\n              This order encountered an error on marketplace "
                ),
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.getPrettyMarketName()) }
                }),
                _c("br"),
                _vm._v(" "),
                _c("strong", [_vm._v(_vm._s(_vm.order.code) + " - ")]),
                _vm._v(
                  _vm._s(
                    (_vm.order.message
                      ? _vm.order.message
                      : _vm.order.data
                      ? _vm.order.data.message ||
                        _vm.order.data.msg ||
                        _vm.order.data.details
                      : "") || ""
                  ) + "\n          "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.order._type == "error" && _vm.order.code == "request_processing"
            ? _c("div", { staticClass: "alert alert-warning col-sm" }, [
                _vm._v(
                  "\n              This order request is still preprocessing, not yet placed on "
                ),
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.getPrettyMarketName()) }
                }),
                _c("br"),
                _vm._v(" "),
                _c("strong", [_vm._v(_vm._s(_vm.order.code) + " - ")]),
                _vm._v(_vm._s(_vm.order.message) + "\n          ")
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c("Card", { attrs: { title: "Zinc API Details" } }, [
              _c("div", [
                _c("div", { staticClass: "mt-2 mb-1" }, [
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v("Zinc Order ID")
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "text-monospace",
                    staticStyle: {
                      "font-family": "monospace",
                      "font-size": "1.1em"
                    }
                  },
                  [_vm._v(_vm._s(_vm.zincOrderId))]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://" +
                        _vm.user.zincClientToken +
                        ":@api.zinc.io/v1/orders/" +
                        _vm.order.request_id,
                      target: "_blank"
                    }
                  },
                  [
                    _c("span", { staticClass: "badge badge-secondary" }, [
                      _vm._v("JSON")
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "mt-2 mb-1" }, [
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v("Created At")
                  ])
                ]),
                _vm._v(" "),
                _c("span", {}, [
                  _vm._v(_vm._s(_vm.getPrettyDate(_vm.order._created_at)))
                ])
              ]),
              _vm._v(" "),
              _vm.get(_vm.order, "delivery_dates[0].date")
                ? _c("div", [
                    _c("div", { staticClass: "mt-2 mb-1" }, [
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v("Estimated Delivery Date")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("span", {}, [
                      _vm._v(
                        _vm._s(
                          _vm.getPrettyDate(_vm.order.delivery_dates[0].date)
                        )
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "mt-2 mb-1" }, [
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v("Zinc User")
                  ])
                ]),
                _vm._v(" "),
                _c("span", {}, [
                  _vm._v(_vm._s(_vm.user.name) + " "),
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.user.email))
                  ])
                ])
              ]),
              _vm._v(" "),
              _vm.get(_vm.order, "request.retailer_credentials.email")
                ? _c("div", [
                    _c("div", { staticClass: "mt-2 mb-1" }, [
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v("Retailer Account Email")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.get(
                            _vm.order,
                            "request.retailer_credentials.email"
                          )
                        )
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "mt-2 mb-1" }, [
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v("Payment Method")
                  ])
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm.get(_vm.order, "request.payment_method.use_gift")
                    ? _c("span", [_vm._v("Gift Card")])
                    : _vm.get(_vm.order, "request.addax", false)
                    ? _c("span", [_vm._v("Zinc Managed Account")])
                    : _c("span", [
                        _vm._v("Credit Card "),
                        _c("span", { staticClass: "text-muted pl-2" }, [
                          _vm._v(
                            _vm._s(
                              _vm.get(
                                _vm.order,
                                "request.payment_method.name_on_card"
                              )
                            ) +
                              " EXP:\n              " +
                              _vm._s(
                                _vm.get(
                                  _vm.order,
                                  "request.payment_method.expiration_month"
                                )
                              ) +
                              " / " +
                              _vm._s(
                                _vm.get(
                                  _vm.order,
                                  "request.payment_method.expiration_year"
                                )
                              )
                          )
                        ])
                      ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex flex-row justify-content-between" },
                [
                  _c("div", [
                    _c("div", { staticClass: "mt-2 mb-1" }, [
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v("Marketplace")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getPrettyMarketName())
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("div", { staticClass: "mt-2 mb-1" }, [
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v("Has Returns?")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm.has(_vm.order, "return_request_ids.0", false)
                        ? _c("span", [_vm._v("✅")])
                        : _c("span", [_vm._v("❌")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("div", { staticClass: "mt-2 mb-1" }, [
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v("Has Cases?")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm.get(_vm.cases, "state", null) != null
                        ? _c(
                            "span",
                            { staticStyle: { "text-transform": "capitalize" } },
                            [
                              _vm._v(
                                "✅\n                " +
                                  _vm._s(_vm.get(_vm.cases, "state", ""))
                              )
                            ]
                          )
                        : _c("span", [_vm._v("❌")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div")
                ]
              )
            ]),
            _vm._v(" "),
            _c("Card", { attrs: { title: "Actions" } }, [
              _vm.get(_vm.order, "request.addax")
                ? _c("span", [
                    _vm.get(_vm.cases, "state", null) == null ||
                    _vm.cases.state != "open"
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: {
                              type: "button",
                              "data-toggle": "modal",
                              "data-target": "#casesMessageModal",
                              "data-action": "open"
                            }
                          },
                          [_vm._v("Open Case")]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: {
                              type: "button",
                              "data-toggle": "modal",
                              "data-target": "#casesMessageModal",
                              "data-action": "update"
                            }
                          },
                          [_vm._v("Update Case")]
                        )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isCancelable()
                ? _c("span", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            _vm.cancelOrder(_vm.order.request_id, $event)
                          }
                        }
                      },
                      [_vm._v("Cancel")]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isAbortable()
                ? _c("span", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: {
                          type: "button",
                          "data-toggle": "modal",
                          "data-target": "#abortConfirmModal"
                        }
                      },
                      [_vm._v("Attempt Abort")]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isReturnable()
                ? _c("span", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-warning",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            _vm.createReturn(_vm.order.request_id, $event)
                          }
                        }
                      },
                      [_vm._v("Create Return")]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.loginUser.isAdmin
                ? _c("span", { staticClass: "zn-admin" }, [
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://dash.zinc.io/" +
                            _vm.loginUser.zincUserId +
                            "/admin?order_id=" +
                            _vm.zincOrderId
                        }
                      },
                      [_vm._v("View Order as Admin")]
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            (_vm.order._type == "order_response" &&
              _vm.order.price_components) ||
            (_vm.order._type == "error" &&
              _vm.order.code == "max_price_exceeded")
              ? _c("Card", [
                  _vm.order._type == "order_response" &&
                  _vm.order.price_components
                    ? _c("span", [
                        _c(
                          "h6",
                          { staticClass: "text-muted font-weight-normal mb-3" },
                          [_vm._v("Cost Breakdown")]
                        ),
                        _vm._v(" "),
                        _c(
                          "table",
                          {
                            staticClass: "table table-sm table-borderless mb-0"
                          },
                          [
                            _c("tbody", [
                              _c("tr", [
                                _c("td", [_vm._v("Subtotal")]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getPrettyPrice(
                                        _vm.get(
                                          _vm.order,
                                          "price_components.subtotal",
                                          0
                                        )
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [_vm._v("Shipping")]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getPrettyPrice(
                                        _vm.get(
                                          _vm.order,
                                          "price_components.shipping",
                                          0
                                        )
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [_vm._v("Tax")]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getPrettyPrice(
                                        _vm.get(
                                          _vm.order,
                                          "price_components.tax",
                                          0
                                        )
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", { staticClass: "font-weight-bold" }, [
                                _c("td", [_vm._v("Total")]),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getPrettyPrice(
                                        _vm.get(
                                          _vm.order,
                                          "price_components.total",
                                          0
                                        )
                                      )
                                    )
                                  )
                                ])
                              ])
                            ])
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.order._type == "order_response" &&
                    _vm.order.price_components) ||
                  (_vm.order._type == "error" &&
                    _vm.order.code == "max_price_exceeded")
                    ? _c("div", { staticClass: "col-6 col-lg-5 col-xl-4" }, [
                        _vm.order._type == "error" &&
                        _vm.order.code == "max_price_exceeded"
                          ? _c("span", [
                              _c(
                                "table",
                                {
                                  staticClass: "table table-sm table-borderless"
                                },
                                [
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("td", [_vm._v("Max Price Provided")]),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getPrettyPrice(
                                              _vm.get(
                                                _vm.order,
                                                "data.data.price_components.max_price",
                                                0
                                              )
                                            )
                                          )
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [_vm._v("Total")]),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getPrettyPrice(
                                              _vm.get(
                                                _vm.order,
                                                "data.data.price_components.total",
                                                0
                                              )
                                            )
                                          )
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [_vm._v("Subtotal")]),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getPrettyPrice(
                                              _vm.get(
                                                _vm.order,
                                                "data.data.price_components.subtotal",
                                                0
                                              )
                                            )
                                          )
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [_vm._v("Shipping")]),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getPrettyPrice(
                                              _vm.get(
                                                _vm.order,
                                                "data.data.price_components.shipping",
                                                0
                                              )
                                            )
                                          )
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [_vm._v("Shipping")]),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getPrettyPrice(
                                              _vm.get(
                                                _vm.order,
                                                "data.data.price_components.tax",
                                                0
                                              )
                                            )
                                          )
                                        )
                                      ])
                                    ])
                                  ])
                                ]
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.order && _vm.get(_vm.order, "cancellation_request_ids", "")
              ? _c(
                  "Card",
                  { attrs: { title: "Cancellation Requests" } },
                  _vm._l(_vm.order.cancellation_request_ids, function(
                    cancellation_request_id
                  ) {
                    return _c("div", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://" +
                              _vm.user.zincClientToken +
                              ":@api.zinc.io/v1/cancellations/" +
                              cancellation_request_id,
                            target: "_blank"
                          }
                        },
                        [_vm._v(_vm._s(cancellation_request_id))]
                      )
                    ])
                  })
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-8" },
          [
            _c("Card", { attrs: { title: "Order Contents" } }, [
              _c("div", { staticClass: "items-table" }, [
                _vm.has(_vm.order, "request.products")
                  ? _c("table", { staticClass: "table mb-0" }, [
                      _c("thead", { staticClass: "text-muted" }, [
                        _c("tr", [
                          _c("th", [_vm._v("Item")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Quantity")])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.order.request.products, function(
                          order_detail
                        ) {
                          return _c("tr", [
                            _c("td", [
                              _c(
                                "a",
                                {
                                  staticClass: "zn-listing-link",
                                  attrs: {
                                    href: _vm.getProductUrl(
                                      order_detail.product_id
                                    ),
                                    rel: "noopener noreferrer",
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(_vm._s(order_detail.product_id) + " "),
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(_vm.marketInfo.icon)
                                    }
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(order_detail.quantity))])
                          ])
                        })
                      )
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _vm.cases && _vm.get(_vm.cases, "state")
              ? _c("Card", { attrs: { title: "Managed Account Case" } }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "https://support.priceyak.com/agent/tickets/" +
                          _vm.get(_vm.cases, "zendesk_ticket_id"),
                        target: "_blank"
                      }
                    },
                    [
                      _vm.loginUser.isAdmin
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-block btn-primary my-2 zn-admin"
                            },
                            [
                              _vm._v(
                                "View in Zendesk: #" +
                                  _vm._s(
                                    _vm.get(_vm.cases, "zendesk_ticket_id")
                                  )
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("table", { staticClass: "table table-condensed" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { staticStyle: { width: "100px" } }, [
                          _vm._v("Author")
                        ]),
                        _vm._v(" "),
                        _c("th", { staticStyle: { width: "100px" } }, [
                          _vm._v("Time")
                        ]),
                        _vm._v(" "),
                        _c("th", { staticStyle: { width: "200px" } }, [
                          _vm._v("Type")
                        ]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Message")]),
                        _vm._v(" "),
                        _c("th", { staticStyle: { width: "150px" } }, [
                          _vm._v("Refund Amount")
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.cases.messages, function(message) {
                        return _c("tr", [
                          _c("td", [_vm._v(_vm._s(message.author))]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.getPrettyDateFromUnix(message.created_time)
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(message.type))]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "12px",
                                  "white-space": "pre-wrap",
                                  "max-width": "700px"
                                }
                              },
                              [_vm._v(_vm._s(message.message))]
                            )
                          ]),
                          _vm._v(" "),
                          message.transaction
                            ? _c("td", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.getPrettyPrice(
                                        message.transaction.amount
                                      )
                                    ) +
                                    "\n              "
                                )
                              ])
                            : _vm._e()
                        ])
                      })
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row justify-content-between" },
                    [
                      _vm.cases.state == "open"
                        ? _c(
                            "p",
                            { staticClass: "text-muted text-small mt-2" },
                            [
                              _vm._v(
                                "Case will be resolved by: " +
                                  _vm._s(
                                    _vm.getPrettyDateFromUnix(
                                      _vm.cases.next_action_time
                                    )
                                  )
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            type: "button",
                            "data-toggle": "modal",
                            "data-target": "#casesMessageModal",
                            "data-action": "update"
                          }
                        },
                        [_vm._v("Update Case")]
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.returnInfo
              ? _c(
                  "Card",
                  { attrs: { title: "Return Info" } },
                  [
                    _c("table", { staticClass: "table" }, [
                      _c("tbody", [
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v("Attempt time")
                          ]),
                          _vm._v(" "),
                          _vm.has(_vm.returnInfo, "stats.launch_time")
                            ? _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getPrettyDate(
                                      _vm.returnInfo.stats.launch_time
                                    )
                                  )
                                )
                              ])
                            : _c("td")
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v("Return status")
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm.returnInfo._type == "error" &&
                            _vm.returnInfo.code == "request_processing"
                              ? _c("span", [
                                  _vm._v(
                                    "Processsing: " +
                                      _vm._s(_vm.returnInfo.code)
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.returnInfo._type == "error"
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "Failed: " + _vm._s(_vm.returnInfo.code)
                                  )
                                ])
                              : _c("span", { staticClass: "text-success" }, [
                                  _vm._v("Sucess")
                                ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v("Refund amount")
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm.has(_vm.returnInfo, "status.refund_amount") &&
                            _vm.returnInfo.status.refund_amount !== 0
                              ? _c("span", [
                                  _vm._v(
                                    "$" +
                                      _vm._s(
                                        _vm.getDollarsFromCents(
                                          _vm.returnInfo.status.refund_amount
                                        )
                                      )
                                  )
                                ])
                              : _vm._e()
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v("Return method")
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm.has(_vm.returnInfo, "selected_return_method")
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.returnInfo.selected_return_method
                                    )
                                  )
                                ])
                              : _vm._e()
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v("Return label")
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm.has(_vm.returnInfo, "label_urls.0")
                              ? _c("span", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.returnInfo.label_urls[0],
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.returnInfo.label_urls[0])
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v("Return Id")
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "https://" +
                                    _vm.user.zincClientToken +
                                    ":@api.zinc.io/v1/returns/" +
                                    _vm.returnInfo.request_id,
                                  target: "_blank"
                                }
                              },
                              [_vm._v(_vm._s(_vm.returnInfo.request_id))]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("th", { attrs: { scope: "row" } }, [
                            _vm._v("Screenshots")
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "d-flex flex-row flex-wrap" },
                            _vm._l(_vm.returnInfo.screenshot_urls, function(
                              screenshot_url
                            ) {
                              return _c("div", { staticClass: "m-2" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: screenshot_url,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "zn-order-debug-img",
                                      attrs: { src: screenshot_url }
                                    })
                                  ]
                                )
                              ])
                            })
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.order && _vm.get(_vm.order, "return_request_ids", "")
                      ? _c(
                          "Card",
                          { attrs: { title: "Previous Return Requests" } },
                          _vm._l(_vm.order.return_request_ids, function(
                            return_request_id
                          ) {
                            return _c("div", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "https://" +
                                      _vm.user.zincClientToken +
                                      ":@api.zinc.io/v1/returns/" +
                                      return_request_id,
                                    target: "_blank"
                                  }
                                },
                                [_vm._v(_vm._s(return_request_id))]
                              )
                            ])
                          })
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("Card", { attrs: { title: "Tracking Info" } }, [
              _vm.has(_vm.order, "tracking")
                ? _c(
                    "table",
                    { staticClass: "table table-sm table-borderless" },
                    [
                      _c("thead", { staticClass: "text-muted" }, [
                        _c("tr", [
                          _c("th", [_vm._v("Merchant Order Id")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Tracking Number")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Carrier")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Items")])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        [
                          _vm._l(_vm.get(_vm.order, "tracking", []), function(
                            trackingObject
                          ) {
                            return [
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.get(
                                        trackingObject,
                                        "merchant_order_id"
                                      )
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm.get(trackingObject, "tracking_url") !=
                                  null
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: _vm.get(
                                              trackingObject,
                                              "tracking_url"
                                            ),
                                            target: "_blank",
                                            rel: "noopener noreferrer"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.get(
                                                trackingObject,
                                                "tracking_number",
                                                ""
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.get(trackingObject, "tracking_number") ==
                                  null
                                    ? _c("span", [_vm._v("Order Cancelled")])
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm.get(trackingObject, "carrier"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _vm._l(
                                      _vm.get(
                                        trackingObject,
                                        "product_ids",
                                        []
                                      ),
                                      function(productId) {
                                        return [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "zn-listing-link",
                                              attrs: {
                                                href: _vm.getProductUrl(
                                                  productId
                                                ),
                                                rel: "noopener noreferrer",
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _vm._v(_vm._s(productId) + " "),
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.marketInfo.icon
                                                  )
                                                }
                                              })
                                            ]
                                          ),
                                          _c("br")
                                        ]
                                      }
                                    )
                                  ],
                                  2
                                )
                              ])
                            ]
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.get(_vm.order, "tracking", []), function(
                            trackingObject
                          ) {
                            return [
                              _vm.get(
                                trackingObject,
                                "retailer_tracking_number"
                              )
                                ? _c("tr", [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.get(
                                            trackingObject,
                                            "merchant_order_id"
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm.get(
                                        trackingObject,
                                        "retailer_tracking_url"
                                      ) != null
                                        ? _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: _vm.get(
                                                  trackingObject,
                                                  "retailer_tracking_url"
                                                ),
                                                target: "_blank",
                                                rel: "noopener noreferrer"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.get(
                                                    trackingObject,
                                                    "retailer_tracking_number"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v("RETAILER")]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      [
                                        _vm._l(
                                          _vm.get(
                                            trackingObject,
                                            "product_ids",
                                            []
                                          ),
                                          function(productId) {
                                            return [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "zn-listing-link",
                                                  attrs: {
                                                    href: _vm.getProductUrl(
                                                      productId
                                                    ),
                                                    rel: "noopener noreferrer",
                                                    target: "_blank"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(productId) + " "
                                                  ),
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.marketInfo.icon
                                                      )
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c("br")
                                            ]
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  ])
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    ]
                  )
                : _c("span", { staticClass: "text-muted" }, [
                    _vm._v(
                      "Tracking will be provided when received from the retailer"
                    )
                  ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex flex-row flex-wrap order-info-cards" },
              [
                _vm.order && _vm.has(_vm.order, "request.shipping_address")
                  ? _c("Card", { attrs: { title: "Shipping Address" } }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.order.request.shipping_address.first_name
                          ) +
                            " " +
                            _vm._s(_vm.order.request.shipping_address.last_name)
                        )
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        _vm._s(
                          _vm.order.request.shipping_address.address_line1
                        ) + "\n          "
                      ),
                      _c("br"),
                      _vm.order.request.shipping_address.address_line2
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.order.request.shipping_address.address_line2
                              ) + "\n          "
                            ),
                            _c("br")
                          ])
                        : _vm._e(),
                      _vm._v(
                        _vm._s(_vm.order.request.shipping_address.city) +
                          " " +
                          _vm._s(_vm.order.request.shipping_address.state) +
                          "  " +
                          _vm._s(_vm.order.request.shipping_address.zip_code) +
                          "\n          "
                      ),
                      _c("br"),
                      _vm._v(
                        _vm._s(_vm.order.request.shipping_address.country) +
                          "\n          "
                      ),
                      _c("br"),
                      _vm._v(
                        "P: " +
                          _vm._s(
                            _vm.order.request.shipping_address.phone_number
                          ) +
                          "\n        "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.order &&
                !_vm.get(_vm.order, "request.addax", false) &&
                _vm.has(_vm.order, "request.billing_address")
                  ? _c("Card", { attrs: { title: "Billing Address" } }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.get(
                              _vm.order,
                              "request.billing_address.first_name"
                            )
                          ) +
                            " " +
                            _vm._s(
                              _vm.get(
                                _vm.order,
                                "request.billing_address.last_name"
                              )
                            )
                        )
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        _vm._s(
                          _vm.get(
                            _vm.order,
                            "request.billing_address.address_line1"
                          )
                        ) + "\n            "
                      ),
                      _c("br"),
                      _vm.order.request.billing_address.address_line2
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.get(
                                  _vm.order,
                                  "request.billing_address.address_line2"
                                )
                              ) + "\n            "
                            ),
                            _c("br")
                          ])
                        : _vm._e(),
                      _vm._v(
                        _vm._s(
                          _vm.get(_vm.order, "request.billing_address.city")
                        ) +
                          " " +
                          _vm._s(
                            _vm.get(_vm.order, "request.billing_address.state")
                          ) +
                          "  " +
                          _vm._s(
                            _vm.get(
                              _vm.order,
                              "request.billing_address.zip_code"
                            )
                          ) +
                          "\n            "
                      ),
                      _c("br"),
                      _vm._v(
                        _vm._s(
                          _vm.get(_vm.order, "request.billing_address.country")
                        ) + "\n            "
                      ),
                      _c("br"),
                      _vm._v(
                        "P: " +
                          _vm._s(
                            _vm.get(
                              _vm.order,
                              "request.billing_address.phone_number"
                            )
                          ) +
                          "\n        "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.order && _vm.get(_vm.order, "request.client_notes", "")
                  ? _c(
                      "Card",
                      {
                        staticClass: "order-notes-card",
                        attrs: { title: "Order Notes" }
                      },
                      [
                        _c("p", { staticClass: "code" }, [
                          _vm._v(
                            _vm._s(
                              _vm.get(_vm.order, "request.client_notes", "")
                            ) + "\n          "
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.order.screenshot_urls && _vm.order.screenshot_urls.length
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "Card",
                  { attrs: { title: "Automatic Ordering Screenshots" } },
                  [
                    _c(
                      "div",
                      { staticClass: "mt-2 mb-1" },
                      _vm._l(_vm.order.screenshot_urls, function(
                        screenshot_url
                      ) {
                        return _c("span", [
                          _c(
                            "a",
                            {
                              attrs: { href: screenshot_url, target: "_blank" }
                            },
                            [
                              _c("img", {
                                staticClass: "zn-order-debug-img",
                                attrs: { src: screenshot_url }
                              })
                            ]
                          )
                        ])
                      })
                    )
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("Card", { attrs: { title: "Automatic Ordering Event Log" } }, [
              _c("table", { staticClass: "table" }, [
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.order.status_updates, function(status_update) {
                      return [
                        _c("tr", [
                          _c("td", [
                            _vm._v(_vm._s(_vm.get(status_update, "type", "")))
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.get(status_update, "message", ""))
                            )
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.getPrettyDate(
                                  _vm.get(status_update, "_created_at", "")
                                )
                              )
                            )
                          ])
                        ])
                      ]
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _vm.get(_vm.order, "offers_urls", []).length
                ? _c(
                    "div",
                    { staticClass: "mt-2 mb-1" },
                    [
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v("Offers")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.get(_vm.order, "offers_urls", []), function(
                        offerUrl,
                        index
                      ) {
                        return _c("span", [
                          _c(
                            "a",
                            { attrs: { href: offerUrl, target: "_blank" } },
                            [_vm._v("#" + _vm._s(index + 1))]
                          )
                        ])
                      })
                    ],
                    2
                  )
                : _vm._e()
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { attrs: { id: "accordion" } }, [
            _c("div", { staticClass: "card" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "collapse",
                  attrs: {
                    id: "collapseTwo",
                    "aria-labelledby": "collapseTwo",
                    "data-parent": "#accordion"
                  }
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("pre", [
                      _c("br"),
                      _vm._v(_vm._s(_vm.order) + "\n                        ")
                    ])
                  ])
                ]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("CasesModal", {
        attrs: {
          orderId: _vm.zincOrderId,
          clientToken: _vm.user.zincClientToken,
          action: _vm.casesModalAction,
          setCases: this.setCases
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: {
            id: "abortConfirmModal",
            tabindex: "-1",
            role: "dialog",
            "aria-labelledby": "abortConfirmModal",
            "aria-hidden": "true"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-lg",
              attrs: { role: "document" }
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(1),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { type: "button", "data-dismiss": "modal" }
                    },
                    [_vm._v("Close")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning",
                      attrs: { id: "abortOrderButon" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.abortOrder()
                        }
                      }
                    },
                    [_vm._v("Abort Order")]
                  )
                ])
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header", attrs: { id: "headingTwo" } },
      [
        _c("h6", { staticClass: "mb-0" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-link",
              attrs: {
                "data-toggle": "collapse",
                "data-target": "#collapseTwo",
                "aria-expanded": "false",
                "aria-controls": "collapseTwo"
              }
            },
            [_vm._v("View Order Json\n                        ")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "abortConfirmModalTitle" } },
        [
          _vm._v(
            "\n              Are you sure you want to abort this order?\n            "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-body" }, [
      _vm._v(
        "\n            Note that abortion is best effort, so we cannot guarantee that you will be able to abort a request.\n            "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://docs.zincapi.com/#aborting-an-order",
            target: "_blank"
          }
        },
        [_vm._v("Learn more.")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }