<template>
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Current Billing Balance</h5>
            <p class="card-text">
            <h1>{{ getPrettyPrice("$", prepaidLedger.unbilledBalance || prepaidLedger.balance) }}</h1>
            <h5 v-if="feePlan.auto_charge">We will automatically charge your payment method if your balance reaches zero
            </h5>
            <h5 v-else>If your balance reaches zero your API calls will fail</h5>
            <div>
                <!--<button
                    v-if="loginUser.isAdmin"
                    class="btn btn-primary"
                    @click.prevent="openPrepaidCreditModal(get(dashUser, 'email'), get(dashUser, 'id'))"
                >Admin Credit</button>-->
                <button class="btn btn-primary" data-toggle="modal" data-target="#addBalanceModal">Add Balance</button>
                <a href="#" v-if="feePlan.auto_charge" class="btn btn-outline-primary" @click="enableAutoPay(false)">Disable AutoPay</a>
                <a href="#" v-else class="btn btn-primary" @click="enableAutoPay(true)">Enable AutoPay</a>
                <a :href="managePaymentMethodsUrl" class="btn btn-outline-primary">Manage Payment Methods</a>
            </div>
            </p>
        </div>
        <div class="modal fade" id="addBalanceModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Add Prepaid Balance</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="submitAddBalance">
                        <div class="modal-body">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">$</div>
                                </div>
                                <input type="number" name="amount" class="form-control" id="moneyInput" min="50" max="1000"
                                    step="1" style="max-width: 600px;" required>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <a :href="managePaymentMethodsUrl" class="btn btn-outline-primary">Manage Payment Methods</a>
                            <button type="submit" class="btn btn-primary">Charge Payment Method</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Card from '../common/Card.vue'
export default {
    name: 'PrepaidBalance',
    components: { Card },
    data() {
        return {
            managePaymentMethodsUrl: `https://login.zinc.io/manage_payment_methods?redirect_url=${encodeURI(window.location.href)}`,
            user: this.$store.state.currentUser,
            loginUser: this.$store.state.loginUser,
            prepaidLedger: {
                balance: 0,
            },
            feePlan: {
                auto_charge: false
            },
        }
    },
    mounted() {
        this.getPrepaidLedger(this.user.zincUserId).then(prepaidLedger => {
            Object.assign(this.prepaidLedger, prepaidLedger)
        })
        this.getFeePlan(this.user.zincUserId).then(feePlan => {
            Object.assign(this.feePlan, feePlan)
        })
    },
    computed: {},
    methods: {
        async getPrepaidLedger(userId) {
            let limit = parseInt(this.$route.query.prepaidLimit);
            limit = isNaN(limit) ? undefined : limit;
            let url = `/api/v0/prepaidLedger/${userId}?unbilledBalance=1`
            let res = await fetch(url, { method: 'GET' })
            return await res.json()
        },
        async getFeePlan(userId) {
            let url = `/api/v0/feePlan/${userId}`
            let res = await fetch(url, { method: 'GET' })
            return await res.json()
        },
        getPrettyPrice: function (
            currencySymbol = '$',
            num,
            divisor = 1,
            numDecimals = 2,
            targetLength = 10
        ) {
            let currencyString = `${(num / divisor).toFixed(numDecimals)}`
            return `${currencySymbol} ${currencyString.padStart(targetLength)}`
        },
        enableAutoPay: async function (enable) {
            let url = `/api/v0/feePlan/${this.user.zincUserId}`
            try {
                const res = await fetch(url, {
                    method: "POST",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ auto_charge:enable })
                })
                if (res.status !== 200) throw new Error(await res.text())
                location.reload() //show new balance
            } catch (error) {
                alert('There was an error enabling autopay. Contact support@zinc.io for assistance.') //XXX: make bootstrap modal?
            }
        },
        submitAddBalance: async function (event) {
            if (!moneyInput.checkValidity()) return; //should be noop
            const amount = Object.fromEntries(new FormData(event.target)).amount;
            $("#addBalanceModal").modal('hide')
            let url = `/api/v0/topup/${this.user.zincUserId}`
            try {
                const res = await fetch(url, {
                    method: "POST",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ amount: Math.round(amount*100), idempotency_key: (+new Date()).toString() })
                })
                if (res.status !== 200) throw new Error(await res.text())
                location.reload() //show new balance
            } catch (error) {
                alert('There was an error processing your payment. Contact support@zinc.io for assistance.') //XXX: make bootstrap modal?
            }
        }
    },
}
</script>

