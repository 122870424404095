<template>
  <div class="ev-pending-approval">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 col-sm-12 p-5 text-center">
          <h3 v-if="api_prepaid">You have not completed signing up!</h3>
          <h3 v-else>Your Zinc API access is still pending approval.</h3>
          <h5 v-if="!api_prepaid">Please check your email for updates, or contact <a href="mailto:support@zinc.io">support@zinc.io</a>.</h5>
          <h5 v-if="api_prepaid"><a href="https://login.zinc.io/fund_account">Please click here to finish signing up so you can access Zinc's API.</a></h5>
          <h5 v-else>Thanks for your patience.</h5>
        </div>
      </div>
    </div>
    <!-- <pre>
        {{loginUser}}
    </pre> -->
  </div>
</template>

<script>
export default {
  name: 'pending-approval',
  components: {},
  created() {
    // hack below uses getCurrentUser because getLoginUser doesn't refresh info from login backend, just returns cookie
    // rather than hack on an argument, which would be a little prettier, have just hacked it to use getCurrentUser which
    // always loads user info by id from login backend
    // Because getCurrentUser always hits LOGIN_URL/oidc/userinfo it can detect a change in roles
    this.$auth
     .getCurrentUser(_.get(this.loginUser, 'zincUserId'))
      .then(response => {
        // console.log('response is ', response.body)
        const roles = _.get(response, 'body.roles', [])
        if (_.includes(roles, 'api_user')) {
          //
          this.$auth.setLoginUser(this.user)
          this.$router.push('/login')
        }
        else if (_.includes(roles, 'api_prepaid')){
          this.api_prepaid = true;
        }
      })
  },
  mounted() {
    if (_.includes(_.get(this.user, 'roles', []), 'api_user')) {
      this.$router.push('/');
    }
  },
  data() {
    return {
      auth: this.$store.state.auth,
      loginUser: this.$store.state.loginUser,
      user: this.$store.state.currentUser,
      api_prepaid: false,
    }
  },
}
</script>

<style></style>
