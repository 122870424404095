var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ev-admin" }, [
    _c("div", { staticClass: "card" }, [
      _c(
        "div",
        {
          staticClass: "card-header",
          on: {
            click: function($event) {
              _vm.toggleShowSearchBox()
            }
          }
        },
        [
          _vm.showSearchBox
            ? _c("i", { staticClass: "fa fa-chevron-down" })
            : _vm._e(),
          _vm._v(" "),
          !_vm.showSearchBox
            ? _c("i", { staticClass: "fa fa-chevron-up" })
            : _vm._e(),
          _vm._v("\n          Debug an order by ID: "),
          _c("b", [_vm._v(_vm._s(_vm.title))])
        ]
      ),
      _vm._v(" "),
      _vm.showSearchBox
        ? _c(
            "div",
            { staticClass: "card-block p-3" },
            [
              _c("spinner", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading"
                  }
                ]
              }),
              _vm._v(" "),
              _c("div", { staticClass: "col-2" }, [
                _c("form", [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "searchOrderId" } }, [
                      _vm._v("Order ID")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchOrderId,
                          expression: "searchOrderId"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        size: "50",
                        id: "searchOrderId",
                        placeholder: "Enter Zinc Order ID"
                      },
                      domProps: { value: _vm.searchOrderId },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.searchOrderId = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-sm",
                        attrs: { type: "submit" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.getOrderData(_vm.searchOrderId)
                          }
                        }
                      },
                      [_vm._v("Search")]
                    )
                  ])
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.foundOrder
        ? _c("div", [
            _vm.errorBlock
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "card-header",
                      on: {
                        click: function($event) {
                          _vm.toggleShowErrorBlock()
                        }
                      }
                    },
                    [
                      _vm.showErrorBlock
                        ? _c("i", { staticClass: "fa fa-chevron-down" })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.showErrorBlock
                        ? _c("i", { staticClass: "fa fa-chevron-up" })
                        : _vm._e(),
                      _vm._v("\n            Error Block\n          ")
                    ]
                  ),
                  _vm._v(" "),
                  _vm.showErrorBlock
                    ? _c("div", { attrs: { id: "errorblock-content" } }, [
                        _c("div", { staticClass: "card-block p-3" }, [
                          _c("pre", [_vm._v(_vm._s(_vm.errorBlock))])
                        ])
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.screenshots
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "card-header",
                      on: {
                        click: function($event) {
                          _vm.toggleShowScreenshots()
                        }
                      }
                    },
                    [
                      _vm.showScreenshots
                        ? _c("i", { staticClass: "fa fa-chevron-down" })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.showScreenshots
                        ? _c("i", { staticClass: "fa fa-chevron-up" })
                        : _vm._e(),
                      _vm._v("\n            Screenshots\n          ")
                    ]
                  ),
                  _vm._v(" "),
                  _vm.showScreenshots
                    ? _c(
                        "div",
                        { attrs: { id: "screenshot-content" } },
                        _vm._l(_vm.screenshots, function(screenshot) {
                          return _c("div", { key: screenshot }, [
                            _c("div", { staticClass: "card-header" }, [
                              _c("a", { attrs: { href: screenshot } }, [
                                _vm._v(_vm._s(screenshot))
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "card-block p-3" }, [
                              _c("img", {
                                staticStyle: { width: "40%" },
                                attrs: { src: screenshot }
                              })
                            ])
                          ])
                        })
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.logContent
              ? _c("div", [
                  _c("div", { staticClass: "card-header" }, [
                    _vm._v("\n            Log "),
                    _c("a", { attrs: { href: _vm.logPath } }, [
                      _vm._v(_vm._s(_vm.logPath))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-block p-3" }, [
                    _c("pre", [_vm._v(_vm._s(_vm.logContent))])
                  ])
                ])
              : _vm._e()
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }