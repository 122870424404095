import Vue from 'vue'
import 'bootstrap'
import _ from 'lodash'

import VueResource from 'vue-resource'
import TreeView from 'vue-json-tree-view'
import router from './router'
import Auth from './auth'
import store from './store'

Vue.use(VueResource)
Vue.config.productionTip = false
Vue.use(TreeView)
Vue.use(Auth)
/* App sass */
import './assets/style/app.scss'
import './assets/style/app.css'

/* App component */
import App from './components/App.vue'

var vm = new Vue({
  el: '#app',
  // Attach the Vue instance to the window,
  // so it's available globally.
  created: function() {
    window.Vue = this
  },
  router: router,
  store,
  render: h => h(App),
})
