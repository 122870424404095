<template>
<div class="ev-signup">
    <h4>Redirecting to {{this.getLoginURL()}}/signup ... </h4>
</div>
</template>

<script>
export default {
  name: 'signup',
  components: {},
  created() {
    this.$auth.signup()
  },
  getLoginURL() {
    return utils.LOGIN_URL
  },
  data() {
    return {}
  },
}
</script>

<style>

</style>
