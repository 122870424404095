var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ev-pending-approval" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6 col-sm-12 p-5 text-center" }, [
          _vm.api_prepaid
            ? _c("h3", [_vm._v("You have not completed signing up!")])
            : _c("h3", [
                _vm._v("Your Zinc API access is still pending approval.")
              ]),
          _vm._v(" "),
          !_vm.api_prepaid
            ? _c("h5", [
                _vm._v("Please check your email for updates, or contact "),
                _c("a", { attrs: { href: "mailto:support@zinc.io" } }, [
                  _vm._v("support@zinc.io")
                ]),
                _vm._v(".")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.api_prepaid
            ? _c("h5", [
                _c(
                  "a",
                  { attrs: { href: "https://login.zinc.io/fund_account" } },
                  [
                    _vm._v(
                      "Please click here to finish signing up so you can access Zinc's API."
                    )
                  ]
                )
              ])
            : _c("h5", [_vm._v("Thanks for your patience.")])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }