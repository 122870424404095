<template>
<div class="ev-logout">
    <h4>Logging out ... </h4>
</div>
</template>

<script>
export default {
  name: 'logout',
  components: {},
  created() {
    this.$auth.logout()
  },
  data() {
    return {}
  },
}
</script>

<style>

</style>
