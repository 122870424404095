<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h4 class="mt-3 text-center">Welcome to the Zinc API Dashboard, {{ user.name }}!</h4>
                <p class="text-center">Zinc lets you buy products from popular online retailers, including Amazon.com, with
                    a
                    single POST request. Additionally, Zinc can provide prices and descriptive information about products
                    from supported retailers.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2 d-xs-none"></div>
            <div class="col-md-4 col-6">
                <div class="card" style="height: 100%">
                    <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Get your API Key</h5>
                        <p class="card-text">In order to get started, you will need your Zinc Client Token.</p>
                        <div class="flex-grow-1"></div>
                        <button type="button" class="btn btn-success my-2" data-toggle="modal"
                            data-target="#apiCredentialsModal">
                            View Credentials
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-6">
                <div class="card" style="height: 100%">
                    <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Examples & Docs</h5>
                        <p class="card-text">Head over to our <a href="http://docs.zincapi.com" target="_blank">API Docs</a>
                            for examples and documentation of how to use the API.</p>
                        <div class="flex-grow-1"></div>
                        <a class="btn btn-primary my-2" href="http://docs.zincapi.com" target="_blank">
                            View API Docs
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pt-3">
            <div class="col-md-2 d-xs-none"></div>
            <div class="col-12 col-md-8">
                <div class="card">
                    <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Try it Now?</h5>
                        <div class="form-group">
                            <small id="asinHelp" class="form-text text-muted">Enter a Amazon.com ASIN to generate code to
                                get product details</small>
                            <input class="form-control" id="asinInput" value="B081QSJNRJ" @input="onAsinChange"
                                aria-describedby="asinHelp" placeholder="Enter ASIN">
                        </div>
                        <p>Run the following in a language of your choice to get product details for the ASIN you entered
                            using your credentials.
                        </p>
                        <pre
                            style="background-color: #f8f9fa; font-size: 11px; padding: 5px; border: 1px solid #d1d1d1; border-radius: 4px; overflow-x: auto;"><code v-text="exampleCode"></code></pre>
                        <p class="mb-0">Our <a href="http://docs.zincapi.com" target="_blank">API Docs</a> are full of more
                            APIs and examples to
                            help you get started quickly.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-md-2 d-xs-none"></div>
            <div class="col-12 col-md-8">
                <div class="card mt-0">
                    <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Ordering Quickstart</h5>
                        <ZmaTerms :allowReject="false">
                            <div>
                                <a href="https://docs.zincapi.com/#zinc-managed-accounts">Zinc Managed Accounts (ZMA)</a>
                                make
                                it easy to place orders on Amazon. We have provided you with a
                                promotional credit to get started.
                            </div>
                            <form @input="onOrderFormChange" class="mt-2">
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label for="orderInputAsin">ASIN</label>
                                        <input type="text" value="B007YX9O9E" class="form-control" id="orderInputAsin"
                                            placeholder="ASIN">
                                        <small class="form-text text-muted">
                                            Enter a Amazon.com ASIN to order it
                                        </small>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="maxPriceInput">Max Price</label>
                                        <div class="input-group">
                                            <input type="number" value="1700" id="maxPriceInput"
                                                tooltip="Maximum price of order in cents" class="form-control"
                                                placeholder="Cents">
                                            <div class="input-group-append">
                                                <span class="input-group-text">¢</span>
                                            </div>
                                        </div>
                                        <small class="form-text text-muted">
                                            Maximum price of order in cents
                                        </small>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label for="inputFirstName">First Name</label>
                                        <input type="text" value="Ziggy" class="form-control" id="inputFirstName">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="inputLastName">Last Name</label>
                                        <input type="text" value="Stardust" class="form-control" id="inputLastName">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="inputAddress">Address</label>
                                    <input type="text" class="form-control" id="inputAddress" placeholder="1234 Main St">
                                </div>
                                <div class="form-group">
                                    <label for="inputAddress2">Address 2</label>
                                    <input type="text" class="form-control" id="inputAddress2">
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label for="inputCity">City</label>
                                        <input type="text" class="form-control" id="inputCity">
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="inputState">State</label>
                                        <select id="inputState" class="form-control">
                                            <option selected></option>
                                            <option value="AL">Alabama</option>
                                            <option value="AK">Alaska</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="CA">California</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="DE">Delaware</option>
                                            <option value="DC">District Of Columbia</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MT">Montana</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NH">New Hampshire</option>
                                            <option value="NJ">New Jersey</option>
                                            <option value="NM">New Mexico</option>
                                            <option value="NY">New York</option>
                                            <option value="NC">North Carolina</option>
                                            <option value="ND">North Dakota</option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="RI">Rhode Island</option>
                                            <option value="SC">South Carolina</option>
                                            <option value="SD">South Dakota</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">Washington</option>
                                            <option value="WV">West Virginia</option>
                                            <option value="WI">Wisconsin</option>
                                            <option value="WY">Wyoming</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-2">
                                        <label for="inputZip">Zip</label>
                                        <input type="text" class="form-control" id="inputZip">
                                    </div>
                                </div>
                            </form>
                            <ul class="nav nav-tabs">
                                <li class="nav-item">
                                    <a class="nav-link active" id="python-tab" data-toggle="tab" href="#python">Python
                                        Example</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="javascript-tab" data-toggle="tab" href="#javascript">Browser
                                        Javascript Example</a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="python">
                                    <pre
                                        style="background-color: #f8f9fa; font-size: 11px; padding: 5px; border: 1px solid #d1d1d1; border-radius: 4px; overflow-x: auto;"><code v-text="examplePythonOrderCode"></code></pre>
                                </div>
                                <div class="tab-pane fade" id="javascript">
                                    <pre
                                        style="background-color: #f8f9fa; font-size: 11px; padding: 5px; border: 1px solid #d1d1d1; border-radius: 4px; overflow-x: auto;"><code v-text="exampleJSOrderCode"></code></pre>
                                </div>
                            </div>
                            <p class="mb-0">
                                After you place an order you can track using <a href="/-/orders?limit=100&offset=0">our
                                    dashboard</a>.

                                Our <a href="http://docs.zincapi.com" target="_blank">API Docs</a> are full of more
                                APIs and examples to
                                help you get started quickly!</p>
                        </ZmaTerms>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ZmaTerms from '../common/ZmaTerms.vue'

export default {
    name: 'home',
    components: { ZmaTerms },
    data() {
        return {
            isLoading: false,
            auth: this.$store.state.auth,
            user: this.$store.state.currentUser,
            exampleCode: '',
            examplePythonOrderCode: '# Fill in order details above to generate code',
            exampleJSOrderCode: '// Fill in order details above to generate code',
        }
    },
    mounted() {
        this.onAsinChange()
    },
    methods: {
        onAsinChange: _.debounce(function () {
            const str = document.getElementById('asinInput').value
            const ASIN_REGEX = /\d{12}|B0\w{8}|\d{9}[0-9X]/i;
            const asin = ASIN_REGEX.exec(str) && ASIN_REGEX.exec(str)[0] || "<ASIN_HERE>";
            const examples = [
                `# bash Example`,
                `curl -u ${this.user.zincClientToken}: \\\n  'https://api.zinc.io/v1/products/${asin}?retailer=amazon'`,
                '',
                '# python Example',
                `import requests\nrequests.get('https://api.zinc.io/v1/products/${asin}?retailer=amazon',\n    auth=('${this.user.zincClientToken}', '')).json()`,
                '',
                '// Browser Javascript Example',
                `var url = 'https://api.zinc.io/v1/products/${asin}?retailer=amazon'`,
                `var headers = new Headers({'Authorization':'Basic '+btoa('${this.user.zincClientToken}:')})`,
                `var res = await fetch(url,{headers})`,
                `console.log(await res.json())`,
            ]
            this.exampleCode = examples.join('\n')
        }, 50),
        onOrderFormChange: _.debounce(function () {
            const data = {
                "retailer": "amazon",
                "products": [{
                    "product_id": document.getElementById('orderInputAsin').value,
                    "quantity": 1
                }],
                "shipping_address": {
                    "first_name": document.getElementById('inputFirstName').value,
                    "last_name": document.getElementById('inputLastName').value,
                    "address_line1": document.getElementById('inputAddress').value,
                    "address_line2": document.getElementById('inputAddress2').value,
                    "zip_code": document.getElementById('inputZip').value,
                    "city": document.getElementById('inputCity').value,
                    "state": document.getElementById('inputState').value,
                    "country": "US",
                    "phone_number": "5132229890"
                },
                "shipping_method": "cheapest",
                "max_price": document.getElementById('maxPriceInput').value,
                "addax": true,
                "client_notes": { "note": "My first ZMA Order" },
            }
            let example = [
                'import requests',
                `url = 'https://api.zinc.io/v1/orders'`,
                `data = ${JSON.stringify(data, null, 2).replace('"addax": true', '"addax": True')}`,
                `resp = requests.post(url, auth=('${this.user.zincClientToken}', ''), json=data)`,
                `request_id = resp.json()["request_id"] #id that tracks the order.`,
                `print("Visit https://dash.zinc.io/-/orders/" + request_id + " to track your order")`
            ]
            this.examplePythonOrderCode = example.join('\n')
            example = [
                'var url = \'https://api.zinc.io/v1/orders\'',
                `var headers = new Headers({'Authorization':'Basic '+btoa('${this.user.zincClientToken}:'), 'Content-Type': 'application/json'})`,
                `var data = ${JSON.stringify(data, null, 2)};`,
                `var res = await fetch(url,{method:"POST", headers, body: JSON.stringify(data)})`,
                `res = await res.json()`,
                `var request_id = res.request_id //id that tracks the order.`,
                `console.log("Visit https://dash.zinc.io/-/orders/" + request_id + " to track your order")`,
            ]
            this.exampleJSOrderCode = example.join('\n')
        }, 50),
    },
}
</script>

<style></style>
