var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "spinner-container" }, [
    _c("div", { staticClass: "spinner-content" }, [
      _c(
        "svg",
        {
          staticClass: "spinner",
          attrs: {
            width: "65px",
            height: "65px",
            viewBox: "0 0 66 66",
            xmlns: "http://www.w3.org/2000/svg"
          }
        },
        [
          _c("circle", {
            staticClass: "path",
            attrs: {
              fill: "none",
              "stroke-width": "6",
              "stroke-linecap": "round",
              cx: "33",
              cy: "33",
              r: "30"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "spinner-message" }, [
        _vm._v(_vm._s(_vm.message))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }