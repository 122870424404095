var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ev-balance", attrs: { id: "ev-balance" } }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-md-10" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("h6", { staticClass: "text-muted font-weight-normal mb-3" }, [
                _vm._v("Current ZMA Balances for " + _vm._s(_vm.user.name))
              ]),
              _vm._v(" "),
              _c("spinner", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading"
                  }
                ]
              }),
              _vm._v(" "),
              _c("div", { staticClass: "pull-in bg-light p-3" }, [
                _c(
                  "div",
                  { staticClass: "row d-flex justify-content-around" },
                  _vm._l(_vm.addaxCurrencies, function(addaxCurrency) {
                    return _c("div", { staticClass: "text-center" }, [
                      _c("p", { staticClass: "text-muted mb-0 mt-3" }, [
                        _vm._v(_vm._s(addaxCurrency.code))
                      ]),
                      _vm._v(" "),
                      _c("h4", [
                        _vm._v(_vm._s(addaxCurrency.symbol)),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.getPrettyBalancePrice(
                                _vm.balances,
                                addaxCurrency.code
                              )
                            )
                          }
                        })
                      ])
                    ])
                  })
                )
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-outline-primary btn-block mt-4",
                  attrs: {
                    href:
                      "https://www.priceyak.com/user/" +
                      _vm.user.zincUserId +
                      "/managedaccount?apiLayout=1",
                    target: "_blank"
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-plus px-1",
                    attrs: { "aria-hidden": "true" }
                  }),
                  _vm._v("Fund your account\n          ")
                ]
              )
            ],
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    !_vm.tos_accept_time
      ? _c(
          "div",
          { staticClass: "row justify-content-center" },
          [
            _c(
              "Card",
              { attrs: { cssClass: "col-md-10", title: "Terms of Service" } },
              [_c("ZmaTerms")],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-md-10" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("h6", { staticClass: "text-muted font-weight-normal mb-3" }, [
                _vm._v("Transaction History")
              ]),
              _vm._v(" "),
              _c("spinner", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading"
                  }
                ]
              }),
              _vm._v(" "),
              _c("div", { staticClass: "row justify-content-center" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "btn-group btn-group-toggle" }, [
                    _c(
                      "label",
                      {
                        staticClass: "btn btn-light",
                        class: { active: _vm.picked_txn_view == "all_txns" }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.picked_txn_view,
                              expression: "picked_txn_view"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            id: "option1",
                            value: "all_txns"
                          },
                          domProps: {
                            checked: _vm._q(_vm.picked_txn_view, "all_txns")
                          },
                          on: {
                            change: function($event) {
                              _vm.picked_txn_view = "all_txns"
                            }
                          }
                        }),
                        _vm._v(" All txns\n                ")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "btn btn-light",
                        class: {
                          active: _vm.picked_txn_view == "deposits_only"
                        }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.picked_txn_view,
                              expression: "picked_txn_view"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            id: "option2",
                            value: "deposits_only"
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.picked_txn_view,
                              "deposits_only"
                            )
                          },
                          on: {
                            change: function($event) {
                              _vm.picked_txn_view = "deposits_only"
                            }
                          }
                        }),
                        _vm._v(" Deposits only\n                ")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "btn btn-light",
                        class: {
                          active: _vm.picked_txn_view == "admin_adjustment"
                        }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.picked_txn_view,
                              expression: "picked_txn_view"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            id: "option2",
                            value: "admin_adjustment"
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.picked_txn_view,
                              "admin_adjustment"
                            )
                          },
                          on: {
                            change: function($event) {
                              _vm.picked_txn_view = "admin_adjustment"
                            }
                          }
                        }),
                        _vm._v(" Admin Adjustments\n                ")
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12" }, [
                  _c("table", { staticClass: "table table-striped" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      [
                        _vm.transactions.length > 0
                          ? _vm._l(_vm.transactions, function(transaction) {
                              return _c("tr", [
                                _c(
                                  "td",
                                  { staticClass: "text-nowrap text-left" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getPrettyDateFromUnix(
                                          transaction.created_time
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "text-nowrap text-center" },
                                  [
                                    transaction.addax_zinc_order_id
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "/" +
                                                _vm.user.zincUserId +
                                                "/orders/" +
                                                transaction.addax_zinc_order_id
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                _vm.viewOrder(
                                                  transaction.addax_zinc_order_id
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("View Order")]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-right zn-price",
                                    class: _vm.getColorClassName(transaction)
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getPrettyTransactionPrice(
                                          transaction
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "font-family": "monospace",
                                      "font-size": "1.15em"
                                    }
                                  },
                                  [_vm._v(_vm._s(transaction.id))]
                                ),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-left" }, [
                                  _vm._v(_vm._s(transaction.note))
                                ])
                              ])
                            })
                          : [
                              _c("h4", { staticClass: "pt-3 mt-3" }, [
                                _vm._v("No transactions found.")
                              ])
                            ]
                      ],
                      2
                    )
                  ])
                ])
              ])
            ],
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.tos_accept_time
      ? _c(
          "div",
          { staticClass: "row justify-content-center" },
          [
            _c(
              "Card",
              { attrs: { cssClass: "col-md-10", title: "Terms of Service" } },
              [_c("ZmaTerms")],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-md-10" }, [
        _c("div", { staticClass: "text-center" }, [
          _c("h3", { staticClass: "my-4" }, [_vm._v("Zinc Managed Accounts")]),
          _vm._v(" "),
          _c("div", { staticClass: "text-muted" }, [
            _c("p", [
              _c("strong", [_vm._v("Zinc Managed Accounts (ZMA)")]),
              _vm._v(
                " take the headache out of opening, maintaining, and funding\n            Amazon and other retailer accounts, allowing you to focus on growing your business."
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Using managed accounts is totally free! You just pay your standard AutoOrdering (AO) Fee. Managed accounts\n            are currently only available for Amazon US, UK and Germany."
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("Learn more about managed accounts "),
              _c(
                "a",
                {
                  attrs: {
                    href: "http://docs.zincapi.com/#zinc-managed-accounts",
                    target: "_blank"
                  }
                },
                [_vm._v("here")]
              ),
              _vm._v(" and their benefits "),
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "https://support.priceyak.com/hc/en-us/articles/115003853443-About-Managed-Accounts",
                    target: "_blank"
                  }
                },
                [_vm._v("here")]
              ),
              _vm._v(".")
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "8%" } }, [_vm._v("Created Time")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-center", staticStyle: { width: "5%" } }, [
          _vm._v("Order")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", staticStyle: { width: "5%" } }, [
          _vm._v("Amount")
        ]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Transaction Id")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-left" }, [_vm._v("Notes")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }