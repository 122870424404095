let initialState = {
  auth: {
    isLoggedIn: false,
    accessToken: null,
    // refreshToken: null
  },
  loginUser: {
    name: null,
    email: null,
    zincClientToken: null,
    zincUserId: null,
    isAdmin: null,
  },
  currentUser: {
    name: null,
    email: null,
    zincClientToken: null,
    zincUserId: null,
    isAdmin: null,
  },
}

// Other state (not synced in local storage)
initialState.appnav = {
  searchText: '',
  searchTimestamp: null,
}

export const state = initialState
