var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h4", { staticClass: "mt-3 text-center" }, [
          _vm._v(
            "Welcome to the Zinc API Dashboard, " + _vm._s(_vm.user.name) + "!"
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "text-center" }, [
          _vm._v(
            "Zinc lets you buy products from popular online retailers, including Amazon.com, with\n                a\n                single POST request. Additionally, Zinc can provide prices and descriptive information about products\n                from supported retailers."
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row pt-3" }, [
      _c("div", { staticClass: "col-md-2 d-xs-none" }),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-8" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body d-flex flex-column" }, [
            _c("h5", { staticClass: "card-title" }, [_vm._v("Try it Now?")]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "small",
                {
                  staticClass: "form-text text-muted",
                  attrs: { id: "asinHelp" }
                },
                [
                  _vm._v(
                    "Enter a Amazon.com ASIN to generate code to\n                            get product details"
                  )
                ]
              ),
              _vm._v(" "),
              _c("input", {
                staticClass: "form-control",
                attrs: {
                  id: "asinInput",
                  value: "B081QSJNRJ",
                  "aria-describedby": "asinHelp",
                  placeholder: "Enter ASIN"
                },
                on: { input: _vm.onAsinChange }
              })
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Run the following in a language of your choice to get product details for the ASIN you entered\n                        using your credentials.\n                    "
              )
            ]),
            _vm._v(" "),
            _c(
              "pre",
              {
                staticStyle: {
                  "background-color": "#f8f9fa",
                  "font-size": "11px",
                  padding: "5px",
                  border: "1px solid #d1d1d1",
                  "border-radius": "4px",
                  "overflow-x": "auto"
                }
              },
              [
                _c("code", {
                  domProps: { textContent: _vm._s(_vm.exampleCode) }
                })
              ]
            ),
            _vm._v(" "),
            _vm._m(1)
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-5" }, [
      _c("div", { staticClass: "col-md-2 d-xs-none" }),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-8" }, [
        _c("div", { staticClass: "card mt-0" }, [
          _c(
            "div",
            { staticClass: "card-body d-flex flex-column" },
            [
              _c("h5", { staticClass: "card-title" }, [
                _vm._v("Ordering Quickstart")
              ]),
              _vm._v(" "),
              _c("ZmaTerms", { attrs: { allowReject: false } }, [
                _c("div", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://docs.zincapi.com/#zinc-managed-accounts"
                      }
                    },
                    [_vm._v("Zinc Managed Accounts (ZMA)")]
                  ),
                  _vm._v(
                    "\n                            make\n                            it easy to place orders on Amazon. We have provided you with a\n                            promotional credit to get started.\n                        "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  { staticClass: "mt-2", on: { input: _vm.onOrderFormChange } },
                  [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c("label", { attrs: { for: "orderInputAsin" } }, [
                          _vm._v("ASIN")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            value: "B007YX9O9E",
                            id: "orderInputAsin",
                            placeholder: "ASIN"
                          }
                        }),
                        _vm._v(" "),
                        _c("small", { staticClass: "form-text text-muted" }, [
                          _vm._v(
                            "\n                                        Enter a Amazon.com ASIN to order it\n                                    "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c("label", { attrs: { for: "maxPriceInput" } }, [
                          _vm._v("Max Price")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group" }, [
                          _c("input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "number",
                              value: "1700",
                              id: "maxPriceInput",
                              tooltip: "Maximum price of order in cents",
                              placeholder: "Cents"
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "input-group-append" }, [
                            _c("span", { staticClass: "input-group-text" }, [
                              _vm._v("¢")
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("small", { staticClass: "form-text text-muted" }, [
                          _vm._v(
                            "\n                                        Maximum price of order in cents\n                                    "
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c("label", { attrs: { for: "inputFirstName" } }, [
                          _vm._v("First Name")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            value: "Ziggy",
                            id: "inputFirstName"
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c("label", { attrs: { for: "inputLastName" } }, [
                          _vm._v("Last Name")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            value: "Stardust",
                            id: "inputLastName"
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "inputAddress" } }, [
                        _vm._v("Address")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "inputAddress",
                          placeholder: "1234 Main St"
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "inputAddress2" } }, [
                        _vm._v("Address 2")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "form-control",
                        attrs: { type: "text", id: "inputAddress2" }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c("label", { attrs: { for: "inputCity" } }, [
                          _vm._v("City")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: { type: "text", id: "inputCity" }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group col-md-4" }, [
                        _c("label", { attrs: { for: "inputState" } }, [
                          _vm._v("State")
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            staticClass: "form-control",
                            attrs: { id: "inputState" }
                          },
                          [
                            _c("option", { attrs: { selected: "" } }),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "AL" } }, [
                              _vm._v("Alabama")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "AK" } }, [
                              _vm._v("Alaska")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "AZ" } }, [
                              _vm._v("Arizona")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "AR" } }, [
                              _vm._v("Arkansas")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "CA" } }, [
                              _vm._v("California")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "CO" } }, [
                              _vm._v("Colorado")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "CT" } }, [
                              _vm._v("Connecticut")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "DE" } }, [
                              _vm._v("Delaware")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "DC" } }, [
                              _vm._v("District Of Columbia")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "FL" } }, [
                              _vm._v("Florida")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "GA" } }, [
                              _vm._v("Georgia")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "HI" } }, [
                              _vm._v("Hawaii")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "ID" } }, [
                              _vm._v("Idaho")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "IL" } }, [
                              _vm._v("Illinois")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "IN" } }, [
                              _vm._v("Indiana")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "IA" } }, [
                              _vm._v("Iowa")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "KS" } }, [
                              _vm._v("Kansas")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "KY" } }, [
                              _vm._v("Kentucky")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "LA" } }, [
                              _vm._v("Louisiana")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "ME" } }, [
                              _vm._v("Maine")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "MD" } }, [
                              _vm._v("Maryland")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "MA" } }, [
                              _vm._v("Massachusetts")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "MI" } }, [
                              _vm._v("Michigan")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "MN" } }, [
                              _vm._v("Minnesota")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "MS" } }, [
                              _vm._v("Mississippi")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "MO" } }, [
                              _vm._v("Missouri")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "MT" } }, [
                              _vm._v("Montana")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "NE" } }, [
                              _vm._v("Nebraska")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "NV" } }, [
                              _vm._v("Nevada")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "NH" } }, [
                              _vm._v("New Hampshire")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "NJ" } }, [
                              _vm._v("New Jersey")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "NM" } }, [
                              _vm._v("New Mexico")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "NY" } }, [
                              _vm._v("New York")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "NC" } }, [
                              _vm._v("North Carolina")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "ND" } }, [
                              _vm._v("North Dakota")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "OH" } }, [
                              _vm._v("Ohio")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "OK" } }, [
                              _vm._v("Oklahoma")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "OR" } }, [
                              _vm._v("Oregon")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "PA" } }, [
                              _vm._v("Pennsylvania")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "RI" } }, [
                              _vm._v("Rhode Island")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "SC" } }, [
                              _vm._v("South Carolina")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "SD" } }, [
                              _vm._v("South Dakota")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "TN" } }, [
                              _vm._v("Tennessee")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "TX" } }, [
                              _vm._v("Texas")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "UT" } }, [
                              _vm._v("Utah")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "VT" } }, [
                              _vm._v("Vermont")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "VA" } }, [
                              _vm._v("Virginia")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "WA" } }, [
                              _vm._v("Washington")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "WV" } }, [
                              _vm._v("West Virginia")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "WI" } }, [
                              _vm._v("Wisconsin")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "WY" } }, [
                              _vm._v("Wyoming")
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group col-md-2" }, [
                        _c("label", { attrs: { for: "inputZip" } }, [
                          _vm._v("Zip")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: { type: "text", id: "inputZip" }
                        })
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("ul", { staticClass: "nav nav-tabs" }, [
                  _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link active",
                        attrs: {
                          id: "python-tab",
                          "data-toggle": "tab",
                          href: "#python"
                        }
                      },
                      [
                        _vm._v(
                          "Python\n                                    Example"
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          id: "javascript-tab",
                          "data-toggle": "tab",
                          href: "#javascript"
                        }
                      },
                      [
                        _vm._v(
                          "Browser\n                                    Javascript Example"
                        )
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "tab-content" }, [
                  _c(
                    "div",
                    {
                      staticClass: "tab-pane fade show active",
                      attrs: { id: "python" }
                    },
                    [
                      _c(
                        "pre",
                        {
                          staticStyle: {
                            "background-color": "#f8f9fa",
                            "font-size": "11px",
                            padding: "5px",
                            border: "1px solid #d1d1d1",
                            "border-radius": "4px",
                            "overflow-x": "auto"
                          }
                        },
                        [
                          _c("code", {
                            domProps: {
                              textContent: _vm._s(_vm.examplePythonOrderCode)
                            }
                          })
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "tab-pane fade",
                      attrs: { id: "javascript" }
                    },
                    [
                      _c(
                        "pre",
                        {
                          staticStyle: {
                            "background-color": "#f8f9fa",
                            "font-size": "11px",
                            padding: "5px",
                            border: "1px solid #d1d1d1",
                            "border-radius": "4px",
                            "overflow-x": "auto"
                          }
                        },
                        [
                          _c("code", {
                            domProps: {
                              textContent: _vm._s(_vm.exampleJSOrderCode)
                            }
                          })
                        ]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    "\n                            After you place an order you can track using "
                  ),
                  _c("a", { attrs: { href: "/-/orders?limit=100&offset=0" } }, [
                    _vm._v("our\n                                dashboard")
                  ]),
                  _vm._v(".\n\n                            Our "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "http://docs.zincapi.com",
                        target: "_blank"
                      }
                    },
                    [_vm._v("API Docs")]
                  ),
                  _vm._v(
                    " are full of more\n                            APIs and examples to\n                            help you get started quickly!"
                  )
                ])
              ])
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-2 d-xs-none" }),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-4 col-6" }, [
        _c("div", { staticClass: "card", staticStyle: { height: "100%" } }, [
          _c("div", { staticClass: "card-body d-flex flex-column" }, [
            _c("h5", { staticClass: "card-title" }, [
              _vm._v("Get your API Key")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "card-text" }, [
              _vm._v(
                "In order to get started, you will need your Zinc Client Token."
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-grow-1" }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-success my-2",
                attrs: {
                  type: "button",
                  "data-toggle": "modal",
                  "data-target": "#apiCredentialsModal"
                }
              },
              [
                _vm._v(
                  "\n                        View Credentials\n                    "
                )
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-4 col-6" }, [
        _c("div", { staticClass: "card", staticStyle: { height: "100%" } }, [
          _c("div", { staticClass: "card-body d-flex flex-column" }, [
            _c("h5", { staticClass: "card-title" }, [
              _vm._v("Examples & Docs")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "card-text" }, [
              _vm._v("Head over to our "),
              _c(
                "a",
                {
                  attrs: { href: "http://docs.zincapi.com", target: "_blank" }
                },
                [_vm._v("API Docs")]
              ),
              _vm._v(
                "\n                        for examples and documentation of how to use the API."
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-grow-1" }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-primary my-2",
                attrs: { href: "http://docs.zincapi.com", target: "_blank" }
              },
              [
                _vm._v(
                  "\n                        View API Docs\n                    "
                )
              ]
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mb-0" }, [
      _vm._v("Our "),
      _c(
        "a",
        { attrs: { href: "http://docs.zincapi.com", target: "_blank" } },
        [_vm._v("API Docs")]
      ),
      _vm._v(
        " are full of more\n                        APIs and examples to\n                        help you get started quickly."
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }