<template>
    <div v-if="!tos_accept_time">
        <p>You have not accepted the <a
                href="https://support.priceyak.com/hc/en-us/articles/7726088076948-Terms-and-Services-for-ZMA-Platform-Buyers-">ZMA
                ToS</a>. Please review the <a
                href="https://support.priceyak.com/hc/en-us/articles/7726088076948-Terms-and-Services-for-ZMA-Platform-Buyers-">ZMA
                ToS</a> and click below to accept the ToS. You will not be able to place orders until you accept the ToS</p>
        <button class="btn btn-primary" @click="acceptTos()">Accept ToS</button>
    </div>
    <div v-else-if="allowReject">
        <p>Thank you for accepting the <a target="_blank"
                href="https://support.priceyak.com/hc/en-us/articles/7726088076948-Terms-and-Services-for-ZMA-Platform-Buyers-">ZMA
                ToS</a>. You may withdraw consent if you like, but ZMA ordering and case support will stop
            working.</p>
        <button class="btn btn-danger" @click="rejectTos()">Reject ToS</button>
    </div>

    <div v-else>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'ZmaTerms',
    props: {
        allowReject: {
            type: Boolean,
            default: true,
        },
    },
    created() {
        this.getTosState();
    },
    data() {
        return {
            tos_accept_time: false,
        }
    },
    methods: {
        async getTosState() {
            let response = await utils.apiGet('/addax/tos');
            this.tos_accept_time = response.body.tos_accept_time;
        },
        async acceptTos() {
            if (!confirm("Confirm that you have read and accept the ZMA ToS?")) return;
            await utils.apiPost('/addax/tos', { accept: true });
            this.tos_accept_time = true;
        },
        async rejectTos() {
            if (!confirm("Confirm that you have want to reject the ZMA ToS?")) return;
            await utils.apiPost('/addax/tos', { accept: false });
            this.tos_accept_time = false;
        },
    }

}
</script>

<style lang="scss" scoped>
small,
.small {
    font-size: 90% !important;
}
</style>
