var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "nav",
      { staticClass: "navbar navbar-expand-lg navbar-light bg-light" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "navbar-brand",
            attrs: {
              to: {
                name: "home",
                params: { zincUserId: _vm.get(_vm.user, "zincUserId") }
              }
            }
          },
          [
            _c("img", {
              staticClass: "ev-appnav__logo",
              attrs: { src: "/assets/images/logo.png" }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "collapse navbar-collapse",
            attrs: { id: "navbarCollapse" }
          },
          [
            _vm.auth.isLoggedIn
              ? [
                  _vm.isApiUserApproved()
                    ? [
                        _c("ul", { staticClass: "navbar-nav mr-auto" }, [
                          _c(
                            "li",
                            { staticClass: "nav-item active" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "nav-link",
                                  attrs: {
                                    to: {
                                      name: "orders",
                                      params: {
                                        zincUserId: _vm.user.zincUserId
                                      }
                                    }
                                  }
                                },
                                [_vm._v("Orders ")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            { staticClass: "nav-item active" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "nav-link",
                                  attrs: {
                                    to: {
                                      name: "balance",
                                      params: {
                                        zincUserId: _vm.user.zincUserId
                                      }
                                    }
                                  }
                                },
                                [_vm._v("ZMA Balance ")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            { staticClass: "nav-item active" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "nav-link",
                                  attrs: {
                                    to: {
                                      name: "usage",
                                      params: {
                                        zincUserId: _vm.user.zincUserId
                                      }
                                    }
                                  }
                                },
                                [_vm._v("Usage ")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.isPrepaid()
                            ? _c(
                                "li",
                                { staticClass: "nav-item active" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        to: {
                                          name: "billing",
                                          params: {
                                            zincUserId: _vm.user.zincUserId
                                          }
                                        }
                                      }
                                    },
                                    [_vm._v("Billing ")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "li",
                            { staticClass: "nav-item active" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "nav-link",
                                  attrs: {
                                    to: {
                                      name: "coyote",
                                      params: {
                                        zincUserId: _vm.user.zincUserId
                                      }
                                    }
                                  }
                                },
                                [_vm._v("Coyote ")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.loginUser.isAdmin
                            ? _c(
                                "li",
                                { staticClass: "nav-item active zn-admin" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        to: {
                                          name: "admin-order-finder",
                                          params: {
                                            zincUserId: _vm.loginUser.zincUserId
                                          }
                                        }
                                      }
                                    },
                                    [_vm._v("Admin ")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.loginUser.isAdmin
                            ? _c(
                                "li",
                                { staticClass: "nav-item active zn-admin" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        to: {
                                          name: "admin-manage-users",
                                          params: {
                                            zincUserId: _vm.loginUser.zincUserId
                                          }
                                        }
                                      }
                                    },
                                    [_vm._v("Users ")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.loginUser.isAdmin
                            ? _c(
                                "li",
                                { staticClass: "nav-item active zn-admin" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        to: {
                                          name: "admin-order-debugger",
                                          params: {
                                            zincUserId: _vm.loginUser.zincUserId
                                          }
                                        }
                                      }
                                    },
                                    [_vm._v("Order Debug ")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _vm._m(1)
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "navbar-nav nav-right ev-appnav__logout" },
                    [
                      _c("li", { staticClass: "nav-item pr-3 py-1" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: this.getLoginURL() + "/profile",
                              target: "_blank"
                            }
                          },
                          [
                            _c(
                              "button",
                              { staticClass: "btn btn-outline-primary" },
                              [
                                _vm._v(_vm._s(_vm.loginUser.name) + "   "),
                                _c("i", { staticClass: "fa fa-user" })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.loginUser.zincUserId != _vm.user.zincUserId
                          ? _c("div", { staticClass: "zn-admin" }, [
                              _c("p", [
                                _vm._v(
                                  "Impersonating " + _vm._s(_vm.user.name)
                                ),
                                _c("br"),
                                _vm._v(" (" + _vm._s(_vm.user.email) + ")")
                              ])
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "nav-item py-1" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                _vm.logout()
                              }
                            }
                          },
                          [
                            _c(
                              "button",
                              { staticClass: "btn btn-outline-primary" },
                              [_vm._v("Logout")]
                            )
                          ]
                        )
                      ])
                    ]
                  )
                ]
              : [
                  _c("ul", { staticClass: "navbar-nav mr-auto" }, [
                    _c(
                      "li",
                      { staticClass: "nav-item active" },
                      [
                        _c(
                          "router-link",
                          { staticClass: "nav-link", attrs: { to: "login" } },
                          [
                            _vm._v("Login "),
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("(current)")
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]
          ],
          2
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "apiCredentialsModal",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "apiCredentialsModalLabel",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog modal-lg", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body text-center" }, [
                _c("h5", [_vm._v("Your Zinc API Client Token is")]),
                _vm._v(" "),
                _c("h5", [
                  _c("mark", [
                    _c("strong", [_vm._v(_vm._s(_vm.user.zincClientToken))])
                  ])
                ]),
                _vm._v(" "),
                _vm._m(3),
                _vm._v(" "),
                _vm.loginUser.isAdmin
                  ? _c("p", { staticClass: "zn-admin" }, [
                      _vm._v(
                        "Key for " +
                          _vm._s(_vm.user.name) +
                          " - " +
                          _vm._s(_vm.user.email)
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm._m(4)
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "navbar-toggler navbar-toggler-right",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": "#navbarCollapse",
          "aria-controls": "navbarCollapse",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation"
        }
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-inline mt-2 mt-md-0 py-1" }, [
      _c(
        "button",
        {
          staticClass: "ev-appnav__search btn btn-outline-success my-2 my-sm-0",
          attrs: {
            "data-toggle": "modal",
            "data-target": "#apiCredentialsModal"
          }
        },
        [_vm._v("API Credentials")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        {
          staticClass: "modal-title text-center",
          staticStyle: { flex: "1" },
          attrs: { id: "apiCredentialsModalLabel" }
        },
        [_vm._v("Zinc API Credentials")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n                For detailed instructions on how to use the API, along with examples, please see our "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "http://docs.zincapi.com",
            target: "_blank",
            rel: "noopener noreferrer"
          }
        },
        [_vm._v("API docs")]
      ),
      _vm._v(".\n              ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-secondary",
          attrs: { type: "button", "data-dismiss": "modal" }
        },
        [_vm._v("Close")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }