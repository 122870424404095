var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Card", { attrs: { title: "Prepaid Billing Transactions" } }, [
    _c("table", { staticClass: "table" }, [
      _c("thead", { staticClass: "text-muted" }, [
        _c("tr", [
          _c("th", [_vm._v("Time")]),
          _vm._v(" "),
          _c("th", [_vm._v("Description")]),
          _vm._v(" "),
          _c("th", [_vm._v("Amount")])
        ])
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.prepaidLedger.ledger, function(line) {
          return _c("tr", { staticClass: "m-3" }, [
            _c("td", [_vm._v(_vm._s(_vm.formatDate(line.created_at)))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(line.description))]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                _vm._s(_vm.getPrettyPrice("$", line.amount)) +
                  " " +
                  _vm._s(line.unit)
              )
            ])
          ])
        })
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "clearfix" }, [
      _vm.offset() != 0
        ? _c(
            "button",
            {
              staticClass: "btn btn-outline-primary float-left",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return (function() {
                    return _vm.prevPage()
                  })($event)
                }
              }
            },
            [_vm._v("Prev")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.prepaidLedger.ledger.length == 10
        ? _c(
            "button",
            {
              staticClass: "btn btn-outline-primary float-right",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return (function() {
                    return _vm.nextPage()
                  })($event)
                }
              }
            },
            [_vm._v("Next")]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }