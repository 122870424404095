<template>
  <div><spinner v-show="loading" /><slot name="utilizar-view" :data="data" v-if="!loading"></slot></div>
</template>

<script>
import Spinner from '../common/Spinner.vue'

export default {
  name: 'withUtilizar',
  components: { Spinner },
  data() {
    return {
      auth: this.$store.state.auth,
      user: this.$store.state.currentUser,
      loginUser: this.$store.state.loginUser,
      data: {},
      loading: true,
    }
  },

  props: {
    date: {
      type: String,
      required: true,
    },
  },

  created() {
    let momentDate = moment(this.date)
    this.getAndSetData(momentDate)
  },

  watch: {
    date: function(newDate) {
      let momentDate = moment(newDate)
      this.getAndSetData(momentDate)
    },
  },

  methods: {
    getAndSetData(momentDate) {
      this.loading = true
      this.getUtilizarDataForMonth(momentDate).then(values => {
        this.data.raw = values
        this.data.date = momentDate
        this.data.zigguratData = this.processZigguratData(values[1])
        this.data.buyApiData = this.processBuyApiData(values[0])
        this.loading = false
      })
    },

    async getUtilizarHistogram(dataType, startDate, endDate, interval) {
      let res = await fetch(
        `/api/v0/utilizar/${dataType}?start=${startDate}&end=${endDate}&interval=${interval}&user_id=${
          this.user.zincUserId
        }`,
        {
          method: 'GET',
        }
      )

      let data = await res.json()
      return data
    },

    getBuyApiData(startDate, endDate, interval) {
      return this.getUtilizarHistogram('buyapi', ...arguments)
    },

    getZigguratData(startDate, endDate, interval) {
      return this.getUtilizarHistogram('ziggurat', ...arguments)
    },

    getUtilizarDataForMonth(momentDate) {
      let startOfMonth = momentDate.startOf('month').toISOString()
      let endOfMonth = momentDate.endOf('month').toISOString()
      let buyData = this.getBuyApiData(startOfMonth, endOfMonth, '1day')
      let zigguratData = this.getZigguratData(startOfMonth, endOfMonth, '1day')

      return Promise.all([buyData, zigguratData])
    },

    processBuyApiData(data) {
      // If utilizar data includes some buckets without a .b parameter, this will make the graph
      // and the billing preview wonky, so fixup the data so all buckets include .b=0 or .b=1
      data.forEach((bucket) => {
        if (!bucket.dimension.includes('.b')) {
          bucket.dimension += '.b=0';
        }
      })
      let processedData = { grandTotal: 0, grandTotalAutoOrders: 0, grandTotalBludgeonOrders: 0 }
      let groupedByDate = this.groupByProperty(data, 'period_start')
      if (Object.entries(groupedByDate).length == 0) {
        return {}
      }
      Object.entries(groupedByDate).forEach(([key, values]) => {
        values.forEach(val => {
          processedData.grandTotal += val.count;
          if (val.dimension.includes('.b=1')) {
            processedData.grandTotalBludgeonOrders += val.count;
          } else {
            processedData.grandTotalAutoOrders += val.count
          }
        })
      })

      Object.assign(processedData, groupedByDate)
      return processedData
    },

    processZigguratData(data) {
      // admittedly one of the least readable functions ever... dunno how to improve these loops short of querying the psql data directly
      let processedData = { grandTotals: {} }
      // first, group all data by date. then in each date bucket, sum all by dimension type, ignoring queries
      let groupedByDate = this.groupByProperty(data, 'period_start')
      if (Object.entries(groupedByDate).length == 0) {
        return {}
      }
      let propertyFlattenRegex = /^[^_]*_([^\.]*)/ // ignores retailer: (retailer)_[offers|search]
      Object.entries(groupedByDate).forEach(([key, value]) => {
        processedData[key] = {
          dimensions: groupedByDate[key],
          sums: this.sumByProperty(
            value,
            obj => propertyFlattenRegex.exec(obj.dimension)[1]
          ),
        }

        Object.entries(processedData[key].sums).forEach(([key, value]) => {
          if (!processedData.grandTotals[key]) {
            processedData.grandTotals[key] = 0
          }
          processedData.grandTotals[key] += value.count
        })
      })

      return processedData
    },

    sumByProperty(arrayObjects, propertyGetter) {
      return arrayObjects.reduce((acc, obj) => {
        let key =
          typeof propertyGetter == 'function'
            ? propertyGetter(obj)
            : obj[property]
        if (!acc[key]) {
          acc[key] = { count: 0 }
        }
        acc[key].count += obj.count
        return acc
      }, {})
    },

    groupByProperty(arrayObjects, property) {
      return arrayObjects.reduce((acc, obj) => {
        let key = obj[property]
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      }, {})
    },
  },
}
</script>
