var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "casesMessageModal",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "casesMessageModal",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-lg", attrs: { role: "document" } },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "h5",
                {
                  staticClass: "modal-title",
                  attrs: { id: "apiCredentialsModalLabel" }
                },
                [
                  _vm.action == "open"
                    ? _c("span", [_vm._v("Open New Case ")])
                    : _c("span", [_vm._v("Update Case")])
                ]
              ),
              _vm._v(" "),
              _vm._m(0)
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c(
                "form",
                {
                  attrs: { id: "casesMessageForm" },
                  on: { submit: _vm.checkCaseMessageForm }
                },
                [
                  this.errors.length
                    ? _c("div", { staticClass: "alert alert-danger" }, [
                        _vm._v("\n            Error:\n            "),
                        _c(
                          "ul",
                          _vm._l(_vm.errors, function(error) {
                            return _c("li", [_vm._v(_vm._s(error))])
                          })
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "cases-modal-message" } }, [
                      _vm._v("Message")
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.message,
                          expression: "message"
                        }
                      ],
                      staticClass: "form-control mb-4",
                      attrs: { id: "cases-modal-message" },
                      domProps: { value: _vm.message },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.message = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.action == "open"
                      ? _c("label", { attrs: { for: "cases-modal-reason" } }, [
                          _vm._v("Reason")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.action == "open"
                      ? _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.reason,
                                expression: "reason"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { id: "cases-modal-reason" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.reason = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              }
                            }
                          },
                          [
                            _c(
                              "option",
                              { attrs: { disabled: "", value: "" } },
                              [_vm._v("Select a reason")]
                            ),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "return.request_label" } },
                              [_vm._v("return.request_label")]
                            ),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "nondelivery.not_delivered" } },
                              [_vm._v("nondelivery.not_delivered")]
                            ),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "nondelivery.damaged" } },
                              [_vm._v("nondelivery.damaged")]
                            ),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "nondelivery.empty_box" } },
                              [_vm._v("nondelivery.empty_box")]
                            ),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "tracking.request_update" } },
                              [_vm._v("tracking.request_update")]
                            ),
                            _vm._v(" "),
                            _c(
                              "option",
                              {
                                attrs: { value: "cancel.forced_cancellation" }
                              },
                              [_vm._v("cancel.forced_cancellation")]
                            ),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "other" } }, [
                              _vm._v("other")
                            ])
                          ]
                        )
                      : _vm._e()
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _vm._m(1)
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn",
          attrs: { type: "button", "data-dismiss": "modal" }
        },
        [_vm._v("Close")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "submit", form: "casesMessageForm" }
        },
        [_vm._v("Send Message")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }