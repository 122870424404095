<template>
  <div class="ev-login col-sm-4 offset-sm-4">
    <spinner v-show="loggingIn" message="Logging in..."></spinner>

    <div class="alert alert-danger" v-if="error">
      <p>{{ error }}</p>
    </div>

    <h4>Redirecting to {{this.getLoginURL()}} ... </h4>

  </div>
</template>

<script>
import Spinner from '../common/Spinner.vue'

export default {
  name: 'login',
  components: { Spinner },
  getLoginURL() {
    return utils.LOGIN_URL
  },
  data() {
    return {
      auth: this.$store.state.auth,
      user: this.$store.state.user,
      loggingIn: false,
      error: '',
    }
  },
  created() {
    var url = '/api/v0/login'
    var next = this.$route.query.redirect
    if (next && next.indexOf('/login') === -1)
      url += '?next=' + encodeURIComponent(next)
    window.location.href = url
  },
  methods: {},
}
</script>

<style>
.ev-login {
  margin-top: 100px;
}
</style>
