var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "ev-appfooter" }, [
    _c("div", { staticClass: "container" }, [
      _c("span", { staticClass: "text-muted" }, [
        _vm._v("© Zinc Technologies, Inc. " + _vm._s(_vm.currentYear) + ".")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }