var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ev-coyote" }, [
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header" }, [
        _vm._v("\n            Coyote VNC\n        ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-block p-3" },
        [
          _c("spinner", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLoading,
                expression: "isLoading"
              }
            ]
          }),
          _vm._v(" "),
          _c("h4", { staticClass: "card-title" }, [
            _vm._v("Log in with Coyote")
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4 col-sm-6" }, [
              _c("div", { staticClass: "card p-3" }, [
                _c("div", { staticClass: "card-block" }, [
                  !_vm.coyoteLink
                    ? _c("form", [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { attrs: { for: "coyoteMarketplaceSelect" } },
                            [_vm._v("Marketplace")]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.coyoteMarketplaceId,
                                  expression: "coyoteMarketplaceId"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { id: "coyoteMarketplaceSelect" },
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.coyoteMarketplaceId = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            _vm._l(_vm.marketplaces, function(marketplace) {
                              return marketplace.hasAO
                                ? _c(
                                    "option",
                                    { domProps: { value: marketplace.id } },
                                    [
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(marketplace.humanReadable) +
                                          "\n                                        "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            })
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "coyoteLoginEmail" } }, [
                            _vm._v("Retailer account email")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.coyoteLoginEmail,
                                expression: "coyoteLoginEmail"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "coyoteLoginEmail",
                              placeholder: "myaccount@gmail.com"
                            },
                            domProps: { value: _vm.coyoteLoginEmail },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.coyoteLoginEmail = $event.target.value
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "dropdown" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary dropdown-toggle",
                              attrs: {
                                type: "button",
                                id: "coyoteDropdownMenuButton",
                                "data-toggle": "dropdown",
                                "aria-haspopup": "true",
                                "aria-expanded": "false"
                              }
                            },
                            [_vm._v("Enable Coyote")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-menu",
                              attrs: {
                                "aria-labelledby": "coyoteDropdownMenuButton"
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      if (
                                        !("button" in $event) &&
                                        _vm._k(
                                          $event.keyCode,
                                          "this",
                                          undefined,
                                          $event.key,
                                          undefined
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.preventDefault()
                                      _vm.getCoyoteLink()
                                    }
                                  }
                                },
                                [_vm._v("Enable Coyote")]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      if (
                                        !("button" in $event) &&
                                        _vm._k(
                                          $event.keyCode,
                                          "this",
                                          undefined,
                                          $event.key,
                                          undefined
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.preventDefault()
                                      _vm.getCoyoteLink(true)
                                    }
                                  }
                                },
                                [_vm._v("Enable Coyote (with Restart)")]
                              )
                            ]
                          )
                        ])
                      ])
                    : _c("form", [
                        _c("h5", [
                          _c("small", [
                            _vm._v(
                              "Coyote Link for " +
                                _vm._s(_vm.coyoteLoginEmail) +
                                ":"
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { href: _vm.coyoteLink, target: "_blank" }
                          },
                          [_vm._v("Release Coyote")]
                        )
                      ])
                ])
              ])
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6 col-sm-9" }, [
        _c("p", { staticClass: "card-text" }, [
          _vm._v(
            "The Coyote Interface is the suggested way to access the retailer accounts you use with the Zinc API.\n                        It uses the same cookies, IP addresses, and other technologies that we use to automatically place orders."
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "card-text" }, [
          _vm._v(
            "By logging in from the Coyote Interface, you keep your digital fingerprint the same for all log in attempts."
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }