var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Card", { attrs: { title: "Usage Overview" } }, [
    _c("div", { staticClass: "pull-in bg-light p-3" }, [
      _c("div", { staticClass: "d-flex justify-content-around" }, [
        _c("div", { staticClass: "text-center" }, [
          _c("p", { staticClass: "text-muted mb-0 mt-4" }, [
            _vm._v("Successful Orders")
          ]),
          _vm._v(" "),
          _c("h4", [
            _vm._v(_vm._s(this.get(_vm.data, "buyApiData.grandTotal", 0)))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-center" }, [
          _c("p", { staticClass: "text-muted mb-0 mt-4" }, [
            _vm._v("Offers Retrieved")
          ]),
          _vm._v(" "),
          _c("h4", [
            _vm._v(
              _vm._s(this.get(_vm.data, "zigguratData.grandTotals.offers", 0))
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-center" }, [
          _c("p", { staticClass: "text-muted mb-0 mt-4" }, [
            _vm._v("Details Retrieved")
          ]),
          _vm._v(" "),
          _c("h4", [
            _vm._v(
              _vm._s(this.get(_vm.data, "zigguratData.grandTotals.details", 0))
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-center" }, [
          _c("p", { staticClass: "text-muted mb-0 mt-4" }, [
            _vm._v("Searches Retrieved")
          ]),
          _vm._v(" "),
          _c("h4", [
            _vm._v(
              _vm._s(this.get(_vm.data, "zigguratData.grandTotals.search", 0))
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-center" }, [
          _c("p", { staticClass: "text-muted mb-0 mt-4" }, [
            _vm._v("Wishlists Retrieved")
          ]),
          _vm._v(" "),
          _c("h4", [
            _vm._v(
              _vm._s(
                this.get(_vm.data, "zigguratData.grandTotals.wishlists", 0)
              )
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }