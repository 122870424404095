module.exports = {
  LOGIN_URL: 'https://login.zinc.io',
  /**
   * Get the error from a response.
   *
   * @param {Response} response The Vue-resource Response that we will try to get errors from.
   */
  getError: function(response) {
    if (response.body) {
      return response.body['error_description']
        ? response.body.error_description
        : response.statusText
    } else {
      return "response is broken, couldn't find body"
    }
  },

  getDollarsFromCents: function(cents) {
    return +(cents / 100).toFixed(2)
  },

  getTrackingLinks: function(order) {
    var trackingObjects = _.get(order, 'tracking')
    var trackingLinksHTML = ''
    if (_.isArray(trackingObjects)) {
      for (let trackingObject of trackingObjects) {
        var trackingNumber = _.get(trackingObject, 'tracking_number', '') || ''
        var trackingLink = _.get(trackingObject, 'tracking_url', '') || ''
        if (trackingLink.length) {
          trackingLinksHTML += `<a href='${trackingLink}' target="_blank">${trackingNumber}</a><br>`
        }
      }
    }
    return trackingLinksHTML
  },

  apiGet(path) {
    return Vue.$http.get(`https://api.zinc.io/v1${path}`, {
      headers: {
        Authorization: 'Basic '+btoa(Vue.$store.getters.currentUser.zincClientToken+':'),
      }
    })
  },

  apiPost(path, json) {
    return Vue.$http.post(`https://api.zinc.io/v1${path}`,
      JSON.stringify(json||{}),
      {
        headers: {
          Authorization: 'Basic '+btoa(Vue.$store.getters.currentUser.zincClientToken+':'),
        },
        json:true,
    })
  },


}
