var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.tos_accept_time
    ? _c("div", [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            on: {
              click: function($event) {
                _vm.acceptTos()
              }
            }
          },
          [_vm._v("Accept ToS")]
        )
      ])
    : _vm.allowReject
    ? _c("div", [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-danger",
            on: {
              click: function($event) {
                _vm.rejectTos()
              }
            }
          },
          [_vm._v("Reject ToS")]
        )
      ])
    : _c("div", [_vm._t("default")], 2)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("You have not accepted the "),
      _c(
        "a",
        {
          attrs: {
            href:
              "https://support.priceyak.com/hc/en-us/articles/7726088076948-Terms-and-Services-for-ZMA-Platform-Buyers-"
          }
        },
        [_vm._v("ZMA\n            ToS")]
      ),
      _vm._v(". Please review the "),
      _c(
        "a",
        {
          attrs: {
            href:
              "https://support.priceyak.com/hc/en-us/articles/7726088076948-Terms-and-Services-for-ZMA-Platform-Buyers-"
          }
        },
        [_vm._v("ZMA\n            ToS")]
      ),
      _vm._v(
        " and click below to accept the ToS. You will not be able to place orders until you accept the ToS"
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Thank you for accepting the "),
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href:
              "https://support.priceyak.com/hc/en-us/articles/7726088076948-Terms-and-Services-for-ZMA-Platform-Buyers-"
          }
        },
        [_vm._v("ZMA\n            ToS")]
      ),
      _vm._v(
        ". You may withdraw consent if you like, but ZMA ordering and case support will stop\n        working."
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }