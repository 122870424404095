<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <h2 class="pt-3">Zinc API Billing</h2>
                <p>Zinc's API requires a prepaid balance to make API calls. Manage payments and view transaction history.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <PrepaidBalance></PrepaidBalance>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <PrepaidLedger></PrepaidLedger>
            </div>
        </div>
    </div>
</template>

<script>
import PrepaidBalance from '../billing/PrepaidBalance.vue';
import PrepaidLedger from '../billing/PrepaidLedger.vue'
export default {
    name: 'billing',
    components: {
        PrepaidLedger,
        PrepaidBalance,
    },
}
</script>

