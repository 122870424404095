<template>
  <Card title="Usage Overview">
    <div class="pull-in bg-light p-3">
      <div class="d-flex justify-content-around">
        <div class="text-center">
          <p class="text-muted mb-0 mt-4">Successful Orders</p>
          <h4>{{this.get(data, 'buyApiData.grandTotal', 0)}}</h4>
        </div>
        <div class="text-center">
          <p class="text-muted mb-0 mt-4">Offers Retrieved</p>
          <h4>{{this.get(data, 'zigguratData.grandTotals.offers', 0)}}</h4>
        </div>
        <div class="text-center">
          <p class="text-muted mb-0 mt-4">Details Retrieved</p>
          <h4>{{this.get(data, 'zigguratData.grandTotals.details', 0)}}</h4>
        </div>
        <div class="text-center">
          <p class="text-muted mb-0 mt-4">Searches Retrieved</p>
          <h4>{{this.get(data, 'zigguratData.grandTotals.search', 0)}}</h4>
        </div>
        <div class="text-center">
          <p class="text-muted mb-0 mt-4">Wishlists Retrieved</p>
          <h4>{{this.get(data, 'zigguratData.grandTotals.wishlists', 0)}}</h4>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import Spinner from '../common/Spinner.vue'
import Card from '../common/Card.vue'

export default {
  name: 'UsagePullIn',
  components: { Spinner, Card },
  props: {
    data: {
      type: Object,
      default: {
        zigguratData: { grandTotals: { offers: 0, details: 0, search: 0, wishlists: 0 } },
        buyApiData: { grandTotalAutoOrders: 0, grandTotalBludgeonOrders: 0 },
      },
      required: true,
    },
  },
  methods: {
    get: _.get,
  },
}
</script>

<style scoped lang="scss">
.d-flex {
  flex-direction: column;
  @media screen and (max-width: 991px) {
    flex-direction: row;
  }
}
</style>
