var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !this.isEmpty(this.data.buyApiData)
        ? _c(
            "Card",
            {
              staticClass: "ordersChartCard",
              attrs: { title: "Orders Per Day" }
            },
            [
              _c(
                "div",
                { staticClass: "ordersChartContainer chartContainer" },
                [_c("canvas", { attrs: { id: "ordersChart" } })]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !this.isEmpty(this.data.zigguratData)
        ? _c(
            "Card",
            {
              staticClass: "offersChartCard",
              attrs: { title: "Offers Retrieved Per Day" }
            },
            [
              _c(
                "div",
                { staticClass: "offersChartContainer chartContainer" },
                [_c("canvas", { attrs: { id: "offersChart" } })]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !this.isEmpty(this.data.zigguratData)
        ? _c(
            "Card",
            {
              staticClass: "detailsChartCard",
              attrs: { title: "Details Retrieved Per Day" }
            },
            [
              _c(
                "div",
                { staticClass: "detailsChartContainer chartContainer" },
                [_c("canvas", { attrs: { id: "detailsChart" } })]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !this.isEmpty(this.data.zigguratData)
        ? _c(
            "Card",
            {
              staticClass: "searchesChartCard",
              attrs: { title: "Searches Retrieved Per Day" }
            },
            [
              _c(
                "div",
                { staticClass: "searchesChartContainer chartContainer" },
                [_c("canvas", { attrs: { id: "searchesChart" } })]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !this.isEmpty(this.data.zigguratData)
        ? _c(
            "Card",
            {
              staticClass: "wishlistsChartCard",
              attrs: { title: "Wishlists Retrieved Per Day" }
            },
            [
              _c(
                "div",
                { staticClass: "wishlistsChartContainer chartContainer" },
                [_c("canvas", { attrs: { id: "wishlistsChart" } })]
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }