<template>
  <Card title="Billing Preview">
    <table class="table">
      <thead class="text-muted">
        <tr>
          <th></th>
          <th>Usage</th>
          <th>Price</th>
          <th>Subtotal</th>
        </tr>
      </thead>
      <tbody>
        <tr class="m-3" v-for="line in billingLines">
          <th>{{line.description}}</th>
          <th>{{line.usage}}</th>
          <th>{{getPrettyPrice('$', line.price)}} {{line.unit}}</th>
          <th>{{getPrettyPrice('$', line.subtotal)}}</th>
        </tr>
      </tbody>
    </table>

    <small class="text-muted">This is a preview and is not guaranteed to be accurate. Please refer to your invoices for accurate billing.</small>
  </Card>
</template>

<script>
import Card from '../common/Card.vue'
const baseFeePlan = {
  // account_id: 0,
}

function lineItem(description, usage, price, unit, subtotal) {
  this.description = description
  this.usage = usage
  this.price = price
  this.unit = unit
  this.subtotal = subtotal
}

export default {
  name: 'BillingPreview',
  components: { Card },
  data() {
    return {
      billingLines: [],
      user: this.$store.state.currentUser,
      total: 0,
      loginFeePlan: {
        order_fee: '1',
        bludgeon_order_fee: '1',
        require_minimum: false,
        min_fee: '0',
        ziggurat_fee_default: '0.01',
        notes: '',
      },
    }
  },
  props: {
    usage: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.getFeePlan(this.user.zincUserId).then(feePlan => {
      Object.assign(this.loginFeePlan, feePlan)
      this.billingLines = this.generateLineItems(feePlan)
    })
  },
  computed: {},
  methods: {
    async getFeePlan(userId) {
      let url = `/api/v0/feePlan/${userId}`
      let res = await fetch(url, { method: 'GET' })
      return await res.json()
    },

    generateLineItems(feePlan) {
      let safeFeePlan = this.loginFeePlan
      let lineItems = []
      let numAutoOrders = _.get(this.usage, 'buyApiData.grandTotalAutoOrders', 0)
      let numBludgeonOrders = _.get(this.usage, 'buyApiData.grandTotalBludgeonOrders', 0)
      let numOffers = _.get(this.usage, 'zigguratData.grandTotals.offers', 0)
      let numDetails = _.get(this.usage, 'zigguratData.grandTotals.details', 0)
      let numSearches = _.get(this.usage, 'zigguratData.grandTotals.search', 0)
      let numWishlists = _.get(this.usage, 'zigguratData.grandTotals.wishlists', 0)

      lineItems.push(
        new lineItem(
          'Auto Orders Created',
          numAutoOrders,
          safeFeePlan.order_fee,
          '/ order',
          numAutoOrders * safeFeePlan.order_fee
        )
      )

      lineItems.push(
        new lineItem(
          'Bolt Orders Created',
          numBludgeonOrders,
          safeFeePlan.bludgeon_order_fee,
          '/ order',
          numBludgeonOrders * safeFeePlan.bludgeon_order_fee
        )
      )

      let offersUnit = safeFeePlan.ziggurat_fee_offers ? '(flat)' : '/ call'
      let offersCost =
        safeFeePlan.ziggurat_fee_offers ||
        numOffers * (feePlan.ziggurat_fee_default || 0.01)
      let detailsUnit = safeFeePlan.ziggurat_fee_details ? '(flat)' : '/ call'
      let detailsCost =
        feePlan.ziggurat_fee_details ||
        numDetails * (safeFeePlan.ziggurat_fee_default || 0.01)
      let searchesUnit = safeFeePlan.ziggurat_fee_search ? '(flat)' : '/ call'
      let searchesCost =
        safeFeePlan.ziggurat_fee_search ||
        numSearches * (safeFeePlan.ziggurat_fee_default || 0.01)
      let wishlistsUnit = safeFeePlan.ziggurat_fee_wishlists ? '(flat)' : '/ call'
      let wishlistsCost =
        safeFeePlan.ziggurat_fee_wishlists ||
        numWishlists * (feePlan.ziggurat_fee_default || 0.01)

      lineItems.push(
        new lineItem(
          'Offers',
          numOffers,
          offersUnit == '(flat)'
            ? safeFeePlan.ziggurat_fee_offers
            : safeFeePlan.ziggurat_fee_default,
          offersUnit,
          offersCost
        )
      )

      lineItems.push(
        new lineItem(
          'Details',
          numDetails,
          detailsUnit == '(flat)'
            ? safeFeePlan.ziggurat_fee_details
            : safeFeePlan.ziggurat_fee_default,
          detailsUnit,
          detailsCost
        )
      )

      lineItems.push(
        new lineItem(
          'Searches',
          numSearches,
          searchesUnit == '(flat)'
            ? safeFeePlan.ziggurat_fee_search
            : safeFeePlan.ziggurat_fee_default,
          searchesUnit,
          searchesCost
        )
      )

      lineItems.push(
        new lineItem(
          'Wishlists',
          numWishlists,
          wishlistsUnit == '(flat)'
            ? safeFeePlan.ziggurat_fee_wishlists
            : safeFeePlan.ziggurat_fee_default,
          wishlistsUnit,
          wishlistsCost
        )
      )

      return lineItems
    },

    getPrettyPrice: function(
      currencySymbol = '$',
      num,
      divisor = 1,
      numDecimals = 2,
      targetLength = 10
    ) {
      let currencyString = `${(num / divisor).toFixed(numDecimals)}`
      return `${currencySymbol} ${currencyString.padStart(targetLength)}`
    },
  },
}
</script>


<style scoped lang="scss">
tbody > tr {
  font-weight: 400;
}
</style>
