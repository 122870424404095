import { render, staticRenderFns } from "./PrepaidLedger.vue?vue&type=template&id=22cdab1e&scoped=true&"
import script from "./PrepaidLedger.vue?vue&type=script&lang=js&"
export * from "./PrepaidLedger.vue?vue&type=script&lang=js&"
import style0 from "./PrepaidLedger.vue?vue&type=style&index=0&id=22cdab1e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22cdab1e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/zincadm/zinc-api-dash/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('22cdab1e', component.options)
    } else {
      api.reload('22cdab1e', component.options)
    }
    module.hot.accept("./PrepaidLedger.vue?vue&type=template&id=22cdab1e&scoped=true&", function () {
      api.rerender('22cdab1e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "client/components/billing/PrepaidLedger.vue"
export default component.exports