const markets = {
  DEFAULT: {
    id: 'default',
    humanReadable: '',
    icon: '',
    listingUrl() {
      return null
    },
    orderUrl() {
      return null
    },
    sellerSpecificProductUrl() {
      return null
    },
    offersUrl() {
      return null
    },
    searchUrl() {
      return null
    },
    currencySymbol: '$',
    productIdRegex: /./i,
    hasAO: false,
  },
  addax: {
    id: 'addax',
    humanReadable: 'ProBuyer',
    icon: '',
    listingUrl() {
      return null
    },
    orderUrl() {
      return null
    },
    sellerSpecificProductUrl() {
      return null
    },
    offersUrl() {
      return null
    },
    searchUrl() {
      return null
    },
    currencySymbol: '$',
    productIdRegex: /./i,
    hasAO: false,
  },
  amazon: {
    id: 'amazon',
    humanReadable: 'Amazon US',
    icon:
      "<img src='/icons/amazon-logo.svg'/><img class='zn-flag' src='/icons/us.png'/>",
    listingUrl(listingId) {
      return `https://amazon.com/dp/${listingId}?psc=1`
    },
    orderUrl(orderId, listingId = null) {
      return `https://www.amazon.com/gp/css/summary/edit.html/?orderID=${orderId}`
    },
    sellerSpecificProductUrl(retailerId, retailerName, productId) {
      return `http://www.amazon.com/gp/product/${productId}?m=${retailerId}&psc=1`
    },
    offersUrl(productId) {
      return `http://www.amazon.com/gp/offer-listing/${productId}?condition=new`
    },
    searchUrl(query) {
      return `http://www.amazon.com/s/?field-keywords=${encodeURIComponent(
        query
      )}`
    },
    currencySymbol: '$',
    currency: 'USD',
    productIdRegex: /B0\w{8}|\d{9}[0-9X]/i,
    hasAO: true,
  },
  amazonmfn: {
    id: 'amazonmfn',
    humanReadable: 'Amazon',
    icon: "<img src='/icons/amazon-logo.svg'/>",
    orderUrl(orderId, listingId = null) {
      return `https://sellercentral.amazon.com/hz/orders/details?_encoding=UTF8&orderId=${orderId}`
    },
    listingUrl(listingId) {
      return `https://sellercentral.amazon.com/hz/inventory?tbla_myitable=sort:%7B%22sortOrder%22%3A%22DESCENDING%22%2C%22sortedColumnId%22%3A%22date%22%7D;search:${listingId &&
        /^.+?_(.*)$/.exec(listingId)[1]};pagination:1;`
    },
    searchUrl(query) {
      return `https://sellercentral.amazon.com/hz/inventory?tbla_myitable=sort:%7B%22sortOrder%22%3A%22DESCENDING%22%2C%22sortedColumnId%22%3A%22date%22%7D;search:${encodeURIComponent(
        query
      )};pagination:1;`
    },
    currencySymbol: '$',
    formatProductId(productId) {
      return productId && /^.+?_(.*)$/.exec(productId)[1]
    },
    defaultSource: 'amazon',
    featureFlagged: true,
  },
  amazon_uk: {
    id: 'amazon_uk',
    humanReadable: 'Amazon UK',
    icon:
      "<img src='/icons/amazon-logo.svg'/><img class='zn-flag' src='/icons/gb.png'/>",
    listingUrl(listingId) {
      return `https://amazon.co.uk/dp/${listingId}`
    },
    orderUrl(orderId, listingId = null) {
      return `https://www.amazon.co.uk/gp/css/summary/edit.html/?orderID=${orderId}`
    },
    sellerSpecificProductUrl(retailerId, retailerName, productId) {
      return `http://www.amazon.co.uk/gp/product/${productId}?m=${retailerId}`
    },
    offersUrl(productId) {
      return `http://www.amazon.co.uk/gp/offer-listing/${productId}?condition=new`
    },
    searchUrl(query) {
      return `http://www.amazon.co.uk/s/?field-keywords=${encodeURIComponent(
        query
      )}`
    },
    currencySymbol: '£',
    currency: 'GBP',
    productIdRegex: /B0\w{8}|\d{9}[0-9X]/i,
    hasAO: true,
  },
  amazon_ca: {
    id: 'amazon_ca',
    humanReadable: 'Amazon CA',
    icon:
      "<img src='/icons/amazon-logo.svg'/><img class='zn-flag' src='/icons/ca.png'/>",
    listingUrl(listingId) {
      return `https://amazon.ca/dp/${listingId}`
    },
    orderUrl(orderId, listingId = null) {
      return `https://www.amazon.ca/gp/css/summary/edit.html/?orderID=${orderId}`
    },
    sellerSpecificProductUrl(retailerId, retailerName, productId) {
      return `http://www.amazon.ca/gp/product/${productId}?m=${retailerId}`
    },
    offersUrl(productId) {
      return `http://www.amazon.ca/gp/offer-listing/${productId}?condition=new`
    },
    searchUrl(query) {
      return `http://www.amazon.ca/s/?field-keywords=${encodeURIComponent(
        query
      )}`
    },
    currencySymbol: '$',
    productIdRegex: /B0\w{8}|\d{9}[0-9X]/i,
    hasAO: true,
  },
  amazon_de: {
    id: 'amazon_de',
    humanReadable: 'Amazon DE',
    icon:
      "<img src='/icons/amazon-logo.svg'/><img class='zn-flag' src='/icons/de.svg'/>",
    listingUrl(listingId) {
      return `https://amazon.de/dp/${listingId}`
    },
    orderUrl(orderId, listingId = null) {
      return `https://www.amazon.de/gp/css/summary/edit.html/?orderID=${orderId}`
    },
    sellerSpecificProductUrl(retailerId, retailerName, productId) {
      return `http://www.amazon.de/gp/product/${productId}?m=${retailerId}`
    },
    offersUrl(productId) {
      return `http://www.amazon.de/gp/offer-listing/${productId}?condition=new`
    },
    searchUrl(query) {
      return `http://www.amazon.de/s/?field-keywords=${encodeURIComponent(
        query
      )}`
    },
    currencySymbol: '€',
    currency: 'EUR',
    productIdRegex: /B0\w{8}|\d{9}[0-9X]/i,
    hasAO: true,
  },
  amazon_fr: {
    id: 'amazon_fr',
    humanReadable: 'Amazon FR',
    icon:
      "<img src='/icons/amazon-logo.svg'/><img class='zn-flag' src='/icons/fr.svg'/>",
    listingUrl(listingId) {
      return `https://amazon.fr/dp/${listingId}`
    },
    orderUrl(orderId, listingId = null) {
      return `https://www.amazon.fr/gp/css/summary/edit.html/?orderID=${orderId}`
    },
    sellerSpecificProductUrl(retailerId, retailerName, productId) {
      return `http://www.amazon.fr/gp/product/${productId}?m=${retailerId}`
    },
    offersUrl(productId) {
      return `http://www.amazon.fr/gp/offer-listing/${productId}?condition=new`
    },
    searchUrl(query) {
      return `http://www.amazon.fr/s/?field-keywords=${encodeURIComponent(
        query
      )}`
    },
    currencySymbol: '€',
    currency: 'EUR',
    productIdRegex: /B0\w{8}|\d{9}[0-9X]/i,
    hasAO: true,
    sourceFeatureFlagged: 'amazon_fr',
  },
  ebay: {
    id: 'ebay',
    humanReadable: 'eBay US',
    icon:
      "<img src='/icons/ebay-logo.svg'/><img class='zn-flag' src='/icons/us.png'/>",
    listingUrl(listingId) {
      return `https://www.ebay.com/itm/${listingId}`
    },
    orderUrl(orderId, listingId = null) {
      return `http://k2b-bulk.ebay.com/ws/eBayISAPI.dll?EditSalesRecord&transid=${orderId}&itemid=${listingId}`
    },
    searchUrl(query) {
      return `http://www.ebay.com/sch/i.html?_nkw=${encodeURIComponent(query)}`
    },
    currencySymbol: '$',
    productIdRegex: /\d{7,12}/i,
    defaultSource: 'amazon',
  },
  ebay_uk: {
    id: 'ebay_uk',
    humanReadable: 'eBay UK',
    icon:
      "<img src='/icons/ebay-logo.svg'/><img class='zn-flag' src='/icons/gb.png'/>",
    listingUrl(listingId) {
      return `https://www.ebay.co.uk/itm/${listingId}`
    },
    orderUrl(orderId, listingId = null) {
      return `http://k2b-bulk.ebay.co.uk/ws/eBayISAPI.dll?EditSalesRecord&transid=${orderId}&itemid=${listingId}`
    },
    searchUrl(query) {
      return `http://www.ebay.co.uk/sch/i.html?_nkw=${encodeURIComponent(
        query
      )}`
    },
    currencySymbol: '£',
    productIdRegex: /\d{7,12}/i,
    defaultSource: 'amazon_uk',
  },
  ebay_de: {
    id: 'ebay_de',
    humanReadable: 'eBay DE',
    icon:
      "<img src='/icons/ebay-logo.svg'/><img class='zn-flag' src='/icons/de.svg'/>",
    listingUrl(listingId) {
      return `https://www.ebay.de/itm/${listingId}`
    },
    orderUrl(orderId, listingId = null) {
      return `http://k2b-bulk.ebay.de/ws/eBayISAPI.dll?EditSalesRecord&transid=${orderId}&itemid=${listingId}`
    },
    searchUrl(query) {
      return `http://www.ebay.de/sch/i.html?_nkw=${encodeURIComponent(query)}`
    },
    currencySymbol: '€',
    productIdRegex: /\d{7,12}/i,
    defaultSource: 'amazon_uk',
  },
  ebay_fr: {
    id: 'ebay_fr',
    humanReadable: 'eBay FR',
    icon:
      "<img src='/icons/ebay-logo.svg'/><img class='zn-flag' src='/icons/fr.svg'/>",
    listingUrl(listingId) {
      return `https://www.ebay.fr/itm/${listingId}`
    },
    orderUrl(orderId, listingId = null) {
      return `http://k2b-bulk.ebay.fr/ws/eBayISAPI.dll?EditSalesRecord&transid=${orderId}&itemid=${listingId}`
    },
    searchUrl(query) {
      return `http://www.ebay.fr/sch/i.html?_nkw=${encodeURIComponent(query)}`
    },
    currencySymbol: '€',
    productIdRegex: /\d{7,12}/i,
    defaultSource: 'amazon_fr',
  },
  ebay_it: {
    id: 'ebay_it',
    humanReadable: 'eBay IT',
    icon:
      "<img src='/icons/ebay-logo.svg'/><img class='zn-flag' src='/icons/it.svg'/>",
    listingUrl(listingId) {
      return `https://www.ebay.it/itm/${listingId}`
    },
    orderUrl(orderId, listingId = null) {
      return `http://k2b-bulk.ebay.it/ws/eBayISAPI.dll?EditSalesRecord&transid=${orderId}&itemid=${listingId}`
    },
    searchUrl(query) {
      return `http://www.ebay.it/sch/i.html?_nkw=${encodeURIComponent(query)}`
    },
    currencySymbol: '€',
    productIdRegex: /\d{7,12}/i,
    defaultSource: 'amazon_de',
  },
  ebay_ca: {
    id: 'ebay_ca',
    humanReadable: 'eBay CA',
    icon:
      "<img src='/icons/ebay-logo.svg'/><img class='zn-flag' src='/icons/ca.png'/>",
    listingUrl(listingId) {
      return `https://www.ebay.ca/itm/${listingId}`
    },
    orderUrl(orderId, listingId = null) {
      return `http://k2b-bulk.ebay.ca/ws/eBayISAPI.dll?EditSalesRecord&transid=${orderId}&itemid=${listingId}`
    },
    searchUrl(query) {
      return `http://www.ebay.ca/sch/i.html?_nkw=${encodeURIComponent(query)}`
    },
    currencySymbol: '$',
    productIdRegex: /\d{7,12}/i,
    defaultSource: 'amazon_ca',
  },
  woocommerce: {
    id: 'woocommerce',
    humanReadable: 'WooCommerce',
    icon: "<img src='/icons/woocommerce-logo.svg'/>",
    currencySymbol: '$',
    listingUrl(listingId, currentStore) {
      return `/wp-admin/post.php?post=${listingId.split('_')[1]}&action=edit`
    },
    publicListingUrl(publicListingId, currentStore) {
      return `/product/${publicListingId}/`
    },
    orderUrl(orderId, listingId = null) {
      return `/wp-admin/post.php?post=${orderId.split('_')[1]}&action=edit`
    },
    searchUrl(query) {
      return `/?s=fff&post_type=${query}`
    },
    formatProductId(productId) {
      return productId && productId.split('_')[1]
    },
    defaultSource: 'amazon',
    featureFlagged: true,
  },
  shopify: {
    id: 'shopify',
    humanReadable: 'Shopify',
    icon: "<img src='/icons/shopify-logo.png'/>",
    currencySymbol: '$',
    listingUrl(listingId) {
      return `https://.myshopify.com/admin/products/${listingId}`
    },
    orderUrl(orderId, listingId = null) {
      return `https://.myshopify.com/admin/orders/${orderId}`
    },
    searchUrl(query) {
      return `http://.myshopify.com/search?q=${encodeURIComponent(query)}`
    },
    defaultSource: 'amazon',
    productIdRegex: /\d{3,20}/i,
    featureFlagged: true,
  },
  walmart: {
    id: 'walmart',
    humanReadable: 'Walmart',
    icon: "<img src='/icons/walmart-logo.png'/>",
    listingUrl(listingId) {
      return `http://www.walmart.com/ip/${listingId}`
    },
    orderUrl(orderId, listingId = null) {
      return `https://www.walmart.com/account/order/${orderId}`
    },
    sellerSpecificProductUrl(retailerId, retailerName, productId) {
      return `http://www.walmart.com/ip/${productId}`
    },
    offersUrl(productId) {
      return `http://www.walmart.com/product/${productId}/sellers`
    },
    searchUrl(query) {
      return `http://www.walmart.com/search?query=${encodeURIComponent(query)}`
    },
    currencySymbol: '$',
    currency: 'USD',
    productIdRegex: /\d{4,10}/i,
    hasAO: true,
  },
  aliexpress: {
    id: 'aliexpress',
    humanReadable: 'Aliexpress',
    icon: "<img src='/icons/aliexpress-logo.png'/>",
    listingUrl(listingId) {
      return `http://www.aliexpress.com/item/-/${listingId.replace(
        /-.*/,
        ''
      )}.html`
    },
    orderUrl(orderId, listingId = null) {
      return 'http://example.com/unknown/fixme/orderId'
    },
    sellerSpecificProductUrl(retailerId, retailerName, productId) {
      return `http://www.aliexpress.com/item/-/${listingId.replace(
        /-.*/,
        ''
      )}.html`
    },
    offersUrl(productId) {
      return `http://www.aliexpress.com/item/-/${listingId.replace(
        /-.*/,
        ''
      )}.html`
    },
    searchUrl(query) {
      return `http://www.aliexpress.com/wholesale?SearchText=${encodeURIComponent(
        query
      )}`
    },
    currencySymbol: '$',
    productIdRegex: /\d{5,}(-\d+)*/i,
    formatProductId(productId) {
      return productId && productId.split('-')[0]
    },
    hasAO: true,
  },
  homedepot: {
    id: 'homedepot',
    humanReadable: "Home Depot",
    icon: "<img class='zn-market-logo' src='/icons/homedepot-logo.svg'/>",
    listingUrl(listingId) { return `https://www.homedepot.com/p/${listingId}` },
    sellerSpecificProductUrl(retailerId, retailerName, productId) { return `https://www.homedepot.com/p/${productId}` },
    offersUrl(listingId) { return `https://www.homedepot.com/p/${listingId}` },
    searchUrl(query) { return `https://www.homedepot.com/s/${encodeURIComponent(query)}` },
    currencySymbol: '$',
    productIdRegex: /\d{4,}(-\d+)?/i,
    formatProductId (productId) { return productId && productId.split('-')[0] },
    hasAO: false,
  },
  bhphotovideo: {
    id: 'bhphotovideo',
    humanReadable: 'B&H Photo Video',
    icon: "<img src='/icons/bhphotovideo-logo.svg'/>",
    listingUrl(listingId) {
      return `https://www.bhphotovideo.com/c/product/${listingId}/-.html`
    },
    orderUrl(orderId, listingId = null) {
      return 'http://example.com/unknown/fixme/orderId'
    }, // FIXME
    sellerSpecificProductUrl(retailerId, retailerName, productId) {
      return 'http://example.com/unknown/fixme/sellerSpecificProductUrl'
    }, // FIXME
    offersUrl(listingId) {
      return `https://www.bhphotovideo.com/c/product/${listingId}/-.html`
    },
    searchUrl(query) {
      return `https://www.bhphotovideo.com/c/search?Ntt=${encodeURIComponent(
        query
      )}`
    },
    currencySymbol: '$',
    productIdRegex: /[0-9]*-REG/,
    formatProductId(productId) {
      return productId
    },
    hasAO: false,
    disableListing: true,
  },
  overstock: {
    id: 'overstock',
    humanReadable: 'Overstock.com',
    icon: "<img src='/icons/overstock-logo.svg'/>",
    listingUrl(listingId) {
      return `https://www.overstock.com/-/-/${listingId.replace(
        /-.*/,
        ''
      )}/product.html`
    },
    offersUrl(listingId) {
      return `https://www.overstock.com/-/-/${listingId.replace(
        /-.*/,
        ''
      )}/product.html`
    },
    searchUrl(query) {
      return `https://www.overstock.com/search?keywords=${encodeURIComponent(
        query
      )}`
    },
    currencySymbol: '$',
    productIdRegex: /[0-9]{3,}(-[0-9]+)?/,
    formatProductId(productId) {
      return productId
    },
    hasAO: false,
    disableListing: true,
  },
  bestbuy: {
    id: 'bestbuy',
    humanReadable: 'Best Buy',
    icon: "<img src='/icons/bestbuy-logo.svg'/>",
    listingUrl(listingId) {
      return `http://www.bestbuy.com/site/-/${listingId}.p`
    },
    offersUrl(listingId) {
      return `http://www.bestbuy.com/site/-/${listingId}.p`
    },
    searchUrl(query) {
      return `http://www.bestbuy.com/site/searchpage.jsp?st=${encodeURIComponent(
        query
      )}&id=pcat17071`
    },
    currencySymbol: '$',
    productIdRegex: /[1-9][0-9]{6,}/,
    formatProductId(productId) {
      return productId
    },
    hasAO: false,
    disableListing: true,
  },
  sears: {
    id: 'sears',
    humanReadable: 'Sears',
    icon: "<img style='max-width:35px' src='/icons/sears-logo.svg'/>",
    listingUrl(listingId) {
      return `http://www.sears.com/-/p-${listingId.replace(/-.*/, '')}`
    },
    offersUrl(listingId) {
      return `http://www.sears.com/-/p-${listingId.replace(/-.*/, '')}`
    },
    searchUrl(query) {
      return `http://www.sears.com/search=${encodeURIComponent(query)}`
    },
    currencySymbol: '$',
    productIdRegex: /[0-9A-Za-z]{3,}P(-[0-9A-Za-z]+)?/,
    formatProductId(productId) {
      return productId
    },
    hasAO: false,
    disableListing: true,
  },
  costco: {
    id: 'costco',
    humanReadable: 'Costco',
    icon: "<img src='/icons/costco-logo.png'/>",
    listingUrl(listingId) {
      return `https://www.costco.com/-.product.${listingId.replace(
        /-.*/,
        ''
      )}.html`
    },
    sellerSpecificProductUrl(retailerId, retailerName, productId) {
      return `https://www.costco.com/-.product.${productId.replace(
        /-.*/,
        ''
      )}.html`
    },
    offersUrl(listingId) {
      return `https://www.costco.com/-.product.${listingId.replace(
        /-.*/,
        ''
      )}.html`
    },
    searchUrl(query) {
      return `https://www.costco.com/CatalogSearch?keyword=${encodeURIComponent(
        query
      )}&pageSize=96`
    },
    orderUrl(orderId, listingId = null) {
      return `https://www.costco.com/OrderStatusDetailsView?orderId=${orderId}`
    },
    currencySymbol: '$',
    currency: 'USD',
    productIdRegex: /\d{4,}(-\d+)?/i,
    formatProductId(productId) {
      return productId && productId.split('-')[0]
    },
    hasAO: true,
  },
  samsclub: {
    id: 'samsclub',
    humanReadable: 'SamsClub',
    icon: "<img src='/icons/samsclub-logo.png'/>",
    listingUrl(listingId) {
      return `https://www.samsclub.com/sams/-/prod${listingId}.ip`
    },
    sellerSpecificProductUrl(retailerId, retailerName, productId) {
      return `https://www.samsclub.com/sams/-/prod${listingId}.ip`
    },
    offersUrl(listingId) {
      return `https://www.samsclub.com/sams/-/prod${listingId}.ip`
    },
    searchUrl(query) {
      return `https://www.samsclub.com/sams/search/searchResults.jsp?searchTerm=${encodeURIComponent(
        query
      )}`
    },
    orderUrl(orderId, listingId = null) {
      return 'https://www.samsclub.com/sams/shoppingtools/orderhistory/orderHistory.jsp'
    },
    currencySymbol: '$',
    currency: 'USD',
    productIdRegex: /\d{4,}(-\d+)?/i,
    formatProductId(productId) {
      return productId && productId.split('-')[0]
    },
    hasAO: true,
    disableListing: true,
  },
  bedbathandbeyond: {
    id: 'bedbathandbeyond',
    humanReadable: 'Bed Bath & Beyond',
    icon: "<img src='/icons/bedbathandbeyond-logo.png'/>",
    listingUrl(listingId) {
      return `https://www.bedbathandbeyond.com/store/product/-/${listingId}`
    },
    sellerSpecificProductUrl(retailerId, retailerName, productId) {
      return `https://www.bedbathandbeyond.com/store/product/-/${productId}`
    },
    offersUrl(productId) {
      return `https://www.bedbathandbeyond.com/store/product/-/${productId}`
    },
    searchUrl(query) {
      return `https://www.bedbathandbeyond.com/store/s/${encodeURIComponent(
        query
      )}`
    },
    orderUrl(orderId, listingId = null) {
      return `https://www.bedbathandbeyond.com/TODO`
    },
    currencySymbol: '$',
    currency: 'USD',
    productIdRegex: /\d{4,15}/i,
    hasAO: false,
  },
  bjs: {
    id: 'bjs',
    humanReadable: 'BJs',
    icon: "<img src='/icons/bjs-logo.svg'/>",
    listingUrl(listingId) {
      return `http://www.bjs.com/X.product.${listingId}`
    },
    sellerSpecificProductUrl(retailerId, retailerName, productId) {
      return `http://www.bjs.com/X.product.${listingId}`
    },
    offersUrl(listingId) {
      return `http://www.bjs.com/X.product.${listingId}`
    },
    searchUrl(query) {
      return `http://www.bjs.com/search?Ntt=${encodeURIComponent(query)}&N=`
    },
    orderUrl(orderId, listingId = null) {
      return `http://www.bjs.com/orders/${orderId}`
    },
    currencySymbol: '$',
    currency: 'USD',
    productIdRegex: /\d{4,}(-\d+)?/i,
    formatProductId(productId) {
      return productId && productId.split('-')[0]
    },
    hasAO: true,
    disableListing: true,
  },
  boohoo: {
    id: 'boohoo',
    humanReadable: 'boohoo',
    icon: "<img src='/icons/boohoo-logo.png'/>",
    listingUrl(listingId) {
      return `https://www.boohoo.com/X/${listingId}`
    },
    sellerSpecificProductUrl(retailerId, retailerName, productId) {
      return `https://www.boohoo.com/X/${listingId}`
    },
    offersUrl(listingId) {
      return `https://www.boohoo.com/X/${listingId}`
    },
    searchUrl(query) {
      return `https://www.boohoo.com/search?q=${encodeURIComponent(query)}`
    },
    orderUrl(orderId, listingId = null) {
      return `https://www.boohoo.com/orders/${orderId}`  // XXX this is a guess, probably wrong
    },
    currencySymbol: '£',
    currency: 'GBP',
    productIdRegex: /[\w\d]{8}(#.*)?/i,
    formatProductId(productId) {
      return productId
    },
    hasAO: true,
    disableListing: true,
  },
}
function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null
    ? transform(value)
    : undefined
}

module.exports = markets
