<template>
<div class="ev-coyote">
    <div class="card">
        <div class="card-header">
            Coyote VNC
        </div>
        <div class="card-block p-3">
            <spinner v-show="isLoading"></spinner>
            <h4 class="card-title">Log in with Coyote</h4>
            <div class="row">
                <div class="col-6 col-sm-9">
                    <p class="card-text">The Coyote Interface is the suggested way to access the retailer accounts you use with the Zinc API.
                        It uses the same cookies, IP addresses, and other technologies that we use to automatically place orders.</p>
                    <p class="card-text">By logging in from the Coyote Interface, you keep your digital fingerprint the same for all log in attempts.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-4 col-sm-6">
                    <div class="card p-3">
                        <div class="card-block">
                            <form v-if="!coyoteLink">
                                <div class="form-group">
                                    <label for="coyoteMarketplaceSelect">Marketplace</label>
                                    <select v-model="coyoteMarketplaceId" class="form-control" id="coyoteMarketplaceSelect">
                                        <option
                                            v-for="marketplace in marketplaces"
                                            v-if="marketplace.hasAO"
                                            :value="marketplace.id">
                                            {{marketplace.humanReadable}}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="coyoteLoginEmail">Retailer account email</label>
                                    <input v-model="coyoteLoginEmail" type="text" class="form-control" id="coyoteLoginEmail" placeholder="myaccount@gmail.com">
                                </div>
                                <div class="dropdown">
                                    <button class="btn btn-primary dropdown-toggle" type="button" id="coyoteDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Enable Coyote</button>
                                    <div class="dropdown-menu" aria-labelledby="coyoteDropdownMenuButton">
                                        <a class="dropdown-item" href="#" @click.this.prevent="getCoyoteLink()">Enable Coyote</a>
                                        <a class="dropdown-item" href="#" @click.this.prevent="getCoyoteLink(true)">Enable Coyote (with Restart)</a>
                                    </div>
                                </div>
                            </form>
                            <form v-else>
                                <h5><small>Coyote Link for {{coyoteLoginEmail}}:</small></h5>
                                <a class="btn btn-primary" :href="coyoteLink" target="_blank">Release Coyote</a>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Spinner from '../common/Spinner.vue'
const addaxCurrencies = require('../common/addaxCurrencies.data.js')
const marketplaces = require('../common/marketplaces.data.js')
const tz = moment.tz.guess()

export default {
  name: 'coyote',
  components: { Spinner },
  created() {
    // this.$auth.signup()
  },
  data() {
    return {
      isLoading: false,
      auth: this.$store.state.auth,
      user: this.$store.state.currentUser,
      marketplaces: marketplaces,
      coyoteMarketplaceId: 'amazon',
      coyoteLoginEmail: '',
      coyoteLink: '',
    }
  },
  methods: {
    get: _.get,
    getCoyoteLink(kill = false) {
      if (!this.coyoteLoginEmail) {
        alert(
          'Please enter a Fulfillment Account email address to enable Coyote with!'
        )
      } else {
        var headers = {
          Authorization: 'Basic ' + btoa(this.user.zincClientToken + ':'),
        }
        this.$http
          .post(
            `https://api.zinc.io/v1/coyote`,
            {
              retailer: this.coyoteMarketplaceId,
              retailer_credentials: {
                email: this.coyoteLoginEmail && this.coyoteLoginEmail.trim().toLowerCase(),
              },
              kill: kill,
            },
            { headers: headers }
          )
          .then(response => {
            let coyoteLink = _.get(response, 'body.coyote_url', false)
            if (coyoteLink) {
              this.coyoteLink = coyoteLink
            }
            // alert("Successful coyote response:\n" + JSON.stringify(response.body, null, 3))
          })
          .catch(response => {
            //this.$auth.logout()
            console.log(
              'Coyote access failed',
              JSON.stringify(response, null, 4)
            )
            alert(
              'You do not have permission to use Coyote. Please contact sales@zinc.io for more info.'
            )
          })
      }
    },
  },
}
</script>

<style>

</style>
