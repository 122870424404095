import Vue from 'vue'
import router from './router'
import store from './store'

const LOGOUT_URL = '/api/v0/logout'
const GET_USER_URL = '/api/v0/getUser'
const SIGNUP_URL = '/api/v0/signup'

/**
 * Auth Plugin
 *
 * (see https://vuejs.org/v2/guide/plugins.html for more info on Vue.js plugins)
 *
 * Handles login and token authentication using OAuth2.
 */
export default {
  /**
   * Install the Auth class.
   *
   * Creates a Vue-resource http interceptor to handle automatically adding auth headers
   * and refreshing tokens. Then attaches this object to the global Vue (as Vue.auth).
   *
   * @param {Object} Vue The global Vue.
   * @param {Object} options Any options we want to have in our plugin.
   * @return {void}
   */
  install(Vue, options) {
    Vue.prototype.$auth = Vue.auth = this
  },

  getLoginUser(redirect) {
    return Vue.http
      .get(GET_USER_URL)
      .then(response => {
        this._storeToken(response)
        const loginUser = store.state.loginUser
        if (window.location.host == 'dash.zinc.io') {
          heap.identify(loginUser.email)
        }
        return response
      })
      .catch(errorResponse => {
        router.push({ name: 'login', query: { redirect: redirect } })
        return errorResponse
      })
  },

  /**
   * Store tokens
   *
   * Update the Vuex store with the access/refresh tokens received from the response from
   * the Oauth2 server.
   *
   * @private
   * @param {Response} response Vue-resource Response instance from an OAuth2 server.
   *      that contains our tokens.
   * @return {void}
   */
  _storeToken(response) {
    const auth = store.state.auth
    const loginUser = store.state.loginUser
    const currentUser = store.state.currentUser

    auth.isLoggedIn = true
    auth.accessToken = response.body.accessToken
    loginUser.name = response.body.loginResponse.name
    loginUser.email = response.body.loginResponse.email
    loginUser.zincClientToken = response.body.loginResponse.zinc_client_token
    loginUser.zincUserId = response.body.loginResponse.sub
    loginUser.isAdmin = _.includes(
      response.body.loginResponse.roles,
      'api_admin'
    )
    loginUser.roles = response.body.loginResponse.roles

    store.commit('UPDATE_AUTH', auth)
    this.setLoginUser(loginUser)
    _.merge(currentUser, loginUser)
    this.setCurrentUser(currentUser) // hack to avoid missing admin headers bug
  },

  setLoginUser(loginUser) {
    store.commit('UPDATE_LOGIN_USER', loginUser)
  },

  getCurrentUser(currentZincUserId, redirect) {
    return Vue.http
      .get(`${GET_USER_URL}/${currentZincUserId}`)
      .then(response => {
        const currentUser = store.state.currentUser
        currentUser.name = response.body.name
        currentUser.email = response.body.email
        currentUser.zincClientToken = response.body.zinc_client_token
        currentUser.zincUserId = response.body.sub
        currentUser.isAdmin = _.includes(response.body.roles, 'api_admin')
        currentUser.roles = response.body.roles
        this.setCurrentUser(currentUser)
        return response
      })
      .catch(errorResponse => {
        router.push({ name: 'login', query: { redirect: redirect } })
        return errorResponse
      })
  },

  setCurrentUser(currentUser) {
    store.commit('UPDATE_CURRENT_USER', currentUser)
  },

  /**
   * Logout
   *
   * Clear all data in our Vuex store (which resets logged-in status) and redirect back
   * to login form.
   *
   * @return {void}
   */
  logout() {
    store.commit('CLEAR_ALL_DATA')
    window.location.href = LOGOUT_URL
  },

  getQueryStringValue(key) {
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
  },

  signup() {
    store.commit('CLEAR_ALL_DATA');
    var surl = SIGNUP_URL;
    if (this.getQueryStringValue("ref"))
      surl += "?ref=" + encodeURIComponent(this.getQueryStringValue("ref"));
    window.location.href = surl;
  },
}
