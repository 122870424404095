<template>
  <!-- cases message modal -->
  <div class="modal fade" id="casesMessageModal" tabindex="-1" role="dialog" aria-labelledby="casesMessageModal" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="apiCredentialsModalLabel">
            <span v-if="action == 'open'">Open New Case </span>
            <span v-else>Update Case</span></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form id="casesMessageForm" @submit="checkCaseMessageForm">
            <div v-if="this.errors.length" class="alert alert-danger">
              Error:
              <ul>
                <li v-for="error in errors">{{ error }}</li>
              </ul>
            </div>
            <div class="form-group">
              <label for="cases-modal-message">Message</label>
              <textarea class="form-control mb-4" id="cases-modal-message" v-model="message"></textarea>
              <label for="cases-modal-reason" v-if="action == 'open'">Reason</label>
              <select class="form-control" id="cases-modal-reason" v-model="reason" v-if="action == 'open'">
                <option disabled value="">Select a reason</option>
                <option value="return.request_label">return.request_label</option>
                <option value="nondelivery.not_delivered">nondelivery.not_delivered</option>
                <option value="nondelivery.damaged">nondelivery.damaged</option>
                <option value="nondelivery.empty_box">nondelivery.empty_box</option>
                <option value="tracking.request_update">tracking.request_update</option>
                <option value="cancel.forced_cancellation">cancel.forced_cancellation</option>
                <option value="other">other</option>
              </select>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn" data-dismiss="modal">Close</button>
          <button type="submit" form="casesMessageForm" class="btn btn-primary" >Send Message</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
export default {
  name: 'CasesModal',
  props: {
    action: {
      // one of: ['open', 'update']
      type: String,
      required: true,
    },
    clientToken: {
      type: String,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
    setCases: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      errors: [],
      message: null,
      reason: null,
    }
  },
  created() {},
  methods: {
    checkCaseMessageForm: function(e) {
      e.preventDefault()
      this.errors = []
      if (!this.message) {
        this.errors.push('Message required.')
        return
      }
      if (this.action === 'open') {
        if (!this.reason) {
          this.errors.push('A reason is required.')
          return
        } else {
          this.openCase().then(json => {
            if (json) {
              this.setCases(json)
            }
          })
        }
      }
      if (this.action === 'update') {
        this.updateCase().then(json => {
          if (json) {
            this.setCases(json)
          }
        })
      }
    },
    openCase: debounce(async function() {
      const data = {
        reason: this.reason,
        message: this.message,
      }
      let res = await fetch(
        `https://api.zinc.io/v1/orders/${this.orderId}/case`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${btoa(this.clientToken + ':')}`,
          },
          body: JSON.stringify(data),
        }
      )
      let json = await res.json()
      if (!res.ok || [404, 403].includes(res.status)) {
        this.errors.push(
          'Failed updating case. Please try again. Error code: ' + json.error
        )
      } else {
        $('#casesMessageModal').modal('hide')
        return json
      }
    }, 2500, {leading: true}),
    updateCase: debounce(async function(message) {
      // duplicated code cuz lazy
      const data = {
        message: this.message,
      }
      let res = await fetch(
        `https://api.zinc.io/v1/orders/${this.orderId}/case`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${btoa(this.clientToken + ':')}`,
          },
          body: JSON.stringify(data),
        }
      )
      let json = await res.json()
      if (!res.ok || [404, 403].includes(res.status)) {
        this.errors.push(
          'Failed updating case. Please try again. Error code:' + json.error
        )
      } else {
        $('#casesMessageModal').modal('hide')
        return json
      }
    }, 2500, {leading: true}),
  },
}
</script>

<style lang="scss" scoped>

</style>
