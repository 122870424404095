<template>
<div class="ev-view-order mt-2">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link :to="{ name: 'orders', params: { zincUserId: user.zincUserId } }">Orders </router-link></li>
      <li class="breadcrumb-item active" aria-current="page">{{ zincOrderId }}</li>
    </ol>
  </nav>
  <div class="row mt-3">
      <div class="col-12">
          <div v-if="order._type == 'order_response'" class="alert alert-success col-sm">
              This order was successfully submitted to marketplace <span v-html="getPrettyMarketName()"></span>
          </div>
          <div v-if="order._type == 'error' && order.code != 'request_processing'" class="alert alert-danger col-sm">
              This order encountered an error on marketplace <span v-html="getPrettyMarketName()"></span><br>
              <strong>{{ order.code }} - </strong>{{ (order.message ? order.message : (order.data ? order.data.message || order.data.msg || order.data.details : "")) || "" }}
          </div>
          <div v-if="order._type == 'error' && order.code == 'request_processing'" class="alert alert-warning col-sm">
              This order request is still preprocessing, not yet placed on <span v-html="getPrettyMarketName()"></span><br>
              <strong>{{ order.code }} - </strong>{{ order.message }}
          </div>
      </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <Card title="Zinc API Details">
        <div>
          <div class="mt-2 mb-1"><small class="text-muted">Zinc Order ID</small></div>
          <span style="font-family: monospace; font-size: 1.1em;" class="text-monospace">{{ zincOrderId }}</span>
          <a :href="`https://${user.zincClientToken}:@api.zinc.io/v1/orders/${order.request_id}`" target="_blank"><span class="badge badge-secondary">JSON</span></a>
        </div>
        <div>
          <div class="mt-2 mb-1"><small class="text-muted">Created At</small></div>
          <span class="">{{ getPrettyDate(order._created_at) }}</span>
        </div>
        <div v-if="get(order, 'delivery_dates[0].date')">
          <div class="mt-2 mb-1"><small class="text-muted">Estimated Delivery Date</small></div>
          <span class="">{{ getPrettyDate(order.delivery_dates[0].date) }}</span>
        </div>
        <div>
          <div class="mt-2 mb-1"><small class="text-muted">Zinc User</small></div>
          <span class="">{{ user.name }} <span class="text-muted">{{user.email}}</span></span>
        </div>
        <div v-if="get(order, 'request.retailer_credentials.email')">
          <div class="mt-2 mb-1"><small class="text-muted">Retailer Account Email</small></div>
          <span>{{get(order, 'request.retailer_credentials.email')}}</span>
        </div>
        <div>
          <div class="mt-2 mb-1"><small class="text-muted">Payment Method</small></div>
          <span>
            <span v-if="get(order, 'request.payment_method.use_gift') ">Gift Card</span>
            <span v-else-if="get(order, 'request.addax', false)">Zinc Managed Account</span>
            <span v-else>Credit Card <span class="text-muted pl-2">{{get(order, 'request.payment_method.name_on_card')}} EXP:
              {{get(order, 'request.payment_method.expiration_month')}} / {{get(order, 'request.payment_method.expiration_year')}}</span>
            </span>
          </span>

        </div>
        <div class="d-flex flex-row justify-content-between">
          <div>
            <div class="mt-2 mb-1"><small class="text-muted">Marketplace</small></div>
            <span>
              <span v-html="getPrettyMarketName()"></span>
            </span>
          </div>
          <div>
            <div class="mt-2 mb-1"><small class="text-muted">Has Returns?</small></div>
            <span>
              <span v-if="has(order, 'return_request_ids.0', false)">✅</span>
              <span v-else>❌</span>
            </span>
          </div>
          <div>
            <div class="mt-2 mb-1"><small class="text-muted">Has Cases?</small></div>
            <span>
              <span style="text-transform: capitalize" v-if="get(cases, 'state', null) != null">✅
                {{get(cases, 'state', '')}}</span>
              <span v-else>❌</span>
            </span>
          </div>
          <div><!-- placeholder --></div>
        </div>
      </Card>
      <Card title="Actions">
        <span v-if="get(order, 'request.addax')">
          <button v-if="get(cases, 'state', null) == null || cases.state != 'open'" type="button" class="btn btn-primary" data-toggle="modal" data-target="#casesMessageModal" data-action="open">Open Case</button>
          <button v-else type="button" class="btn btn-primary" data-toggle="modal" data-target="#casesMessageModal" data-action="update">Update Case</button>
        </span>
        <span v-if="isCancelable()">
          <button type="button" class="btn btn-danger" @click="cancelOrder(order.request_id, $event)">Cancel</button>
        </span>
        <span v-if="isAbortable()">
          <button type="button" class="btn btn-danger"  data-toggle="modal" data-target="#abortConfirmModal">Attempt Abort</button>
        </span>
        <span v-if="isReturnable()">
          <button type="button" class="btn btn-warning"  @click="createReturn(order.request_id, $event)">Create Return</button>
        </span>
        <span class="zn-admin" v-if="loginUser.isAdmin">
          <br>
          <a :href="`https://dash.zinc.io/${loginUser.zincUserId}/admin?order_id=${zincOrderId}`">View Order as Admin</a>
        </span>
      </Card>
      <Card v-if="(order._type == 'order_response' && order.price_components) || (order._type == 'error' && order.code == 'max_price_exceeded')">
        <span v-if="order._type == 'order_response' && order.price_components">
            <h6 class="text-muted font-weight-normal mb-3">Cost Breakdown</h6>
            <table class="table table-sm table-borderless mb-0">
                <tbody>
                    <tr><td>Subtotal</td><td class="text-right">{{ getPrettyPrice( get(order, 'price_components.subtotal', 0 )) }}</td></tr>
                    <tr><td>Shipping</td><td class="text-right">{{ getPrettyPrice( get(order, 'price_components.shipping', 0 )) }}</td></tr>
                    <tr><td>Tax</td><td class="text-right">{{ getPrettyPrice( get(order, 'price_components.tax', 0 )) }}</td></tr>
                    <tr class="font-weight-bold"><td>Total</td><td class="text-right">{{ getPrettyPrice( get(order, 'price_components.total', 0)) }}</td></tr>
                </tbody>
            </table>
        </span>
        <div v-if="(order._type == 'order_response' && order.price_components) || (order._type == 'error' && order.code == 'max_price_exceeded')" class="col-6 col-lg-5 col-xl-4">
          <span v-if="order._type == 'error' && order.code == 'max_price_exceeded'">
              <table class="table table-sm table-borderless">
                  <tbody>
                      <tr><td>Max Price Provided</td><td class="text-right">{{ getPrettyPrice( get(order, 'data.data.price_components.max_price', 0)) }}</td></tr>
                      <tr><td>Total</td><td class="text-right">{{ getPrettyPrice( get(order, 'data.data.price_components.total', 0)) }}</td></tr>
                      <tr><td>Subtotal</td><td class="text-right">{{ getPrettyPrice( get(order, 'data.data.price_components.subtotal', 0 )) }}</td></tr>
                      <tr><td>Shipping</td><td class="text-right">{{ getPrettyPrice( get(order, 'data.data.price_components.shipping', 0 )) }}</td></tr>
                      <tr><td>Shipping</td><td class="text-right">{{ getPrettyPrice( get(order, 'data.data.price_components.tax', 0 )) }}</td></tr>
                  </tbody>
              </table>
          </span>
        </div>
      </Card>
      <Card title="Cancellation Requests" v-if="order && get(order, 'cancellation_request_ids', '')">
        <div v-for="cancellation_request_id in order.cancellation_request_ids">
            <a :href="`https://${user.zincClientToken}:@api.zinc.io/v1/cancellations/${cancellation_request_id}`" target="_blank">{{cancellation_request_id}}</a>
        </div>
      </Card>
    </div> <!-- end col 1 -->
    <div class="col-md-8">
      <Card title="Order Contents">
        <div class="items-table">
          <table class="table mb-0" v-if="has(order,'request.products')">
            <thead class="text-muted">
              <tr>
                <th>Item</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order_detail in order.request.products">
                <td>
                  <a :href="getProductUrl(order_detail.product_id)" class="zn-listing-link" rel="noopener noreferrer" target="_blank">{{ order_detail.product_id }} <span v-html="marketInfo.icon"></span></a></td>
                <td>{{ order_detail.quantity }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>
      <Card title="Managed Account Case" v-if="cases && get(cases, 'state')">
        <a :href="`https://support.priceyak.com/agent/tickets/${get(cases, 'zendesk_ticket_id')}`" target="_blank"><button v-if="loginUser.isAdmin" class="btn btn-block btn-primary my-2 zn-admin">View in Zendesk: #{{get(cases, 'zendesk_ticket_id')}}</button></a>
        <table class='table table-condensed'>
          <thead><tr>
            <th style="width:100px;">Author</th>
            <th style="width:100px;">Time</th>
            <th style="width:200px;">Type</th>
            <th>Message</th>
            <th style="width:150px;">Refund Amount</th>
          </tr></thead>
          <tbody>
            <tr v-for="message in cases.messages">
              <td>{{message.author}}</td>
              <td>{{getPrettyDateFromUnix(message.created_time)}}</td>
              <td>{{message.type}}</td>
              <td><div style="font-size: 12px; white-space: pre-wrap; max-width: 700px;">{{message.message}}</div></td>
              <td v-if="message.transaction">
                  {{getPrettyPrice(message.transaction.amount)}}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex flex-row justify-content-between">
          <p class="text-muted text-small mt-2" v-if="cases.state == 'open'">Case will be resolved by: {{getPrettyDateFromUnix(cases.next_action_time)}}</p>
          <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#casesMessageModal" data-action="update">Update Case</button>
      </div>
      </Card>
      <Card title="Return Info" v-if="returnInfo">
        <table class="table">
          <tbody>
            <!-- <tr>
              <th scope="row">Created time</th>
              <td v-if="has(returnInfo, '_created_at')">{{ getPrettyDate(returnInfo._created_at )}}</td>
              </tr> -->
            <tr>
              <th scope="row">Attempt time</th>
              <td v-if="has(returnInfo, 'stats.launch_time')">{{ getPrettyDate(returnInfo.stats.launch_time)}}</td>
              <td v-else></td>
            </tr>
            <tr>
              <th scope="row">Return status</th>
              <td>
                <span v-if="returnInfo._type=='error' && returnInfo.code=='request_processing'">Processsing: {{ returnInfo.code }}</span>
                <span v-if="returnInfo._type=='error'" class="text-danger">Failed: {{ returnInfo.code }}</span>
                <span v-else class="text-success">Sucess</span>
              </td>
            </tr>
            <tr>
              <th scope="row">Refund amount</th>
              <td><span v-if="has(returnInfo, 'status.refund_amount') && returnInfo.status.refund_amount!==0">${{ getDollarsFromCents(returnInfo.status.refund_amount) }}</span></td>
            </tr>
            <tr>
              <th scope="row">Return method</th>
              <td><span v-if="has(returnInfo, 'selected_return_method')">{{ returnInfo.selected_return_method }}</span></td>
            </tr>
            <tr>
              <th scope="row">Return label</th>
              <td><span  v-if="has(returnInfo, 'label_urls.0')"><a :href="returnInfo.label_urls[0]" target="_blank">{{ returnInfo.label_urls[0] }}</a></span></td>
            </tr>
            <tr>
              <th scope="row">Return Id</th>
              <td>
                <a :href="`https://${user.zincClientToken}:@api.zinc.io/v1/returns/${returnInfo.request_id}`" target="_blank">{{returnInfo.request_id}}</a>
              </td>
            </tr>
            <tr>
              <th scope="row">Screenshots</th>
              <td class="d-flex flex-row flex-wrap">
                <div v-for="screenshot_url in returnInfo.screenshot_urls" class="m-2">
                  <a :href="screenshot_url" target="_blank"><img :src="screenshot_url" class="zn-order-debug-img" /></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <Card title="Previous Return Requests" v-if="order && get(order, 'return_request_ids', '')">
          <div v-for="return_request_id in order.return_request_ids">
              <a :href="`https://${user.zincClientToken}:@api.zinc.io/v1/returns/${return_request_id}`" target="_blank">{{return_request_id}}</a>
          </div>
        </Card>
      </Card>
      <Card title="Tracking Info">
        <table class="table table-sm table-borderless" v-if="has(order, 'tracking')">
          <thead class="text-muted">
            <tr>
              <th>Merchant Order Id</th>
              <th>Tracking Number</th>
              <th>Carrier</th>
              <th>Items</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="trackingObject in get(order, 'tracking', [])">
              <tr>
                <td>{{get(trackingObject, 'merchant_order_id')}}</td>
                <td>
                  <a v-if="get(trackingObject, 'tracking_url') != null" :href="get(trackingObject, 'tracking_url')" target="_blank" rel="noopener noreferrer">{{get(trackingObject, 'tracking_number', '')}}</a>
                  <span v-if="get(trackingObject, 'tracking_number') == null">Order Cancelled</span>
                </td>
                <td>{{get(trackingObject, 'carrier')}}</td>
                <td><template v-for="productId in get(trackingObject, 'product_ids', [])"><a :href="getProductUrl(productId)" class="zn-listing-link" rel="noopener noreferrer" target="_blank">{{ productId }} <span v-html="marketInfo.icon"></span></a><br></template></td>
              </tr>
            </template>
            <template v-for="trackingObject in get(order, 'tracking', [])">
              <tr v-if="get(trackingObject, 'retailer_tracking_number')">
                <td>{{get(trackingObject, 'merchant_order_id')}}</td>
                <td>
                  <a v-if="get(trackingObject, 'retailer_tracking_url') != null" :href="get(trackingObject, 'retailer_tracking_url')" target="_blank" rel="noopener noreferrer">{{get(trackingObject, 'retailer_tracking_number')}}</a>
                </td>
                <td>RETAILER</td>
                <td><template v-for="productId in get(trackingObject, 'product_ids', [])"><a :href="getProductUrl(productId)" class="zn-listing-link" rel="noopener noreferrer" target="_blank">{{ productId }} <span v-html="marketInfo.icon"></span></a><br></template></td>
              </tr>
            </template>
          </tbody>
        </table>
        <span class="text-muted" v-else>Tracking will be provided when received from the retailer</span>
      </Card>
      <div class="d-flex flex-row flex-wrap order-info-cards"> <!-- start small card grid -->
        <Card title="Shipping Address" v-if="order && has(order, 'request.shipping_address')">
          <strong>{{ order.request.shipping_address.first_name }} {{ order.request.shipping_address.last_name }}</strong>
          <br>{{ order.request.shipping_address.address_line1 }}
          <br><span v-if="order.request.shipping_address.address_line2">{{ order.request.shipping_address.address_line2 }}
          <br></span>{{ order.request.shipping_address.city }} {{ order.request.shipping_address.state }}  {{ order.request.shipping_address.zip_code }}
          <br>{{ order.request.shipping_address.country }}
          <br>P: {{ order.request.shipping_address.phone_number }}
        </Card>
        <Card title="Billing Address" v-if="order && !get(order, 'request.addax', false) && has(order, 'request.billing_address')">
            <strong>{{ get(order, 'request.billing_address.first_name') }} {{ get(order, 'request.billing_address.last_name') }}</strong>
            <br>{{ get(order, 'request.billing_address.address_line1') }}
            <br><span v-if="order.request.billing_address.address_line2">{{  get(order, 'request.billing_address.address_line2') }}
            <br></span>{{  get(order, 'request.billing_address.city') }} {{ get(order, 'request.billing_address.state') }}  {{ get(order, 'request.billing_address.zip_code') }}
            <br>{{ get(order, 'request.billing_address.country') }}
            <br>P: {{ get(order, 'request.billing_address.phone_number') }}
        </Card>
        <Card class="order-notes-card" title="Order Notes" v-if="order && get(order, 'request.client_notes', '')">
          <p class="code">{{get(order, 'request.client_notes', '')}}
          </p>
        </Card>
      </div> <!-- end small card grid -->
    </div>
  </div>
  <div class="row" v-if="order.screenshot_urls && order.screenshot_urls.length">
    <div class="col-12">
      <Card title="Automatic Ordering Screenshots">
        <div class="mt-2 mb-1">
            <span v-for="screenshot_url in order.screenshot_urls">
              <a :href="screenshot_url" target="_blank"><img :src="screenshot_url" class="zn-order-debug-img" /></a>
            </span>
        </div>
      </Card>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <Card title="Automatic Ordering Event Log">
        <table class="table">
            <tbody>
                <template v-for="status_update in order.status_updates">
                <tr><td>{{get(status_update, 'type', '')}}</td><td>{{get(status_update, 'message', '')}}</td><td>{{getPrettyDate(get(status_update, '_created_at', ''))}}</td></tr>
                </template>
            </tbody>
        </table>
        <div class="mt-2 mb-1" v-if="get(order, 'offers_urls', []).length">
          <small class="text-muted">Offers</small>
          <span v-for="(offerUrl, index) in get(order, 'offers_urls', [])">
              <a :href="offerUrl" target="_blank">#{{index + 1}}</a>
          </span>
        </div>
      </Card>
    </div>
  </div>

    <div class="row">
        <div class="col-12">
          <div id="accordion">
            <div class="card">
                <div class="card-header" id="headingTwo">
                    <h6 class="mb-0"><button class="btn btn-link" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">View Order Json
                        </button>
                    </h6>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="collapseTwo" data-parent="#accordion">
                    <div class="card-body">
                        <pre><br>{{order}}
                        </pre>
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>
    <CasesModal :orderId="zincOrderId" :clientToken="user.zincClientToken" :action="casesModalAction" :setCases="this.setCases"></CasesModal>
    <div class="modal fade" id="abortConfirmModal" tabindex="-1" role="dialog" aria-labelledby="abortConfirmModal" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="abortConfirmModalTitle">
              Are you sure you want to abort this order?
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Note that abortion is best effort, so we cannot guarantee that you will be able to abort a request.
            <a href="https://docs.zincapi.com/#aborting-an-order" target="_blank">Learn more.</a>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn" data-dismiss="modal">Close</button>
            <button @click.prevent="abortOrder()" id="abortOrderButon" class="btn btn-warning">Abort Order</button>
          </div>
        </div>
      </div>
    </div>

</div>
</template>

<script>
import Auth from '@/auth'
import Spinner from '../common/Spinner.vue'
import CasesModal from '../cases/CasesModal.vue'
import Card from '../common/Card.vue'
const marketplaces = require('../common/marketplaces.data.js')
const tz = moment.tz.guess()

export default {
  name: 'view-order',
  components: { Spinner, Card, CasesModal },
  data() {
    return {
      auth: this.$store.state.auth,
      user: this.$store.state.currentUser,
      loginUser: this.$store.state.loginUser,
      casesModalAction: 'open',
      cases: null,
      order: '',
      marketInfo: '',
      marketId: '',
      returnInfo: '',
      zincOrderId: null,
      marketplaces: marketplaces,
      getDollarsFromCents: utils.getDollarsFromCents,
    }
  },
  computed: {
    currentClientToken: {
      get: function() {},
      set: function(newValue) {},
    },
  },
  created() {
    if (this.$route.params.zincOrderId) {
      this.zincOrderId = this.$route.params.zincOrderId
      this.getOrder(this.zincOrderId)
    }
  },
  mounted() {
    $('#casesMessageModal').on('show.bs.modal', function(e) {
      let buttonClicked = $(event.relatedTarget)
      let action = buttonClicked.data('action')
      this.casesModalAction = action
    })
  },
  methods: {
    getPrettyDate: function(UTCDate) {
      if (!UTCDate) return "";
      return moment(UTCDate)
        .tz(tz)
        .format('l LTS')
    },
    getPrettyDateFromUnix: function(unixDate) {
      return moment
        .unix(unixDate)
        .tz(tz)
        .format('l LTS')
    },
    getPrettyPrice: function(num) {
      return `${this.marketInfo.currencySymbol} ${(num / 100).toFixed(2)}`
    },
    getPrettyMarketName: function() {
      if(!this.marketInfo.humanReadable) return ""
      return `<span class="zn-listing-link">${this.marketInfo.humanReadable} <span class="text-nowrap">${
        this.marketInfo.icon
      }</span></span>`
    },
    getProductUrl: function(productId){
      //we allow bludgen to shove in full urls
      if(/^(http:\/\/|https:\/\/)/i.test(productId)) return productId;
      return this.marketInfo.listingUrl(productId)
    },
    async createReturn(request_id, clickEvent) {
      if(clickEvent) clickEvent.target.disabled = true //only want to create a single return
      let products = this.order.request.products.map(p=>{
        return {"product_id": p.product_id, "quantity": p.quantity}
      });
      let res = await utils.apiPost(`/orders/${request_id}/return`, {
        "products": products,
        "reason_code": "item arrived too late",
        "method_code": "UPS Dropoff",
        //"explanation": "arrived too late",
        "cancel_pending": false,
      })
      console.log(res)
    },
    isReturnable(){
      return _.get(this.order, "_type") == "order_response"
    },
    isAbortable: function() {
      return _.get(this.order, 'code', false) == 'request_processing'
        && Array.isArray(_.get(this.order, 'request.products'))
    },
    isCancelable() {
      let retailer = _.get(this.order, 'request.retailer', '')
      return (retailer=='amazon' || retailer=='amazon_uk') && this.order._type == 'order_response'
    },
    async cancelOrder(zincOrderId, clickEvent) {
      if(clickEvent) clickEvent.target.disabled = true //only want to create a single return
      let response = await utils.apiPost(`/orders/${zincOrderId}/cancel`)
      alert('Successful cancel response:\n' +JSON.stringify(response.body, null, 3))
      this.getOrder(this.zincOrderId)
    },
    async abortOrder() {
      $('#abortConfirmModal').modal('hide')
      await utils.apiPost(`/orders/${this.zincOrderId}/abort`)
      this.getOrder(this.zincOrderId)
    },
    async getCases(zincOrderId) {
      let res = await utils.apiGet(`/orders/${zincOrderId}/case`)
      this.cases = res.body
      if (this.cases.state == 'open') {
        this.casesModalAction = 'update'
      }
    },
    getOrder: async function getOrder(zincOrderId) {
      //get the order
      let response = await utils.apiGet(`/orders/${zincOrderId}`)
      this.order = response.data
      this.marketId = this.order.request.retailer
      this.marketInfo = this.marketplaces[this.marketId]
      if (!this.marketInfo) {
        this.marketInfo = this.marketplaces['DEFAULT']
        this.marketInfo.id = this.marketId
        this.marketInfo.humanReadable = this.marketId
      }
      //If order does not belong to loaded user redirect to correct user
      const orderClientToken = _.get(this.order, 'request.client_token')
      if(orderClientToken && orderClientToken !== this.user.zincClientToken && this.loginUser.isAdmin){
        const ctResponse = await this.$http.get(`${utils.LOGIN_URL}/apidash/users?q=${orderClientToken}`,
          {headers:{Authorization: `Bearer ${this.auth.accessToken}`}})
        if(ctResponse.body.length){ //exact match only
          let orderZincUserId = ctResponse.body[0].id
          this.$router.push({name: 'view-order', params: {zincUserId: orderZincUserId,	zincOrderId: zincOrderId}})
          this.$router.go()
        }
      }
      //load additional order data
      if (_.get(this.order, 'request.addax')) {
        await this.getCases(zincOrderId)
      }
      if (_.has(this.order, 'return_request_ids')) {
        let returnRequestId = this.order.return_request_ids[this.order.return_request_ids.length-1]
        let returnInfos = await Promise.all(this.order.return_request_ids.map(returnRequestId=>utils.apiGet(`/returns/${returnRequestId}`).catch(err=>null)))
        returnInfos = returnInfos
          .filter(returnInfo=>returnInfo)
          .map(returnInfo=>returnInfo.body)
          .filter(returnInfo=>returnInfo.code !== 'return_in_progress')
        this.returnInfo = returnInfos[returnInfos.length-1]
      }
    },
    setCases: function(cases) {
      console.log(cases)
      this.cases = cases
    },
    getTrackingLinks: utils.getTrackingLinks,
    lastStatusUpdate: function(returnInfo) {
      return returnInfo.status_updates[returnInfo.status_updates.length - 1]
    },
    get: _.get,
    has: _.has,
    includes: _.includes,
  },
}
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;
  margin-bottom: 0;
}

.order-info-cards {
  > div {
    flex: 1 1 20%;
    margin-right: 15px;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.items-table {
  overflow-y: auto;
  max-height: 350px;
}

.order-notes-card {
  flex: 2 1 640px !important;
}
</style>
