<template>
  <div class="ev-orders">
    <!-- <div class="card"> -->
    <div class="title-row d-flex flex-row align-items-center py-3">
      <h4 class="mx-2 my-2 mb-0">
        Orders
      </h4>
      <span class="text-muted">Showing {{ lowerBound }} - {{ upperBound }} results of <span
          v-if="upperBound < (currentOffset + resultsPerPage)">{{ upperBound }}</span><span v-else>Many</span></span>
      <div id="zn-page-select" class="form form-inline ml-auto">
        <div class="form-group">
          <label for="resultsPerPage">Results per page:</label>
          <select name="resultsPerPage" class="form-control input-sm" v-model="resultsPerPage"
            @change="changeResultsPerPage">
            <!-- inline object literal -->
            <option>20</option>
            <option>50</option>
            <option>100</option>
            <option>200</option>
            <option>1000</option>
          </select>
        </div>
        <span v-if="previousPageUrl"><a class="btn btn-outline-secondary px-2 ml-2" :href="previousPageUrl"><i
              class="fa fa-chevron-left pr-2" aria-hidden="true"></i>Previous</a></span>
        <span v-if="nextPageUrl"><a class="btn btn-outline-secondary px-2 ml-2" :href="nextPageUrl">Next<i
              class="fa fa-chevron-right pl-2" aria-hidden="true"></i></a></span>
        <!-- <button class="btn btn-primary" @click="getOrders()"><i class="fa fa-refresh px-1" aria-hidden="true"></i>Get orders</button> -->
      </div>
    </div>
    <!-- <div class="card-block px-1 py-3"> -->
    <spinner v-show="isLoadingOrders"></spinner>
    <!-- <div class="row">
        <div class="my-2 px-3">
          <button type="button" class="btn btn-success" data-toggle="modal" data-target="#createOrderModal">
            <i class="fa fa-plus px-1" aria-hidden="true"></i>Create Order
          </button>
        </div>
      </div> -->
    <div class="table-responsive">
      <table id="orders-table" class="table">
        <thead>
          <tr>
            <th>Zinc Order ID</th>
            <th>Submitted At</th>
            <th>Finished At</th>
            <th>Retailer</th>
            <th style="text-align:center;">Order Total</th>
            <th style="text-align:center;">Max Price</th>
            <th style="text-align:center;">Order Status</th>
            <th>Client Note</th>
            <th>Tracking</th>
            <th>Merchant Order IDs</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="orders.length > 0">
            <tr v-for="order in orders">
              <td class="text-nowrap">
                <div class="btn-group">
                  <a type="button" style="font-family: monospace; font-size: 12px;"
                    class="btn btn-outline-primary btn-sm d-flex align-items-center"
                    :href="`/${user.zincUserId}/orders/${order.request_id}`">{{ shortenStr(order.request_id, 22) }}</a>
                  <button type="button" class="btn btn-sm btn-outline-primary dropdown-toggle dropdown-toggle-split"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item"
                      :href="`https://${currentClientToken}:@api.zinc.io/v1/orders/${order.request_id}`"
                      target="_blank">View JSON</a>
                    <a class="dropdown-item" href="#" @click="copyToClip(order.request_id)">Copy ID</a>
                    <a class="dropdown-item" href="#"
                      @click="copyToClip(JSON.stringify(getData(order, 'request.client_notes', '')))">Copy Client Note</a>
                  </div>
                </div>
                <div v-if="order.cancellation_request_ids" class="pt-2">
                  <strong>Cancellation request IDs:</strong>
                  <div v-for="cancellation_request_id in order.cancellation_request_ids"
                    style="font-family: monospace; font-size: smaller;">
                    <a :href="`https://${currentClientToken}:@api.zinc.io/v1/cancellations/${cancellation_request_id}`"
                      target="_blank">{{ cancellation_request_id }}</a>
                  </div>
                </div>
                <div v-if="order.return_request_ids" class="pt-2">
                  <strong>Return request IDs:</strong>
                  <div v-for="return_id in order.return_request_ids" style="font-family: monospace; font-size: smaller;">
                    <a target="_blank" :href="`https://${currentClientToken}:@api.zinc.io/v1/returns/${return_id}`">{{
                      return_id }}</a>
                  </div>
                </div>
              </td>
              <td style="white-space:nowrap;">{{ order.request ? getPrettyDate(order.request._created_at) :
                getPrettyDate(order._created_at) }}</td>
              <td style="white-space:nowrap;">{{ order._created_at ? getPrettyDate(order._created_at) : '' }}</td>
              <td><span style="width:75px; display:inline-block;"
                  v-html="getPrettyMarketName(getData(order, 'request.retailer', ''))"></span></td>
              <td class="zn-price"><span v-html="getPrettyOrderTotal(order)"></span></td>
              <td class="zn-price"><span
                  v-html="getPrettyMarketPrice(getData(order, 'request.retailer', ''), getData(order, 'request.max_price', 0))"></span>
              </td>
              <td :class="getOrderRowStyles(order)" class="zn-order-state"
                :title="(order.message ? order.message : (order.data ? order.data.message || order.data.msg || order.data.details : '')) || ''">
                <b>{{ getPrettyOrderStateString(order) }}</b>
                <div v-if="order.code">
                  {{ order.code }}
                </div>
              </td>
              <td class="zn-price">{{ getPrettyClientNotes(getData(order, 'request.client_notes', '')) }}</td>
              <td class="zn-price"><span v-html="getTrackingLinks(order)"></span></td>
              <td class="zn-price">{{ order.merchant_order_ids && order.merchant_order_ids[0] ?
                order.merchant_order_ids[0].merchant_order_id : '' }}</td>
              <td>
                <div class="dropdown">
                  <button type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown"
                    aria-expanded="false">
                    Actions
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" :href="`/${user.zincUserId}/orders/${order.request_id}`">View</a>
                    <a class="dropdown-item text-danger"
                      v-if="order._type == 'error' && getData(order, 'code', '') == 'request_processing'"
                      @click="abortOrder(order.request_id)" href="#">Abort</a>
                    <a class="dropdown-item"
                      v-if="order._type == 'error' && getData(order, 'code', '') != 'request_processing'"
                      @click="retryOrder(order.request_id)" href="#">Retry</a>
                    <a class="dropdown-item text-danger" @click="cancelOrder(order.request_id)"
                      v-if="(getData(order, 'request.retailer', '') == 'amazon' || getData(order, 'request.retailer', '') == 'amazon_uk') && order._type == 'order_response'"
                      href="#">Cancel<span v-if="order.cancellation_request_ids"> Again</span></a>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <h4 class="pt-3 mt-3">No orders <span v-if="currentOffset > 0">on this page</span></h4>
          </template>
        </tbody>
      </table>
    </div>
    <div class="modal fade" id="createOrderModal" tabindex="-1" role="dialog" aria-labelledby="createOrderModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="createOrderModalLabel">Create a Zinc Order Request</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <div class="form m-2">
              <div class="form-row py-2">
                <h4>Pick your product</h4>
              </div>
              <div class="form-row py-2">

                <div class="col wolf-bg" style="height:150px">
                  <div class="mt-3 bg-dark text-light align-bottom">
                    Wolf Shirt (Small)<br>
                    $ 8.92<br>
                    ASIN: B002HJ377A
                  </div>
                </div>

                <div class="col whip-bg" style="height:150px">
                  <div class="mt-3 bg-dark text-white align-bottom">
                    Premium Suede Hand Whip<br>
                    $ 13.51<br>
                    ASIN: B00BYH5PG2
                  </div>
                </div>

                <div class="col rogaine-bg" style="height:150px">
                  <div class="mt-3 bg-dark text-light align-bottom">
                    Hair Loss Treatment<br>
                    $ 19.52<br>
                    ASIN: B0012BNVE8
                  </div>
                </div>

              </div>
              <div class="form-row py-2">
                <h4>Do you use managed accounts?</h4>
                <div class="form-group">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                      value="option1">
                    <label class="form-check-label" for="inlineRadio1">Yes, I use Managed Accounts</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                      value="option2">
                    <label class="form-check-label" for="inlineRadio2">No, I would like to use my own Amazon Account and
                      Payment Method</label>
                  </div>
                </div>
              </div>
              <div class="form-row py-2">
                <p>Submitting the form below will place a <strong>live order</strong> on Amazon</p><br>
                <p>Alternatively you can fill out the form and copy a curl request</p>
                <div>
                  <tree-view :data="createOrderJSON" :options="{ modifiable: true }"
                    @change-data="onChangeData"></tree-view>
                </div>
              </div>
              <div class="form-row py-2">
                <button type="button" class="btn btn-success px-3 mx-2" disabled>Submit Amazon Order</button>
                <button type="button" class="btn btn-info px-3 mx-2" disabled>Copy CURL request</button>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Auth from '@/auth'
import Spinner from '../common/Spinner.vue'
import router from '@/router'
const marketplaces = require('../common/marketplaces.data.js')
const tz = moment.tz.guess()
const DEFAULT_NUM_RESULTS = 100

export default {
  name: 'orders',
  components: { Spinner },
  props: ['limit', 'offset'],
  data() {
    return {
      auth: this.$store.state.auth,
      user: this.$store.state.currentUser,
      loginUser: this.$store.state.loginUser,
      currentClientToken: this.$store.state.currentUser.zincClientToken,
      isLoadingOrders: false,
      orders: [],
      currentOffset: 0,
      resultsPerPage: DEFAULT_NUM_RESULTS,
      marketplaces: marketplaces,
      createOrderJSON: {
        client_token: '',
        retailer: 'amazon',
        products: [{ product_id: 'BOOBYH5PG2', quantity: 1 }],
        shipping_address: {
          first_name: 'Donald',
          last_name: 'Trump',
          address_line1: 'The White House',
          address_line2: '1600 Pennsylvania Ave',
          zip_code: '20500',
          city: 'Washington',
          state: 'DC',
          country: 'US',
          phone_number: 'aditya.advani',
        },
        max_price: '1000',
        is_gift: true,
        shipping_method: 'cheapest',
      },
    }
  },
  computed: {
    lowerBound: function () {
      if (this.orders.length == 0) {
        var firstIndex = 0
      } else {
        var firstIndex = 1
      }
      return this.currentOffset + firstIndex
    },
    upperBound: function () {
      return Math.min(
        this.currentOffset + this.resultsPerPage,
        this.currentOffset + this.orders.length
      )
    },
    nextPageUrl: function () {
      var nextUrl = ''
      if (this.upperBound >= this.currentOffset + this.resultsPerPage) {
        nextUrl = `/${this.user.zincUserId}/orders?limit=${this.resultsPerPage
          }&offset=${this.currentOffset + this.resultsPerPage}`
      }
      // console.log(`currentOffset:${this.currentOffset}, upperBound:${this.upperBound}, nextUrl:${nextUrl}, currentOffset + resultsPerPage, ${(this.currentOffset + this.resultsPerPage)}`)
      return nextUrl
    },
    previousPageUrl: function () {
      if (this.lowerBound > 1) {
        return `/${this.user.zincUserId}/orders?limit=${this.resultsPerPage
          }&offset=${Math.max(this.currentOffset - this.resultsPerPage, 0)}`
      } else {
        return ''
      }
    },
  },
  watch: {
    // whenever question changes, this function will run
    currentClientToken: function (newClientToken, oldClientToken) {
      console.log(`updated user ZCT to ${newClientToken}`)
      this.user.zincClientToken = newClientToken
      this.$store.commit('UPDATE_CURRENT_USER', this.user)
      this.getOrders()
    },
  },
  created() {
    if (this.$route.query.limit) {
      this.resultsPerPage = parseInt(this.$route.query.limit)
    } else {
      this.resultsPerPage = DEFAULT_NUM_RESULTS
    }

    if (this.$route.query.offset) {
      this.currentOffset = parseInt(this.$route.query.offset)
    } else {
      this.currentOffset = 0
    }

    this.getOrders()
  },
  methods: {
    changeResultsPerPage: function () {
      this.currentOffset = 0
      this.getOrders()
    },
    getData: function (order, key, defaultValue) {
      return _.get(order, key, defaultValue)
    },
    shortenStr(str, len) {
      if (str.length > len) {
        return str.substr(0, Math.ceil(len / 2)) + '…' + str.substr(str.length - Math.floor(len / 2), str.length);
      } else {
        return str
      }
    },
    getPrettyClientNotes(notes) {
      if (!notes) {
        return ''
      }

      if (Array.isArray(notes)) {
        return JSON.stringify(notes)
      }

      if (typeof notes == 'object') {
        var ret = []
        Object.keys(notes).forEach(function (key) {
          ret.push(`${key}: ${JSON.stringify(notes[key])}`)
        })
        return ret.join('\n')
      }

      return JSON.stringify(notes)
    },
    getPrettyDate: function (UTCDate) {
      return moment(UTCDate)
        .tz(tz)
        .format('l LTS')
    },
    getPrettyMarketName: function (marketId) {
      //${ _.get(this.marketplaces, `${marketId}.humanReadable`, 'WTF') }
      return `<span class="zn-listing-link">${_.get(
        this.marketplaces,
        `${marketId}.icon`,
        marketId
      )}</span>`
    },
    getPrettyMarketPrice: function (marketId, num) {
      if (!_.isNumber(num)) {
        num = 0
      }
      return `${_.get(
        this.marketplaces,
        `${marketId}.currencySymbol`,
        '$'
      )} ${_.padStart((num / 100).toFixed(2), 9)}`
    },
    getOrderRowStyles: function (order) {
      return {
        'text-center': true,
        'text-danger':
          order._type == 'error' && order.code != 'request_processing',
        'text-warning':
          order._type == 'error' && order.code == 'request_processing',
        'text-success': order._type == 'order_response',
      }
    },
    copyToClip: function (str) {
      navigator.clipboard.writeText(str)
    },
    getPrettyOrderStateString: function (order) {
      switch (order._type) {
        case 'error':
          return order.code == 'request_processing' ? 'In Progress' : ''
        case 'order_response':
          return 'success'
      }
    },
    getPrettyOrderTotal: function (order) {
      var marketId = _.get(order, 'request.retailer', 'unknown')
      var num =
        order._type == 'error'
          ? _.get(order, 'data.price_components.total', 0)
          : _.get(order, 'price_components.total', 0)
      return `${_.get(
        this.marketplaces,
        `${marketId}.currencySymbol`,
        '$'
      )} ${_.padStart((num / 100).toFixed(2), 9)}`
    },
    async getOrders() {
      this.isLoadingOrders = true
      router.push({
        path: this.$route.path,
        query: { limit: this.resultsPerPage, offset: this.currentOffset },
      })
      let response = await utils.apiGet(`/orders?limit=${this.resultsPerPage}&offset=${this.currentOffset}`)
      this.orders = _.get(response, 'data.orders', [])
      this.isLoadingOrders = false
    },
    getTrackingLinks: utils.getTrackingLinks,
    async abortOrder(zincOrderId) {
      let response = await utils.apiPost(`/orders/${zincOrderId}/abort`)
      alert('Successful abort response:\n' + JSON.stringify(response.body, null, 3))
      this.getOrders()
    },
    async retryOrder(zincOrderId) {
      let response = await utils.apiPost(`/orders/${zincOrderId}/retry`)
      if (response.body && response.body.request_id) {
        alert('Successful retry order id:\n' + JSON.stringify(response.body.request_id, null, 3))
      } else {
        alert('Unsuccessful retry:\n ' + JSON.stringify(response.body, null, 3))
      }
      this.getOrders()
    },
    async cancelOrder(zincOrderId) {
      let response = await utils.apiPost(`/orders/${zincOrderId}/cancel`)
      alert('Successful cancel response:\n' + JSON.stringify(response.body, null, 3))
      this.getOrders()
    },
    onChangeData: function (data) {
      console.log(JSON.stringify(data))
    },
    viewOrder: function (event, zincOrderId) {
      if (!event.ctrlKey) {
        // event.preventDefault()
        this.$router.push({
          name: 'view-order',
          params: {
            zincUserId: this.user.zincUserId,
            zincOrderId: zincOrderId,
          },
        })
      }
    },
  },
}
</script>

<style>
#orders-table {
  font-size: 0.8rem;
}

#orders-table th {
  padding: .1rem;
}

#orders-table td {
  padding-bottom: .1rem;
  padding-top: .1rem;
  padding-left: .1rem;
  padding-right: .1rem;
}

#orders-table .zn-order-state {
  line-height: normal;
  padding-top: 2px;
}

.title-row>*:not(h4) {
  line-height: 1em;
}

#zn-page-select .form-group {
  padding-right: 8px;
}

#zn-page-select .form-group label {
  padding-right: 3px;
}
</style>
