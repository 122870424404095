var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("h5", { staticClass: "card-title" }, [
        _vm._v("Current Billing Balance")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "card-text" }),
      _c("h1", [
        _vm._v(
          _vm._s(
            _vm.getPrettyPrice(
              "$",
              _vm.prepaidLedger.unbilledBalance || _vm.prepaidLedger.balance
            )
          )
        )
      ]),
      _vm._v(" "),
      _vm.feePlan.auto_charge
        ? _c("h5", [
            _vm._v(
              "We will automatically charge your payment method if your balance reaches zero\n        "
            )
          ])
        : _c("h5", [
            _vm._v("If your balance reaches zero your API calls will fail")
          ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { "data-toggle": "modal", "data-target": "#addBalanceModal" }
          },
          [_vm._v("Add Balance")]
        ),
        _vm._v(" "),
        _vm.feePlan.auto_charge
          ? _c(
              "a",
              {
                staticClass: "btn btn-outline-primary",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    _vm.enableAutoPay(false)
                  }
                }
              },
              [_vm._v("Disable AutoPay")]
            )
          : _c(
              "a",
              {
                staticClass: "btn btn-primary",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    _vm.enableAutoPay(true)
                  }
                }
              },
              [_vm._v("Enable AutoPay")]
            ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn-outline-primary",
            attrs: { href: _vm.managePaymentMethodsUrl }
          },
          [_vm._v("Manage Payment Methods")]
        )
      ]),
      _vm._v(" "),
      _c("p")
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "addBalanceModal",
          tabindex: "-1",
          role: "dialog",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered modal-lg",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitAddBalance($event)
                    }
                  }
                },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-footer" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-outline-primary",
                        attrs: { href: _vm.managePaymentMethodsUrl }
                      },
                      [_vm._v("Manage Payment Methods")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "submit" }
                      },
                      [_vm._v("Charge Payment Method")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button", "data-dismiss": "modal" }
                      },
                      [_vm._v("Cancel")]
                    )
                  ])
                ]
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "exampleModalLongTitle" } },
        [_vm._v("Add Prepaid Balance")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-body" }, [
      _c("div", { staticClass: "input-group" }, [
        _c("div", { staticClass: "input-group-prepend" }, [
          _c("div", { staticClass: "input-group-text" }, [_vm._v("$")])
        ]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          staticStyle: { "max-width": "600px" },
          attrs: {
            type: "number",
            name: "amount",
            id: "moneyInput",
            min: "50",
            max: "1000",
            step: "1",
            required: ""
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }