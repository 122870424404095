<template>
  <div class="ev-balance" id="ev-balance">
    <div class="row justify-content-center">
      <div class="col-md-10">
        <div class="text-center">
          <h3 class="my-4">Zinc Managed Accounts</h3>
          <div class="text-muted">
            <p><strong>Zinc Managed Accounts (ZMA)</strong> take the headache out of opening, maintaining, and funding
              Amazon and other retailer accounts, allowing you to focus on growing your business.</p>

            <p>Using managed accounts is totally free! You just pay your standard AutoOrdering (AO) Fee. Managed accounts
              are currently only available for Amazon US, UK and Germany.</p>

            <p>Learn more about managed accounts <a href="http://docs.zincapi.com/#zinc-managed-accounts"
                target="_blank">here</a> and their benefits <a
                href="https://support.priceyak.com/hc/en-us/articles/115003853443-About-Managed-Accounts"
                target="_blank">here</a>.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-10">
        <div class="card">
          <div class="card-body">
            <h6 class="text-muted font-weight-normal mb-3">Current ZMA Balances for {{ user.name }}</h6>
            <spinner v-show="isLoading"></spinner>
            <div class="pull-in bg-light p-3">
              <div class="row d-flex justify-content-around">
                <div class="text-center" v-for="addaxCurrency in addaxCurrencies">
                  <p class="text-muted mb-0 mt-3">{{ addaxCurrency.code }}</p>
                  <h4>{{ addaxCurrency.symbol }}<span v-html="getPrettyBalancePrice(balances, addaxCurrency.code)"></span>
                  </h4>
                </div>
              </div>
            </div>

            <a class="btn btn-outline-primary btn-block mt-4"
              :href="`https://www.priceyak.com/user/${user.zincUserId}/managedaccount?apiLayout=1`" target="_blank">
              <i class="fa fa-plus px-1" aria-hidden="true"></i>Fund your account
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center" v-if="!tos_accept_time">
      <Card cssClass="col-md-10" title="Terms of Service">
        <ZmaTerms></ZmaTerms>
      </Card>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-10">
        <div class="card">
          <div class="card-body">
            <h6 class="text-muted font-weight-normal mb-3">Transaction History</h6>
            <spinner v-show="isLoading"></spinner>

            <div class="row justify-content-center">
              <div class="col-12">
                <div class="btn-group btn-group-toggle">
                  <label class="btn btn-light" :class="{ active: picked_txn_view == 'all_txns' }">
                    <input type="radio" id="option1" value="all_txns" v-model="picked_txn_view"> All txns
                  </label>
                  <label class="btn btn-light" :class="{ active: picked_txn_view == 'deposits_only' }">
                    <input type="radio" id="option2" value="deposits_only" v-model="picked_txn_view"> Deposits only
                  </label>
                  <label class="btn btn-light" :class="{ active: picked_txn_view == 'admin_adjustment' }">
                    <input type="radio" id="option2" value="admin_adjustment" v-model="picked_txn_view"> Admin Adjustments
                  </label>
                  <!-- <label class="btn btn-light">
                        <input type="radio" name="options" id="option3" autocomplete="off"> Refunds
                    </label> -->
                </div>
              </div>

              <!-- <h4 class="card-title">Latest transactions:</h4>  -->
              <div class="col-12">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th style="width: 8%">Created Time</th>
                      <th style="width: 5%" class="text-center">Order</th>
                      <th style="width: 5%" class="text-right">Amount</th>
                      <th style="width: 10%">Transaction Id</th>
                      <th class="text-left">Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="transactions.length > 0">
                      <tr v-for="transaction in transactions">
                        <td class="text-nowrap text-left">{{ getPrettyDateFromUnix(transaction.created_time) }}</td>
                        <td class="text-nowrap text-center">
                          <a v-if="transaction.addax_zinc_order_id"
                            :href="`/${user.zincUserId}/orders/${transaction.addax_zinc_order_id}`"
                            @click.prevent="viewOrder(transaction.addax_zinc_order_id)">View&nbsp;Order</a>
                        </td>
                        <td class="text-right zn-price" v-bind:class="getColorClassName(transaction)">{{
                          getPrettyTransactionPrice(transaction) }}</td>
                        <td style="font-family: monospace; font-size: 1.15em">{{ transaction.id }}</td>
                        <td class="text-left">{{ transaction.note }}</td>
                      </tr>
                    </template>
                    <template v-else>
                      <h4 class="pt-3 mt-3">No transactions found.</h4>

                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center" v-if="tos_accept_time">
      <Card cssClass="col-md-10" title="Terms of Service">
        <ZmaTerms></ZmaTerms>
      </Card>
    </div>
  </div>
</template>

<script>
import Auth from '@/auth'
import Spinner from '../common/Spinner.vue'
import ZmaTerms from '../common/ZmaTerms.vue'
import Card from '../common/Card.vue'
import router from '@/router'
const addaxCurrencies = require('../common/addaxCurrencies.data.js')
const marketplaces = require('../common/marketplaces.data.js')
const tz = moment.tz.guess()

export default {
  name: 'balance',
  components: { Spinner, ZmaTerms, Card },
  props: ['limit', 'skip', 'txn_view'],
  data() {
    return {
      auth: this.$store.state.auth,
      user: this.$store.state.currentUser,
      currentClientToken: this.$store.state.currentUser.zincClientToken,
      isLoading: false,
      balances: '',
      transactions: '',
      currentPage: 0,
      // limit: 1000,
      resultsPerPage: { number: 100 },
      addaxCurrencies: addaxCurrencies,
      marketplaces: marketplaces,
      amountToFund: 1000,
      fundingCurrency: 'USD',
      paypalEmail: '',
      picked_txn_view: '',
      tos_accept_time: this.tos_accept_time || false,
    }
  },
  watch: {
    picked_txn_view: function (newValue, oldValue) {
      if (oldValue != '' && newValue != oldValue) {
        this.resultsPerPage.number = 100
        this.currentPage = 0
      }
      this.getTransactions()
    },
    currentClientToken: function (newClientToken, oldClientToken) {
      console.log(`updated user ZCT to ${newClientToken}`)
      this.user.zincClientToken = newClientToken
      this.$store.commit('UPDATE_CURRENT_USER', this.user)
    },
  },
  created() {
    if (this.$route.query.limit) {
      this.resultsPerPage.number = this.$route.query.limit
    }

    if (this.$route.query.skip) {
      this.currentPage = this.$route.query.skip
    }

    if (_.has(this.$route.query, 'txn_view')) {
      this.picked_txn_view = this.$route.query.txn_view
    } else {
      this.picked_txn_view = 'all_txns'
    }

    this.getTosState()
    this.getBalance()
    // this.getTransactions()
  },
  methods: {
    async getTosState() {
      let response = await utils.apiGet('/addax/tos');
      this.tos_accept_time = response.body.tos_accept_time;
    },
    async getBalance() {
      this.isLoading = true
      let response = await utils.apiGet('/addax/balance')
      this.balances = _.merge(
        {
          USD: 0,
          GBP: 0,
          EUR: 0,
          BTC: 0,
        },
        response.body
      )
      this.isLoading = false
    },
    async getTransactions() {
      this.isLoading = true
      var url = `/addax/transactions?count=${this.resultsPerPage.number}&offset=${this.resultsPerPage.number * this.currentPage}`
      if (this.picked_txn_view == 'deposits_only') url += `&deposits_only=true`
      if (this.picked_txn_view == 'admin_adjustment') url += `&type=admin_adjustment`
      router.push({
        path: this.$route.path,
        query: {
          txn_view: this.picked_txn_view,
          limit: this.resultsPerPage.number,
          skip: this.currentPage,
        },
      })
      this.transactions = (await utils.apiGet(url)).body.data
      this.isLoading = false
    },
    getPrettyBalancePrice(balances, currencyCode) {
      var num = _.get(balances, currencyCode, 0)
      if (currencyCode == 'BTC') {
        return _.padStart((num / Math.pow(10, 8)).toFixed(8), 10)
      }
      return _.padStart((num / 100).toFixed(2), 8)
    },
    getPrettyTransactionPrice(transactionDetail) {
      var currencyCode = _.get(transactionDetail, 'currency', 'USD')
      var amount = _.get(transactionDetail, 'amount', 0)
      //console.log(amount, "isString?", _.isString(amount), "isNumber?", _.isNumber(amount))

      if (_.isNumber(amount) && amount < 0) {
        var sign = '-'
        amount = Math.abs(amount)
      } else {
        var sign = '+'
      }
      var currencyInfo = this.addaxCurrencies.find(
        addaxCurrency => addaxCurrency.code == currencyCode
      )
      var currencySymbol = _.get(currencyInfo, 'symbol', '$')
      if (currencyCode == 'BTC') {
        return `${sign} ${currencySymbol} ${(amount / Math.pow(10, 8)).toFixed(
          8
        )}`
      }
      return `${sign}   ${currencySymbol} ${_.padStart(
        (amount / 100).toFixed(2),
        8
      )}`
    },
    getColorClassName(transactionDetail) {
      var amount = _.get(transactionDetail, 'amount', 0)
      if (_.isNumber(amount) && amount < 0) {
        return 'text-danger'
      } else {
        return 'text-success'
      }
    },
    getPrettyDateFromUnix: function (unixTimestamp) {
      return moment
        .unix(unixTimestamp)
        .tz(tz)
        .format('l LTS')
    },
    refreshPage() {
      this.getBalance()
      this.getTransactions()
    },
    viewOrder: function (zincOrderId) {
      // event.preventDefault()
      this.$router.push({
        name: 'view-order',
        params: {
          zincUserId: this.user.zincUserId,
          zincOrderId: zincOrderId,
        },
      })
    },
  },
}
</script>

<style lang="css"></style>
