var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ev-admin-manage-users" }, [
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header" }, [
        _vm._v("\n        Search users\n      ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-block p-3" },
        [
          _c("spinner", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLoading,
                expression: "isLoading"
              }
            ]
          }),
          _vm._v(" "),
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row pb-3" }, [
              _c("div", { staticClass: "col-9" }, [
                _c("div", { staticClass: "form-inline mt-2 mt-md-0" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchText,
                        expression: "searchText"
                      }
                    ],
                    staticClass: "form-control mr-sm-2",
                    attrs: { placeholder: "Search" },
                    domProps: { value: _vm.searchText },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.searchText = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "ev-appnav__search btn btn-outline-success my-2 my-sm-0",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return (function() {})($event)
                        }
                      }
                    },
                    [_vm._v("Search")]
                  )
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(
                  "Search the name, email and ZincUserId across all user types\n            "
                )
              ])
            ])
          ])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header" }, [
        _vm._v("\n        Pending Users Table\n      ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-block p-3" },
        [
          _c("spinner", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLoading,
                expression: "isLoading"
              }
            ]
          }),
          _vm._v(" "),
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("table", { staticClass: "table table-striped" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    [
                      _vm._l(_vm.filteredPendingDashUsers, function(
                        pendingDashUser
                      ) {
                        return [
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.getPrettyDate(
                                    _vm.get(pendingDashUser, "created_time")
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(_vm.get(pendingDashUser, "name")))
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(_vm.get(pendingDashUser, "email")))
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(_vm.get(pendingDashUser, "id")))
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm
                                .get(pendingDashUser, "roles", [])
                                .includes("api_user_limited")
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "badge badge-pill badge-info"
                                    },
                                    [
                                      _c("v-icon", {
                                        attrs: { name: "credit-card" }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-success",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.approvePendingDashUser(
                                        _vm.get(pendingDashUser, "id")
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-check px-1",
                                    attrs: { "aria-hidden": "true" }
                                  }),
                                  _vm._v("Approve\n                        ")
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.declinePendingDashUser(
                                        _vm.get(pendingDashUser, "id")
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-times px-1",
                                    attrs: { "aria-hidden": "true" }
                                  }),
                                  _vm._v("Decline\n                        ")
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: {
                                    href:
                                      "/" +
                                      _vm.get(pendingDashUser, "id") +
                                      "/orders"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.impersonateUser(
                                        _vm.get(pendingDashUser, "id")
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-eye px-1",
                                    attrs: { "aria-hidden": "true" }
                                  }),
                                  _vm._v(
                                    "Impersonate\n                        "
                                  )
                                ]
                              )
                            ])
                          ])
                        ]
                      })
                    ],
                    2
                  )
                ])
              ])
            ])
          ])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header" }, [
        _vm._v("\n        Active Users Table\n      ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-block p-3" },
        [
          _c("spinner", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLoading,
                expression: "isLoading"
              }
            ]
          }),
          _vm._v(" "),
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("table", { staticClass: "table table-striped" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    [
                      _vm._l(_vm.filteredDashUsers, function(dashUser) {
                        return !_vm.get(dashUser, "denied", false)
                          ? [
                              _c(
                                "tr",
                                { class: _vm.getUserRowStyles(dashUser) },
                                [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getPrettyDate(
                                          _vm.get(dashUser, "created_time")
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.get(dashUser, "name")))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.get(dashUser, "email")))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.get(dashUser, "id")))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm
                                      .get(dashUser, "roles", [])
                                      .includes("api_user_limited")
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "badge badge-pill badge-info"
                                          },
                                          [
                                            _c("v-icon", {
                                              attrs: { name: "credit-card" }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href:
                                            "https://metabase.zincapi.com/dashboard/62?user_id=" +
                                            _vm.get(dashUser, "id"),
                                          target: "_blank"
                                        }
                                      },
                                      [_vm._v("Analytics")]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c("div", { staticClass: "dropdown" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-secondary dropdown-toggle",
                                          attrs: {
                                            type: "button",
                                            id: "dropdownMenuButton",
                                            "data-toggle": "dropdown",
                                            "aria-haspopup": "true",
                                            "aria-expanded": "false"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                            Actions\n                          "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "dropdown-menu",
                                          attrs: {
                                            "aria-labelledby":
                                              "dropdownMenuButton"
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "btn btn-primary btn-sm",
                                              attrs: {
                                                href:
                                                  "/" +
                                                  _vm.get(dashUser, "id") +
                                                  "/orders"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  _vm.impersonateUser(
                                                    _vm.get(dashUser, "id")
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-eye px-1",
                                                attrs: { "aria-hidden": "true" }
                                              }),
                                              _vm._v(
                                                "Impersonate\n                            "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm._m(2, true),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "btn btn-success btn-sm",
                                              attrs: {
                                                href:
                                                  "https://www.priceyak.com/admin/addax_user/" +
                                                  _vm.get(dashUser, "id"),
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-pencil px-1",
                                                attrs: { "aria-hidden": "true" }
                                              }),
                                              _vm._v(
                                                "ZMA Settings\n                            "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "btn btn-success btn-sm",
                                              attrs: {
                                                href: "#",
                                                target: "_blank"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  _vm.openFeePlanModal(
                                                    _vm.get(dashUser, "email"),
                                                    _vm.get(dashUser, "id")
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-pencil px-1",
                                                attrs: { "aria-hidden": "true" }
                                              }),
                                              _vm._v(
                                                "Edit Fee Plan\n                            "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "btn btn-success btn-sm",
                                              attrs: {
                                                href: "#",
                                                target: "_blank"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  _vm.openPrepaidCreditModal(
                                                    _vm.get(dashUser, "email"),
                                                    _vm.get(dashUser, "id")
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "Prepaid Credit User\n                            "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "btn btn-danger btn-sm",
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  _vm.declinePendingDashUser(
                                                    _vm.get(dashUser, "id"),
                                                    _vm.get(dashUser, "email")
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-times px-1",
                                                attrs: { "aria-hidden": "true" }
                                              }),
                                              _vm._v(
                                                "Deactivate user\n                            "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                ]
                              )
                            ]
                          : _vm._e()
                      })
                    ],
                    2
                  )
                ])
              ])
            ])
          ])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header" }, [
        _vm._v("\n        Declined Users Table\n      ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-block p-3" },
        [
          _c("spinner", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLoading,
                expression: "isLoading"
              }
            ]
          }),
          _vm._v(" "),
          _c("div", { staticClass: "col-10" }, [
            _c("table", { staticClass: "table table-striped" }, [
              _vm._m(3),
              _vm._v(" "),
              _c(
                "tbody",
                [
                  _vm._l(_vm.filteredDashUsers, function(dashUser) {
                    return _vm.get(dashUser, "denied", false)
                      ? [
                          _c("tr", { class: _vm.getUserRowStyles(dashUser) }, [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.getPrettyDate(
                                    _vm.get(dashUser, "created_time")
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(_vm.get(dashUser, "name")))
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(_vm.get(dashUser, "email")))
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(_vm.get(dashUser, "id")))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm
                                .get(dashUser, "roles", [])
                                .includes("api_user_limited")
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "badge badge-pill badge-info"
                                    },
                                    [
                                      _c("v-icon", {
                                        attrs: { name: "credit-card" }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: {
                                    href:
                                      "/" + _vm.get(dashUser, "id") + "/orders"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.impersonateUser(
                                        _vm.get(dashUser, "id")
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-eye px-1",
                                    attrs: { "aria-hidden": "true" }
                                  }),
                                  _vm._v("Impersonate\n                    ")
                                ]
                              ),
                              _vm._v(" "),
                              _vm._m(4, true),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-success",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.approvePendingDashUser(
                                        _vm.get(dashUser, "id")
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-check px-1",
                                    attrs: { "aria-hidden": "true" }
                                  }),
                                  _vm._v("Activate user\n                    ")
                                ]
                              )
                            ])
                          ])
                        ]
                      : _vm._e()
                  })
                ],
                2
              )
            ])
          ])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal",
        attrs: { tabindex: "-1", role: "dialog", id: "prepaidCreditModal" }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(5),
              _vm._v(" "),
              _c(
                "form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.prepaidModalSubmit($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "modal-body" }, [
                    _c("p", [
                      _vm._v("You are about to credit the user "),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.prepaidCreditModalUserEmail)
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm._m(6),
                    _vm._v(" "),
                    _vm._m(7),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.prepaidIdempotencyKey,
                          expression: "prepaidIdempotencyKey"
                        }
                      ],
                      attrs: {
                        type: "hidden",
                        id: "hiddenField",
                        name: "idempotencyKey"
                      },
                      domProps: { value: _vm.prepaidIdempotencyKey },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.prepaidIdempotencyKey = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.prepaidCreditModalUserId,
                          expression: "prepaidCreditModalUserId"
                        }
                      ],
                      attrs: {
                        type: "hidden",
                        id: "hiddenField",
                        name: "userId"
                      },
                      domProps: { value: _vm.prepaidCreditModalUserId },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.prepaidCreditModalUserId = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _vm._m(8)
                ]
              )
            ])
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade edit-fee-plan-modal",
        attrs: {
          id: "editFeePlanModal",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "editFeePlanModalLabel",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog modal-lg", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "h5",
                  {
                    staticClass: "modal-title",
                    attrs: { id: "editFeePlanModalLabel" }
                  },
                  [
                    _vm._v(
                      "Edit Fee Plan for " +
                        _vm._s(_vm.selectedFeePlanAccountEmail) +
                        "\n              (" +
                        _vm._s(_vm.selectedFeePlanAccountUserId) +
                        ") in dollars"
                    )
                  ]
                ),
                _vm._v(" "),
                _vm._m(9)
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _vm.updateFeePlanSuccess
                  ? _c(
                      "div",
                      {
                        staticClass: "alert alert-success",
                        attrs: { role: "alert" }
                      },
                      [
                        _vm._v(
                          "\n              Fee Plan updated successfully\n              "
                        ),
                        _vm._m(10)
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("form", [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "orderFeeInput" } }, [
                      _vm._v("Order Fee")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedFeePlan.order_fee,
                          expression: "selectedFeePlan.order_fee"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        id: "orderFeeInput",
                        step: "0.00000001",
                        min: "0"
                      },
                      domProps: { value: _vm.selectedFeePlan.order_fee },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.selectedFeePlan,
                            "order_fee",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "bludgeonOrderFeeInput" } }, [
                      _vm._v("Bolt Order Fee")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedFeePlan.bludgeon_order_fee,
                          expression: "selectedFeePlan.bludgeon_order_fee"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        id: "bludgeonOrderFeeInput",
                        step: "0.00000001",
                        min: "0"
                      },
                      domProps: {
                        value: _vm.selectedFeePlan.bludgeon_order_fee
                      },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.selectedFeePlan,
                            "bludgeon_order_fee",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "minFeeInput" } }, [
                      _vm._v("Min Fee")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedFeePlan.min_fee,
                          expression: "selectedFeePlan.min_fee"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        id: "minFeeInput",
                        step: "0.00000001",
                        min: "0"
                      },
                      domProps: { value: _vm.selectedFeePlan.min_fee },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.selectedFeePlan,
                            "min_fee",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "forceMinFee" } }, [
                      _vm._v("Require minimum fee even with no usage")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedFeePlan.require_minimum,
                          expression: "selectedFeePlan.require_minimum"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "checkbox", id: "requireMinFee" },
                      domProps: {
                        checked: Array.isArray(
                          _vm.selectedFeePlan.require_minimum
                        )
                          ? _vm._i(_vm.selectedFeePlan.require_minimum, null) >
                            -1
                          : _vm.selectedFeePlan.require_minimum
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.selectedFeePlan.require_minimum,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.selectedFeePlan,
                                  "require_minimum",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.selectedFeePlan,
                                  "require_minimum",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.selectedFeePlan,
                              "require_minimum",
                              $$c
                            )
                          }
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "zigguratDefaultInput" } }, [
                      _vm._v("Default Data APIs Fee")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedFeePlan.ziggurat_fee_default,
                          expression: "selectedFeePlan.ziggurat_fee_default"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        id: "zigguratDefaultInput",
                        step: "0.00000001",
                        min: "0"
                      },
                      domProps: {
                        value: _vm.selectedFeePlan.ziggurat_fee_default
                      },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.selectedFeePlan,
                            "ziggurat_fee_default",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "productOffersInput" } }, [
                      _vm._v("Product Offers Override Fee")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedFeePlan.ziggurat_fee_offers,
                          expression: "selectedFeePlan.ziggurat_fee_offers"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        id: "productOffersInput",
                        step: "0.00000001"
                      },
                      domProps: {
                        value: _vm.selectedFeePlan.ziggurat_fee_offers
                      },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.selectedFeePlan,
                            "ziggurat_fee_offers",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "productDetailsInput" } }, [
                      _vm._v("Product Details Override Fee")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedFeePlan.ziggurat_fee_details,
                          expression: "selectedFeePlan.ziggurat_fee_details"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        id: "productDetailsInput",
                        step: "0.00000001"
                      },
                      domProps: {
                        value: _vm.selectedFeePlan.ziggurat_fee_details
                      },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.selectedFeePlan,
                            "ziggurat_fee_details",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "productSearchInput" } }, [
                      _vm._v("Product Search Override Fee")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedFeePlan.ziggurat_fee_search,
                          expression: "selectedFeePlan.ziggurat_fee_search"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        id: "productSearchInput",
                        step: "0.00000001"
                      },
                      domProps: {
                        value: _vm.selectedFeePlan.ziggurat_fee_search
                      },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.selectedFeePlan,
                            "ziggurat_fee_search",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "wishlistsInput" } }, [
                      _vm._v("Wishlist Override Fee")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedFeePlan.ziggurat_fee_wishlists,
                          expression: "selectedFeePlan.ziggurat_fee_wishlists"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        id: "wishlistsInput",
                        step: "0.00000001"
                      },
                      domProps: {
                        value: _vm.selectedFeePlan.ziggurat_fee_wishlists
                      },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.selectedFeePlan,
                            "ziggurat_fee_wishlists",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "autoCharge" } }, [
                      _vm._v(
                        "Should the user be auto charged when prepaid API balance is low?"
                      )
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedFeePlan.auto_charge,
                          expression: "selectedFeePlan.auto_charge"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "checkbox", id: "autoCharge" },
                      domProps: {
                        checked: Array.isArray(_vm.selectedFeePlan.auto_charge)
                          ? _vm._i(_vm.selectedFeePlan.auto_charge, null) > -1
                          : _vm.selectedFeePlan.auto_charge
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.selectedFeePlan.auto_charge,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.selectedFeePlan,
                                  "auto_charge",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.selectedFeePlan,
                                  "auto_charge",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.selectedFeePlan, "auto_charge", $$c)
                          }
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      { attrs: { for: "zigguratScrapesPerSecondInput" } },
                      [
                        _vm._v(
                          "Amount in cents to auto charge when balance is low."
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedFeePlan.auto_charge_amount,
                          expression: "selectedFeePlan.auto_charge_amount"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        id: "zigguratScrapesPerSecondInput",
                        step: "1"
                      },
                      domProps: {
                        value: _vm.selectedFeePlan.auto_charge_amount
                      },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.selectedFeePlan,
                            "auto_charge_amount",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      { attrs: { for: "zigguratScrapesPerSecondInput" } },
                      [_vm._v("Ziggurat Scrapes per Minute")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.selectedFeePlan.ziggurat_scrapes_per_minute,
                          expression:
                            "selectedFeePlan.ziggurat_scrapes_per_minute"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        id: "zigguratScrapesPerSecondInput",
                        step: "1"
                      },
                      domProps: {
                        value: _vm.selectedFeePlan.ziggurat_scrapes_per_minute
                      },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.selectedFeePlan,
                            "ziggurat_scrapes_per_minute",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(11),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedFeePlan.extra_line_items_display,
                          expression: "selectedFeePlan.extra_line_items_display"
                        }
                      ],
                      staticClass: "form-control",
                      staticStyle: { "white-space": "pre" },
                      attrs: {
                        type: "text",
                        id: "extraLinesInput",
                        placeholder:
                          "Placeholder Description,100\nPlaceholder Description 2,50"
                      },
                      domProps: {
                        value: _vm.selectedFeePlan.extra_line_items_display
                      },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.selectedFeePlan,
                            "extra_line_items_display",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "notesInput" } }, [
                      _vm._v("Notes")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedFeePlan.notes,
                          expression: "selectedFeePlan.notes"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "notesInput" },
                      domProps: { value: _vm.selectedFeePlan.notes },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.selectedFeePlan,
                            "notes",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.updateSelectedFeePlan(
                            _vm.selectedFeePlanAccountUserId
                          )
                        }
                      }
                    },
                    [_vm._v("Update\n                Fee Plan")]
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._m(12)
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Created Time")]),
        _vm._v(" "),
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Email")]),
        _vm._v(" "),
        _c("th", [_vm._v("Zinc User ID")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "1%" } }),
        _vm._v(" "),
        _c("th", [_vm._v("Actions")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Created Time")]),
        _vm._v(" "),
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Email")]),
        _vm._v(" "),
        _c("th", [_vm._v("Zinc User ID")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "1%" } }),
        _vm._v(" "),
        _c("th", [_vm._v(" ")]),
        _vm._v(" "),
        _c("th", [_vm._v("Actions")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn-success btn-sm",
        attrs: { href: "https://www.priceyak.com/admin", target: "_blank" }
      },
      [
        _c("i", {
          staticClass: "fa fa-plus px-1",
          attrs: { "aria-hidden": "true" }
        }),
        _vm._v("Add ZMA balance\n                            ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Created Time")]),
        _vm._v(" "),
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Email")]),
        _vm._v(" "),
        _c("th", [_vm._v("Zinc User ID")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "1%" } }),
        _vm._v(" "),
        _c("th", [_vm._v("Actions")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn-success",
        attrs: { href: "https://www.priceyak.com/admin", target: "_blank" }
      },
      [
        _c("i", {
          staticClass: "fa fa-plus px-1",
          attrs: { "aria-hidden": "true" }
        }),
        _vm._v("Add ZMA balance\n                    ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("Prepaid Credit User")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "prepaidAmount" } }, [
        _vm._v("Amount (in dollars):")
      ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: {
          name: "amount",
          type: "text",
          id: "prepaidAmount",
          pattern: "^-?\\d*(\\.\\d{0,2})?$",
          required: ""
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "invalid-feedback" }, [
        _vm._v("Please enter a valid number with up to 2 decimal places.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "prepaidDescription" } }, [
        _vm._v("Description as shown to the user:")
      ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: {
          name: "description",
          id: "prepaidDescription",
          rows: "4",
          value: "Admin Credit",
          required: ""
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "invalid-feedback" }, [
        _vm._v("Description is required.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-secondary",
          attrs: { type: "button", "data-dismiss": "modal" }
        },
        [_vm._v("Cancel")]
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("Credit")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "alert",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "extraLinesInput" } }, [
      _vm._v("Extra Line Items (one per line in format\n                  "),
      _c("code", [_vm._v("description,amount")]),
      _vm._v(")")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-secondary",
          attrs: { type: "button", "data-dismiss": "modal" }
        },
        [_vm._v("Close")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }