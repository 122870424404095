import { render, staticRenderFns } from "./ViewOrder.vue?vue&type=template&id=70c2d283&scoped=true&"
import script from "./ViewOrder.vue?vue&type=script&lang=js&"
export * from "./ViewOrder.vue?vue&type=script&lang=js&"
import style0 from "./ViewOrder.vue?vue&type=style&index=0&id=70c2d283&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70c2d283",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/zincadm/zinc-api-dash/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('70c2d283', component.options)
    } else {
      api.reload('70c2d283', component.options)
    }
    module.hot.accept("./ViewOrder.vue?vue&type=template&id=70c2d283&scoped=true&", function () {
      api.rerender('70c2d283', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "client/components/view-order/ViewOrder.vue"
export default component.exports