<template>
  <div>
    <Card v-if="!this.isEmpty(this.data.buyApiData)" title="Orders Per Day" class="ordersChartCard">
      <div class="ordersChartContainer chartContainer">
        <canvas id="ordersChart" />
      </div>
    </Card>
    <Card v-if="!this.isEmpty(this.data.zigguratData)" title="Offers Retrieved Per Day" class="offersChartCard">
      <div class="offersChartContainer chartContainer">
        <canvas id="offersChart" />
      </div>
    </Card>
    <Card v-if="!this.isEmpty(this.data.zigguratData)" title="Details Retrieved Per Day" class="detailsChartCard">
      <div class="detailsChartContainer chartContainer">
        <canvas id="detailsChart" />
      </div>
    </Card>
    <Card v-if="!this.isEmpty(this.data.zigguratData)" title="Searches Retrieved Per Day" class="searchesChartCard">
      <div class="searchesChartContainer chartContainer">
        <canvas id="searchesChart" />
      </div>
    </Card>
    <Card v-if="!this.isEmpty(this.data.zigguratData)" title="Wishlists Retrieved Per Day" class="wishlistsChartCard">
      <div class="wishlistsChartContainer chartContainer">
        <canvas id="wishlistsChart" />
      </div>
    </Card>
  </div>
</template>

<script>
import Card from '../common/Card.vue'
import Chart from 'chart.js'

const DEFAULT_CHART_OPTIONS = {
  tooltips: { mode: 'x' },
  hover: { intersect: false },
  maintainAspectRatio: false,
  scales: {
    yAxes: [{ id: 'numOrders', type: 'linear', ticks: { min: 0 } }],
    xAxes: [{ gridLines: { display: false } }],
  },
}

export default {
  name: 'UsageGraph',
  components: { Card },
  props: {
    data: {
      type: Object,
      default: {
        date: moment(),
        zigguratData: { grandTotals: { offers: 0, details: 0, wishlists: 0 } },
        buyApiData: { grandTotalAutoOrders: 0, grandTotalBludgeonOrders: 0 },
      },
      required: true,
    },
  },
  mounted() {
    if (!_.isEmpty(this.data.buyApiData)) {
      let ordersDataset = this.makeOrdersChartDataset(this.data.buyApiData)
      let ordersCtx = document.getElementById('ordersChart')
      let ordersChart = new Chart(ordersCtx, {
        type: 'line',
        data: {
          labels: [...Array(ordersDataset[0].data.length).keys()].map(
            x => x + 1
          ), // [1, 2, 3 ... daysInMonth]: array index + 1 = date
          datasets: ordersDataset,
        },
        options: DEFAULT_CHART_OPTIONS,
      })
    }

    if (!_.isEmpty(this.data.zigguratData)) {
      try {
        let [
          offersDataset,
          detailsDataset,
          searchesDataset,
          wishlistsDataset,
        ] = this.makeZigguratChartsDatasets(this.data.zigguratData)
        let offersCtx = document.getElementById('offersChart')
        let offersChart = new Chart(offersCtx, {
          type: 'line',
          data: {
            labels: [...Array(offersDataset.data.length).keys()].map(
              x => x + 1
            ), // [1, 2, 3 ... daysInMonth]: array index + 1 = date
            datasets: [offersDataset],
          },
          options: DEFAULT_CHART_OPTIONS,
        })

        let detailsCtx = document.getElementById('detailsChart')
        let detailsChart = new Chart(detailsCtx, {
          type: 'line',
          data: {
            labels: [...Array(detailsDataset.data.length).keys()].map(
              x => x + 1
            ), // [1, 2, 3 ... daysInMonth]: array index + 1 = date
            datasets: [detailsDataset],
          },
          options: DEFAULT_CHART_OPTIONS,
        })

        let searchesCtx = document.getElementById('searchesChart')
        let searchesChart = new Chart(searchesCtx, {
          type: 'line',
          data: {
            labels: [...Array(searchesDataset.data.length).keys()].map(
              x => x + 1
            ), // [1, 2, 3 ... daysInMonth]: array index + 1 = date
            datasets: [searchesDataset],
          },
          options: DEFAULT_CHART_OPTIONS,
        })

        let wishlistsCtx = document.getElementById('wishlistsChart')
        let wishlistsChart = new Chart(wishlistsCtx, {
          type: 'line',
          data: {
            labels: [...Array(wishlistsDataset.data.length).keys()].map(
              x => x + 1
            ), // [1, 2, 3 ... daysInMonth]: array index + 1 = date
            datasets: [wishlistsDataset],
          },
          options: DEFAULT_CHART_OPTIONS,
        })

      } catch (e) {
        console.error('Failed to make Ziggurat graph ' + e)
      }
    }
  },

  methods: {
    get: _.get,
    isEmpty: _.isEmpty,
    makeOrdersChartDataset(buyApiData) {
      let datasets = {} // should be dimension: int[# days in month]
      Object.entries(buyApiData).forEach(([date, buckets]) => {
        if (date.startsWith("grandTotal")) return;
        if (!(buckets instanceof Array)) return;
        let bucketDate = moment.utc(date);
        buckets.map(bucket => {
          if (!datasets[bucket.dimension]) {
            datasets[bucket.dimension] = new Array(
              bucketDate.daysInMonth()
            ).fill(0)
          }
          datasets[bucket.dimension][bucketDate.date() - 1] = bucket.count // date - 1 = array index
        })
      })
      let graphableDataset = []
      Object.entries(datasets).forEach(([dimension, datedArray], index) => {
        graphableDataset.push({
          label: dimension,
          data: datedArray,
          backgroundColor: colorPairs[index][0],
          borderColor: colorPairs[index][1],
          lineTension: '0',
        })
      })
      return graphableDataset
    },

    makeZigguratChartsDatasets(zigguratData) {
      let offersDataset = Array(this.data.date.daysInMonth()).fill(0),
        detailsDataset = Array(this.data.date.daysInMonth()).fill(0),
        searchesDataset = Array(this.data.date.daysInMonth()).fill(0),
        wishlistsDataset = Array(this.data.date.daysInMonth()).fill(0)

      Object.entries(zigguratData).forEach(([date, data]) => {
        if (date.startsWith("grandTotal")) return;
        let dataDate = moment.utc(date);
        Object.entries(data.sums).forEach(([dimension, obj]) => {
          if (dimension == 'offers') {
            offersDataset[dataDate.date() - 1] = obj.count
          } else if (dimension == 'details') {
            detailsDataset[dataDate.date() - 1] = obj.count
          } else if (dimension == 'search') {
            searchesDataset[dataDate.date() - 1] = obj.count
          } else if (dimension == 'wishlists') {
            wishlistsDataset[dataDate.date() - 1] = obj.count
          }
        })
      })

      let offersGraphable = {
        label: 'Offers',
        data: offersDataset,
        backgroundColor: colorPairs[4][0],
        borderColor: colorPairs[4][1],
        lineTension: '0',
      }

      let detailsGraphable = {
        label: 'Details',
        data: detailsDataset,
        backgroundColor: colorPairs[3][0],
        borderColor: colorPairs[3][1],
        lineTension: '0',
      }

      let searchesGraphable = {
        label: 'Searches',
        data: searchesDataset,
        backgroundColor: colorPairs[2][0],
        borderColor: colorPairs[2][1],
        lineTension: '0',
      }

      let wishlistsGraphable = {
        label: 'Wishslists',
        data: wishlistsDataset,
        backgroundColor: colorPairs[4][0],
        borderColor: colorPairs[4][1],
        lineTension: '0',
      }

      return [offersGraphable, detailsGraphable, searchesGraphable, wishlistsGraphable]
    },
  },
}

const colorPairs = [
  ['rgba(26, 188, 156,.4)', 'rgba(26, 188, 156,1.0)'],
  ['rgba(241, 196, 15,.4)', 'rgba(241, 196, 15,1.0)'],
  ['rgba(52, 152, 219,.4)', 'rgba(52, 152, 219,1.0)'],
  ['rgba(155, 89, 182,.4)', 'rgba(155, 89, 182,1.0)'],
  ['rgba(231, 76, 60,.4)', 'rgba(231, 76, 60,1.0)'],
]
</script>

<style lang="scss" scoped>
.chartContainer {
  position: relative;
  height: 40vh;
  min-height: 300px;
}
#usageChart {
  width: 100%;
}
</style>
