var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card mt-0", class: _vm.cssClass }, [
    _c(
      "div",
      { staticClass: "card-body" },
      [
        _vm.title
          ? _c("h6", { staticClass: "text-muted font-weight-normal mb-3" }, [
              _vm._v(_vm._s(_vm.title))
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }