<template>


<div>
  <!-- Fixed navbar -->
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
    data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <router-link class="navbar-brand" :to="{ name: 'home', params: { zincUserId: get(user, 'zincUserId') } }"><img src="/assets/images/logo.png" class="ev-appnav__logo"> </router-link>

    <div class="collapse navbar-collapse" id="navbarCollapse">

      <!-- Logged in -->
      <template v-if="auth.isLoggedIn">

        <template v-if="isApiUserApproved()">

          <!-- My Zinc User ID is {{user.zincUserId}} -->
          <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
              <router-link class="nav-link" :to="{ name: 'orders', params: { zincUserId: user.zincUserId } }">Orders </router-link>
            </li>

            <li class="nav-item active">
              <router-link class="nav-link" :to="{ name: 'balance', params: { zincUserId: user.zincUserId } }">ZMA Balance </router-link>
            </li>

            <li class="nav-item active">
              <router-link class="nav-link" :to="{ name: 'usage', params: { zincUserId: user.zincUserId } }">Usage </router-link>
            </li>

            <li v-if="isPrepaid()" class="nav-item active">
              <router-link class="nav-link" :to="{ name: 'billing', params: { zincUserId: user.zincUserId } }">Billing </router-link>
            </li>

            <li class="nav-item active">
              <router-link class="nav-link" :to="{ name: 'coyote', params: { zincUserId: user.zincUserId } }">Coyote </router-link>
            </li>

            <li v-if="loginUser.isAdmin" class="nav-item active zn-admin">
              <router-link class="nav-link" :to="{ name: 'admin-order-finder', params: { zincUserId: loginUser.zincUserId } }">Admin </router-link>
            </li>

            <li v-if="loginUser.isAdmin" class="nav-item active zn-admin">
              <router-link class="nav-link" :to="{ name: 'admin-manage-users', params: { zincUserId: loginUser.zincUserId } }">Users </router-link>
            </li>

            <li v-if="loginUser.isAdmin" class="nav-item active zn-admin">
              <router-link class="nav-link" :to="{ name: 'admin-order-debugger', params: { zincUserId: loginUser.zincUserId } }">Order Debug </router-link>
            </li>
          </ul>

          <div class="form-inline mt-2 mt-md-0 py-1">
            <button class="ev-appnav__search btn btn-outline-success my-2 my-sm-0" data-toggle="modal" data-target="#apiCredentialsModal">API Credentials</button>
          </div>

        </template>

        <ul class="navbar-nav nav-right ev-appnav__logout">
          <li class="nav-item pr-3 py-1">
            <a :href="this.getLoginURL() + '/profile'" target="_blank">
              <button class="btn btn-outline-primary">{{loginUser.name}} &nbsp; <i class="fa fa-user"></i></button>
            </a>
            <div v-if="loginUser.zincUserId != user.zincUserId" class="zn-admin">
              <p>Impersonating {{user.name}}<br> ({{user.email}})</p>
            </div>
          </li>
          <li class="nav-item py-1">
            <a href="#" @click="logout()">
              <button class="btn btn-outline-primary">Logout</button>
            </a>
          </li>
        </ul>
      </template>

      <!-- Not logged in -->
      <template v-else>
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <router-link class="nav-link" to="login">Login <span class="sr-only">(current)</span></router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link to="signup" class="nav-link" href="#">Signup</router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://github.com/prograhammer/example-vue-project/blob/master/README.md">Tutorial</a>
          </li> -->
        </ul>
      </template>

    </div>
  </nav>

  <!-- Modal -->
  <div class="modal fade" id="apiCredentialsModal" tabindex="-1" role="dialog" aria-labelledby="apiCredentialsModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title text-center" style="flex:1" id="apiCredentialsModalLabel">Zinc API Credentials</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body text-center">
              <h5>Your Zinc API Client Token is</h5>
              <h5><mark><strong>{{user.zincClientToken}}</strong></mark></h5>
              <p>
                For detailed instructions on how to use the API, along with examples, please see our <a href="http://docs.zincapi.com" target="_blank" rel="noopener noreferrer">API docs</a>.
              </p>
              <p v-if="loginUser.isAdmin" class="zn-admin">Key for {{user.name}} - {{user.email}}</p>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
          </div>
      </div>
  </div>

</div>

</template>

<script>
import Auth from '../auth'
export default {
  data() {
    return {
      auth: this.$store.state.auth,
      loginUser: this.$store.state.loginUser,
      user: this.$store.state.currentUser,
      searchText: '',
      searchFlag: '',
    }
  },
  created() {},
  methods: {
    get: _.get,
    has: _.has,
    getLoginURL() {
      return utils.LOGIN_URL
    },
    isApiUserApproved() {
      return _.includes(this.loginUser.roles, 'api_user') || _.includes(this.loginUser.roles, 'api_user_limited')
    },
    isPrepaid() {
      return _.includes(this.user.roles, 'api_prepaid')
    },
    logout() {
      Auth.logout()
    },
    submitSearch() {
      this.$store.commit('APPNAV_SEARCH', {
        searchText: this.searchText,
        searchTimestamp: Date.now(),
      })
    },
  },
}
</script>

<style lang="scss" scoped>
/*
  You can use BEM style even though you are scoped. Helps to reason about
  your styles.
*/
.ev-appnav__logo {
  width: 40px;
  filter: brightness(0%);
}
.ev-appnav__search {
  margin-right: 10px;
}
.ev-appnav__logout {
  margin-top: 1px;
}
</style>
