export const UPDATE_AUTH = (state, auth) => {
  state.auth = auth
}

export const UPDATE_LOGIN_USER = (state, user) => {
  state.loginUser = user
}

export const UPDATE_CURRENT_USER = (state, user) => {
  state.currentUser = user
}

export const APPNAV_SEARCH = (state, searchData) => {
  state.appnav = searchData
}

/**
 * Clear each property, one by one, so reactivity still works.
 *
 * (ie. clear out state.auth.isLoggedIn so Navbar component automatically reacts to logged out state,
 * and the Navbar menu adjusts accordingly)
 *
 * TODO: use a common import of default state to reset these values with.
 */
export const CLEAR_ALL_DATA = state => {
  // Auth
  state.auth.isLoggedIn = false
  state.auth.accessToken = ''

  // User
  state.loginUser.name = ''
  state.loginUser.email = ''
  state.loginUser.zincClientToken = ''
  state.loginUser.zincUserId = ''
  state.loginUser.isAdmin = ''
  state.loginUser.roles = {}

  // Current User
  state.currentUser.name = ''
  state.currentUser.email = ''
  state.currentUser.zincClientToken = ''
  state.currentUser.zincUserId = ''
  state.currentUser.isAdmin = ''
  state.currentUser.roles = {}
}
