var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Card", { attrs: { title: "Billing Preview" } }, [
    _c("table", { staticClass: "table" }, [
      _c("thead", { staticClass: "text-muted" }, [
        _c("tr", [
          _c("th"),
          _vm._v(" "),
          _c("th", [_vm._v("Usage")]),
          _vm._v(" "),
          _c("th", [_vm._v("Price")]),
          _vm._v(" "),
          _c("th", [_vm._v("Subtotal")])
        ])
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.billingLines, function(line) {
          return _c("tr", { staticClass: "m-3" }, [
            _c("th", [_vm._v(_vm._s(line.description))]),
            _vm._v(" "),
            _c("th", [_vm._v(_vm._s(line.usage))]),
            _vm._v(" "),
            _c("th", [
              _vm._v(
                _vm._s(_vm.getPrettyPrice("$", line.price)) +
                  " " +
                  _vm._s(line.unit)
              )
            ]),
            _vm._v(" "),
            _c("th", [_vm._v(_vm._s(_vm.getPrettyPrice("$", line.subtotal)))])
          ])
        })
      )
    ]),
    _vm._v(" "),
    _c("small", { staticClass: "text-muted" }, [
      _vm._v(
        "This is a preview and is not guaranteed to be accurate. Please refer to your invoices for accurate billing."
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }