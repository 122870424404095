<template>
  <div class="ev-admin-manage-users">

    <div class="card">
      <div class="card-header">
        Search users
      </div>
      <div class="card-block p-3">
        <spinner v-show="isLoading"></spinner>
        <div class="container-fluid">
          <div class="row pb-3">
            <div class="col-9">
              <div class="form-inline mt-2 mt-md-0">
                <input class="form-control mr-sm-2" v-model="searchText" placeholder="Search">
                <button class="ev-appnav__search btn btn-outline-success my-2 my-sm-0"
                  @click.prevent="() => { }">Search</button>
              </div>
              <br>Search the name, email and ZincUserId across all user types
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="card">
      <div class="card-header">
        Pending Users Table
      </div>
      <div class="card-block p-3">
        <spinner v-show="isLoading"></spinner>
        <div class="container-fluid">
          <!-- <div class="row pb-3">
                    <div class="col-9">
                        <div class="form-inline mt-2 mt-md-0">
                            <input class="form-control mr-sm-2" v-model="searchText" placeholder="Search">
                            <button class="ev-appnav__search btn btn-outline-success my-2 my-sm-0" @click.prevent="() => {}">Search</button>
                        </div>
                    </div>
                </div> -->
          <div class="row">
            <div class="col-12">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Created Time</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Zinc User ID</th>
                    <th style="width: 1%"></th> <!-- badges -->
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="pendingDashUser in filteredPendingDashUsers">
                    <tr>
                      <td>{{ getPrettyDate(get(pendingDashUser, 'created_time')) }}</td>
                      <td>{{ get(pendingDashUser, 'name') }}</td>
                      <td>{{ get(pendingDashUser, 'email') }}</td>
                      <td>{{ get(pendingDashUser, 'id') }}</td>
                      <td><span class="badge badge-pill badge-info"
                          v-if="get(pendingDashUser, 'roles', []).includes('api_user_limited')"><v-icon
                            name="credit-card" /></span></td>
                      <td>
                        <button type="button" class="btn btn-success"
                          @click.prevent="approvePendingDashUser(get(pendingDashUser, 'id'))">
                          <i class="fa fa-check px-1" aria-hidden="true"></i>Approve
                        </button>
                        <button type="button" class="btn btn-danger"
                          @click.prevent="declinePendingDashUser(get(pendingDashUser, 'id'))">
                          <i class="fa fa-times px-1" aria-hidden="true"></i>Decline
                        </button>
                        <a :href="`/${get(pendingDashUser, 'id')}/orders`" class="btn btn-primary"
                          @click.prevent="impersonateUser(get(pendingDashUser, 'id'))">
                          <i class="fa fa-eye px-1" aria-hidden="true"></i>Impersonate
                        </a>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>

        </div>
        <!-- <div class="form-inline mt-2 mt-md-0 px-3">
                <button class="btn btn-primary" @click="getTransactions()">Get transactions</button>
            </div> -->
        <!-- <pre>
                {{pendingDashUsers}}
            </pre> -->
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        Active Users Table
      </div>
      <div class="card-block p-3">
        <spinner v-show="isLoading"></spinner>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Created Time</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Zinc User ID</th>
                    <th style="width: 1%"></th> <!-- badges -->
                    <th>&nbsp;</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="dashUser in filteredDashUsers" v-if="!get(dashUser, 'denied', false)">
                    <tr :class="getUserRowStyles(dashUser)">
                      <td>{{ getPrettyDate(get(dashUser, 'created_time')) }}</td>
                      <td>{{ get(dashUser, 'name') }}</td>
                      <td>{{ get(dashUser, 'email') }}</td>
                      <td>{{ get(dashUser, 'id') }}</td>
                      <td><span class="badge badge-pill badge-info"
                          v-if="get(dashUser, 'roles', []).includes('api_user_limited')"><v-icon
                            name="credit-card" /></span></td>
                      <td><a :href="`https://metabase.zincapi.com/dashboard/62?user_id=${get(dashUser, 'id')}`"
                          target='_blank'>Analytics</a></td>
                      <td>
                        <div class="dropdown">
                          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Actions
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a :href="`/${get(dashUser, 'id')}/orders`" class="btn btn-primary btn-sm"
                              @click.prevent="impersonateUser(get(dashUser, 'id'))">
                              <i class="fa fa-eye px-1" aria-hidden="true"></i>Impersonate
                            </a>
                            <a href="https://www.priceyak.com/admin" class="btn btn-success btn-sm" target="_blank">
                              <i class="fa fa-plus px-1" aria-hidden="true"></i>Add ZMA balance
                            </a>
                            <a :href="`https://www.priceyak.com/admin/addax_user/${get(dashUser, 'id')}`"
                              class="btn btn-success btn-sm" target="_blank">
                              <i class="fa fa-pencil px-1" aria-hidden="true"></i>ZMA Settings
                            </a>
                            <a href="#" @click.prevent="openFeePlanModal(get(dashUser, 'email'), get(dashUser, 'id'))"
                              class="btn btn-success btn-sm" target="_blank">
                              <i class="fa fa-pencil px-1" aria-hidden="true"></i>Edit Fee Plan
                            </a>
                            <a href="#"
                              @click.prevent="openPrepaidCreditModal(get(dashUser, 'email'), get(dashUser, 'id'))"
                              class="btn btn-success btn-sm" target="_blank">Prepaid Credit User
                            </a>
                            <a class="btn btn-danger btn-sm"
                              @click.prevent="declinePendingDashUser(get(dashUser, 'id'), get(dashUser, 'email'))">
                              <i class="fa fa-times px-1" aria-hidden="true"></i>Deactivate user
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- <div class="form-inline mt-2 mt-md-0 px-3">
                <button class="btn btn-primary" @click="getTransactions()">Get transactions</button>
            </div> -->
        <!-- <pre>
                {{dashUsers}}
            </pre> -->
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        Declined Users Table
      </div>
      <div class="card-block p-3">
        <spinner v-show="isLoading"></spinner>

        <div class="col-10">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Created Time</th>
                <th>Name</th>
                <th>Email</th>
                <th>Zinc User ID</th>
                <th style="width: 1%"></th> <!-- badges -->
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="dashUser in filteredDashUsers" v-if="get(dashUser, 'denied', false)">
                <tr :class="getUserRowStyles(dashUser)">
                  <td>{{ getPrettyDate(get(dashUser, 'created_time')) }}</td>
                  <td>{{ get(dashUser, 'name') }}</td>
                  <td>{{ get(dashUser, 'email') }}</td>
                  <td>{{ get(dashUser, 'id') }}</td>
                  <td><span class="badge badge-pill badge-info"
                      v-if="get(dashUser, 'roles', []).includes('api_user_limited')"><v-icon name="credit-card" /></span>
                  </td>
                  <td>
                    <a :href="`/${get(dashUser, 'id')}/orders`" class="btn btn-primary"
                      @click.prevent="impersonateUser(get(dashUser, 'id'))">
                      <i class="fa fa-eye px-1" aria-hidden="true"></i>Impersonate
                    </a>
                    <a href="https://www.priceyak.com/admin" class="btn btn-success" target="_blank">
                      <i class="fa fa-plus px-1" aria-hidden="true"></i>Add ZMA balance
                    </a>
                    <button type="button" class="btn btn-success"
                      @click.prevent="approvePendingDashUser(get(dashUser, 'id'))">
                      <i class="fa fa-check px-1" aria-hidden="true"></i>Activate user
                    </button>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!-- <div class="form-inline mt-2 mt-md-0 px-3">
                <button class="btn btn-primary" @click="getTransactions()">Get transactions</button>
            </div> -->
        <!-- <pre>
                {{dashUsers}}
            </pre> -->
      </div>
    </div>

    <!-- Prepaid Credit User modal -->
    <div class="modal" tabindex="-1" role="dialog" id="prepaidCreditModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Prepaid Credit User</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="prepaidModalSubmit">
            <div class="modal-body">
              <p>You are about to credit the user <span v-text="prepaidCreditModalUserEmail"></span></p>
              <div class="form-group">
                <label for="prepaidAmount">Amount (in dollars):</label>
                <input name="amount" type="text" class="form-control" id="prepaidAmount" pattern="^-?\d*(\.\d{0,2})?$"
                  required>
                <div class="invalid-feedback">Please enter a valid number with up to 2 decimal places.</div>
              </div>
              <div class="form-group">
                <label for="prepaidDescription">Description as shown to the user:</label>
                <input name="description" class="form-control" id="prepaidDescription" rows="4" value="Admin Credit"
                  required>
                <div class="invalid-feedback">Description is required.</div>
              </div>
              <input type="hidden" id="hiddenField" name="idempotencyKey" v-model="prepaidIdempotencyKey">
              <input type="hidden" id="hiddenField" name="userId" v-model="prepaidCreditModalUserId">
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
              <button type="submit" class="btn btn-primary">Credit</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Fee plan modal -->
    <div class="modal fade edit-fee-plan-modal" id="editFeePlanModal" tabindex="-1" role="dialog"
      aria-labelledby="editFeePlanModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editFeePlanModalLabel">Edit Fee Plan for {{ selectedFeePlanAccountEmail }}
              ({{ selectedFeePlanAccountUserId }}) in dollars</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="updateFeePlanSuccess" class="alert alert-success" role="alert">
              Fee Plan updated successfully
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form>
              <div class="form-group">
                <label for="orderFeeInput">Order Fee</label>
                <input v-model="selectedFeePlan.order_fee" type="number" class="form-control" id="orderFeeInput"
                  step="0.00000001" min="0">
              </div>
              <div class="form-group">
                <label for="bludgeonOrderFeeInput">Bolt Order Fee</label>
                <input v-model="selectedFeePlan.bludgeon_order_fee" type="number" class="form-control"
                  id="bludgeonOrderFeeInput" step="0.00000001" min="0">
              </div>
              <div class="form-group">
                <label for="minFeeInput">Min Fee</label>
                <input v-model="selectedFeePlan.min_fee" type="number" class="form-control" id="minFeeInput"
                  step="0.00000001" min="0">
              </div>
              <div class="form-group">
                <label for="forceMinFee">Require minimum fee even with no usage</label>
                <input v-model="selectedFeePlan.require_minimum" type="checkbox" class="form-control" id="requireMinFee">
              </div>
              <div class="form-group">
                <label for="zigguratDefaultInput">Default Data APIs Fee</label>
                <input v-model="selectedFeePlan.ziggurat_fee_default" type="number" class="form-control"
                  id="zigguratDefaultInput" step="0.00000001" min="0">
              </div>
              <div class="form-group">
                <label for="productOffersInput">Product Offers Override Fee</label>
                <input v-model="selectedFeePlan.ziggurat_fee_offers" type="number" class="form-control"
                  id="productOffersInput" step="0.00000001">
              </div>
              <div class="form-group">
                <label for="productDetailsInput">Product Details Override Fee</label>
                <input v-model="selectedFeePlan.ziggurat_fee_details" type="number" class="form-control"
                  id="productDetailsInput" step="0.00000001">
              </div>
              <div class="form-group">
                <label for="productSearchInput">Product Search Override Fee</label>
                <input v-model="selectedFeePlan.ziggurat_fee_search" type="number" class="form-control"
                  id="productSearchInput" step="0.00000001">
              </div>
              <div class="form-group">
                <label for="wishlistsInput">Wishlist Override Fee</label>
                <input v-model="selectedFeePlan.ziggurat_fee_wishlists" type="number" class="form-control"
                  id="wishlistsInput" step="0.00000001">
              </div>
              <div class="form-group">
                <label for="autoCharge">Should the user be auto charged when prepaid API balance is low?</label>
                <input v-model="selectedFeePlan.auto_charge" type="checkbox" class="form-control" id="autoCharge">
              </div>
              <div class="form-group">
                <label for="zigguratScrapesPerSecondInput">Amount in cents to auto charge when balance is low.</label>
                <input v-model="selectedFeePlan.auto_charge_amount" type="number" class="form-control"
                  id="zigguratScrapesPerSecondInput" step="1">
              </div>
              <div class="form-group">
                <label for="zigguratScrapesPerSecondInput">Ziggurat Scrapes per Minute</label>
                <input v-model="selectedFeePlan.ziggurat_scrapes_per_minute" type="number" class="form-control"
                  id="zigguratScrapesPerSecondInput" step="1">
              </div>
              <div class="form-group">
                <label for="extraLinesInput">Extra Line Items (one per line in format
                  <code>description,amount</code>)</label>
                <textarea v-model="selectedFeePlan.extra_line_items_display" type="text" style="white-space: pre;"
                  class="form-control" id="extraLinesInput" placeholder="Placeholder Description,100
Placeholder Description 2,50" />
              </div>
              <div class="form-group">
                <label for="notesInput">Notes</label>
                <input v-model="selectedFeePlan.notes" type="text" class="form-control" id="notesInput">
              </div>

              <!-- <div class="form-group">
                            <label for="productFeeDivisorInput">Product Fee Divisor</label>
                            <input v-model="selectedFeePlan.ziggurat_fee_divisor" type="number" class="form-control" id="productFeeDivisorInput" aria-describedby="productFeeDivisorHelp" placeholder="0">
                            <small id="productFeeDivisorHelp" class="form-text text-muted">Product fees are divided by this divisor to get the resulting price in cents. For example, a product offers fee of 1 and a fee divisor of 10 would lead to a fee of 0.1 cents or $0.001 per call. A fee divisor of 100 would lead to a fee of 0.01 cents or $0.0001 per call.</small>
                        </div> -->
              <button class="btn btn-success" @click.prevent="updateSelectedFeePlan(selectedFeePlanAccountUserId)">Update
                Fee Plan</button>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Auth from '@/auth'
import Spinner from '../common/Spinner.vue'
import router from '@/router'
import 'vue-awesome/icons/credit-card'
import Icon from 'vue-awesome/components/Icon'
const addaxCurrencies = require('../common/addaxCurrencies.data.js')
const marketplaces = require('../common/marketplaces.data.js')
const tz = moment.tz.guess()
const baseFeePlan = {
  // account_id: 0,
  order_fee: '1',
  bludgeon_order_fee: '1',
  min_fee: '0',
  require_minimum: false,
  ziggurat_fee_default: '0.01',
  notes: '',
}

const feePlanNumericKeys = [
  'order_fee',
  'bludgeon_order_fee',
  'min_fee',
  'ziggurat_fee_default',
  'ziggurat_fee_offers',
  'ziggurat_fee_details',
  'ziggurat_fee_search',
  'ziggurat_fee_wishlists',
  'ziggurat_scrapes_per_minute',
]

export default {
  name: 'admin-manage-users',
  components: { Spinner, 'v-icon': Icon },
  props: ['limit', 'skip', 'q'],
  computed: {
    filteredDashUsers() {
      if (this.searchText) {
        router.push({ path: this.$route.path, query: { q: this.searchText } })
      }
      return this.dashUsers.filter(dashUser => {
        if (this.searchText) {
          let testDashUser = _.omitBy(dashUser, _.isNil)
          return (
            _.get(testDashUser, 'email', '')
              .toLowerCase()
              .includes(this.searchText.toLowerCase()) ||
            _.get(testDashUser, 'name', '')
              .toLowerCase()
              .includes(this.searchText.toLowerCase()) ||
            _.get(testDashUser, 'id', '')
              .toLowerCase()
              .includes(this.searchText.toLowerCase())
          )
        } else {
          return true
        }
      })
    },
    filteredPendingDashUsers() {
      return this.pendingDashUsers.filter(dashUser => {
        if (this.searchText) {
          let testDashUser = _.omitBy(dashUser, _.isNil)
          return (
            _.get(testDashUser, 'email', '')
              .toLowerCase()
              .includes(this.searchText.toLowerCase()) ||
            _.get(testDashUser, 'name', '')
              .toLowerCase()
              .includes(this.searchText.toLowerCase()) ||
            _.get(testDashUser, 'id', '')
              .toLowerCase()
              .includes(this.searchText.toLowerCase())
          )
        } else {
          return true
        }
      })
    },
  },
  data() {
    return {
      auth: this.$store.state.auth,
      user: this.$store.state.currentUser,
      currentClientToken: this.$store.state.currentUser.zincClientToken,
      isLoading: false,
      dashUsers: [],
      pendingDashUsers: [],
      searchText: '',
      editFeePlanModal: $('#editFeePlanModal'),
      selectedFeePlan: baseFeePlan,
      selectedFeePlanAccountEmail: '',
      selectedFeePlanAccountUserId: '',
      prepaidCreditModalUserId: null,
      prepaidCreditModalUserEmail: null,
      prepaidIdempotencyKey: null,
      jq: $,
      showModal: false,
      updateFeePlanSuccess: false,
    }
  },
  watch: {
    // whenever question changes, this function will run
    currentClientToken: function (newClientToken, oldClientToken) {
      // console.log(`updated user ZCT to ${newClientToken}`)
      this.user.zincClientToken = newClientToken
      this.$store.commit('UPDATE_CURRENT_USER', this.user)
    },
  },
  created() {
    if (this.$route.query.q) {
      this.searchText = decodeURIComponent(this.$route.query.q)
    } else {
      this.searchText = ''
    }
    this.refreshPage()
  },
  mounted() { },
  methods: {
    approvePendingDashUser: async function (zincUserId) {
      var headers = {
        Authorization: `Bearer ${this.auth.accessToken}`,
      }
      const url = `${utils.LOGIN_URL}/apidash/users/${zincUserId}/approve`
      try {
        await this.$http.post(url, {}, { headers: headers, withCredentials: true })
        alert(`Account ${zincUserId} successfully activated`);
        this.refreshPage()
      } catch (err) {
        alert('Failed to activate, response:\n' + JSON.stringify(err, null, 4))
      }
    },
    declinePendingDashUser: async function (zincUserId, email) {
      var headers = {
        Authorization: `Bearer ${this.auth.accessToken}`,
      }
      try {
        const url = `${utils.LOGIN_URL}/apidash/users/${zincUserId}/decline`
        await this.$http.post(url, {}, { headers: headers, withCredentials: true })
        alert(`Account ${zincUserId} successfully deactivated`)
        this.refreshPage()
      } catch (err) {
        alert('Failed to deactivate, response:\n' + JSON.stringify(err, null, 4))
      }
    },
    get: _.get,
    getDashUsers: function () {
      this.isLoading = true
      var headers = {
        Authorization: `Bearer ${this.auth.accessToken}`,
      }
      this.$http
        .get(`${utils.LOGIN_URL}/apidash/users`, {
          headers: headers,
        })
        .then(response => {
          this.isLoading = false
          // console.log(response)
          this.dashUsers = response.body
        })
        .catch(response => {
          this.isLoading = false
          this.error = utils.getError(response)
        })
    },
    getPendingDashUsers: function () {
      this.isLoading = true
      var headers = {
        Authorization: `Bearer ${this.auth.accessToken}`,
      }
      this.$http
        .get(`${utils.LOGIN_URL}/apidash/zincapi/pending`, {
          headers: headers,
        })
        .then(response => {
          this.isLoading = false
          // console.log(response)
          this.pendingDashUsers = response.body
        })
        .catch(response => {
          this.isLoading = false
          this.error = utils.getError(response)
        })
    },
    getPrettyDate: function (UTCDate) {
      return moment(UTCDate)
        .tz(tz)
        .format('l LTS')
    },
    getUserRowStyles: function (user) {
      return {
        'table-danger': _.get(user, 'denied', false),
      }
    },
    impersonateUser: function (zincUserId) {
      this.$router.push({
        name: 'orders',
        params: {
          zincUserId: zincUserId,
        },
      })
    },
    includes: _.includes,
    refreshPage: function () {
      this.getDashUsers()
      this.getPendingDashUsers()
    },
    openFeePlanModal: function (accountEmail, accountUserId) {
      this.getFeePlan(accountEmail, accountUserId)
      $('#editFeePlanModal').modal({ show: true })
    },
    openPrepaidCreditModal: function (accountEmail, accountUserId) {
      this.prepaidCreditModalUserId = accountUserId;
      this.prepaidCreditModalUserEmail = accountEmail;
      this.prepaidIdempotencyKey = new Date().toISOString()
      $('#prepaidCreditModal').modal({ show: true })
    },
    prepaidModalSubmit: async function (event) {
      const obj = Object.fromEntries(new FormData(event.target).entries())
      let url = `/api/v0/prepaidCreditUser/${obj.userId}`
      let res = await fetch(url, { method: 'POST', body: JSON.stringify(obj), headers: { 'Content-Type': 'application/json' } })
      if(res.status !== 200) {
        alert('Failed to credit user, response:\n' + JSON.stringify(res, null, 4))
      } else {
        alert(`Successfully credited user ${obj.userId} with $${obj.amount}`)
        $('#prepaidCreditModal').modal('hide')
      }
    },
    getFeePlan: function (accountEmail, accountUserId) {
      /**
       * New algorithm
       *
       * First try to get from login ... if you get 404, use base plan
       *
       */
      this.isLoading = true
      var getFeePlanFromLoginPromise = this.$http.get(
        `/api/v0/feePlan/${accountUserId}`
      )

      return getFeePlanFromLoginPromise
        .then(response => {
          if (_.has(response, 'body.error')) {
            console.error(response)
            console.log('using baseFeePlan')
            console.log(baseFeePlan)
            this.selectedFeePlan = baseFeePlan
          } else {
            console.log('using found plan')
            this.selectedFeePlan = _.get(response, 'body')
          }
          this.selectedFeePlanAccountEmail = accountEmail
          this.selectedFeePlanAccountUserId = accountUserId
          this.isLoading = false
        })
        .catch(response => {
          console.log('failed entirely')
          this.selectedFeePlanAccountEmail = ''
          this.selectedFeePlanAccountUserId = ''
          this.selectedFeePlan = baseFeePlan
          this.isLoading = false
          this.error = utils.getError(response)
        })
      // .finally(() => {
      //   if (this.selectedFeePlan.extra_line_items) {
      //     this.selectedFeePlan.extra_line_items_display =
      //       this.selectedFeePlan.extra_line_items.map(e => `${e.description},${e.amount}`).join('\n')
      //   }
      // })
    },
    updateSelectedFeePlan: function (accountUserId) {
      try {
        if (this.selectedFeePlan.extra_line_items_display) {
          this.selectedFeePlan.extra_line_items = this.selectedFeePlan.extra_line_items_display
            .split('\n')
            .map(e => {
              e = e.split(',')
              let amount = parseInt(e.slice(-1))
              if (isNaN(amount)) {
                alert(
                  'Failed update fee plan response:\nCheck extra line items\n' +
                  e
                )
                throw Error
              }
              return { description: e.slice(0, -1).join(), amount } // slicing to account for random commas in desc
            })
        } else {
          this.selectedFeePlan.extra_line_items = []
        }
      } catch (err) {
        return
      }
      this.$http
        .post(`/api/v0/feePlan/${accountUserId}`, this.selectedFeePlan, {})
        .then(response => {
          if (_.has(response, 'body.errors')) {
            alert(
              'Failed update fee plan response:\n' +
              JSON.stringify(response, null, 3)
            )
          } else {
            this.updateFeePlanSuccess = true
            setTimeout(() => {
              this.updateFeePlanSuccess = false
            }, 10000)
          }
          console.log(
            'Successful update fee plan response:\n' +
            JSON.stringify(response.body, null, 3)
          )
          //this.editFeePlanModal.modal({show: false})
          //this.refreshPage()
        })
        .catch(response => {
          alert(
            'Failed update fee plan response:\n' +
            JSON.stringify(response, null, 3)
          )
        })
    },
  },
}
</script>

<style lang="css"></style>
