<template>
<div class="ev-admin">

    <div class="card">
        <div class="card-header">
            Find an Order by ID
        </div>
        <div class="card-block p-3">
            <spinner v-show="isLoading"></spinner>
            <div class="col-2">
                <form>
                    <div class="form-group">
                        <label for="searchOrderId">Order ID</label>
                        <input v-model="searchOrderId" type="text" size="50" class="form-control" id="searchOrderId" placeholder="Enter Zinc Order ID">
                    </div>
                    <div class="form-group">
                        <label for="searchEbayTransactionId">eBay Transaction ID</label>
                        <input v-model="searchEbayTransactionId" type="text" size="50" class="form-control" id="searchEbayTransactionId" placeholder="Enter eBay Transaction ID">
                    </div>
                    <div class="form-group">
                        <label for="searchMerchantOrderId">Merchant Order ID</label>
                        <input v-model="searchMerchantOrderId" type="text" size="50" class="form-control" id="searchMerchantOrderId" placeholder="Enter Merchant Order ID">
                    </div>
                    <div class="form-check">
                        <input v-model="getAddaxChildOrder" type="checkbox" class="form-check-input" id="getAddaxChildOrder">
                        <label class="form-check-label" for="getAddaxChildOrder">Find addax child order?</label>
                    </div>
                    <div class="mt-3">
                        <button type="submit" class="btn btn-primary" @click.prevent="getOrderData(searchOrderId, searchEbayTransactionId, searchMerchantOrderId, getAddaxChildOrder)">Search</button>
                    </div>
                </form>

            </div>


            <!-- <div class="form-inline mt-2 mt-md-0 px-3">
                <button class="btn btn-primary" @click="getBalance()">Get balances</button>
            </div> -->
        </div>

    </div>

    <div v-if="foundOrder" class="card">
        <div class="card-header">
            Found order
        </div>
        <div class="card-block p-3">
            <template v-if="has(foundOrder, 'request_id')">
            <p><a target="_blank" :href="`https://${user.zincClientToken}:@api.zinc.io/v1/orders/${get(foundOrder, 'encrypted_request_id')}`">Raw JSON</a></p>
            <p><a target="_blank" :href="`https://papertrailapp.com/groups/1467904/events?q=${get(foundOrder, 'request_id')}&t=${moment(get(foundOrder, 'order_request._created_at')).unix()}`">Papertrail logs</a></p>
            <p v-if="getLogPath(foundOrder)"><a target="_blank" :href="`/api/v0/logs/${getLogPath(foundOrder)}`">Arsenic logs</a></p>
            <pre>{{foundOrder}}</pre>
            </template>
        </div>

    </div>

</div>
</template>

<script>
import Auth from '@/auth'
import Spinner from '../common/Spinner.vue'
import router from '@/router'
const addaxCurrencies = require('../common/addaxCurrencies.data.js')
const marketplaces = require('../common/marketplaces.data.js')
const tz = moment.tz.guess()

export default {
  name: 'admin-order-finder',
  components: { Spinner },
  props: [
    'order_id',
    'ebay_transaction_id',
    'merchant_order_id',
    'addax_child',
  ],
  data() {
    return {
      auth: this.$store.state.auth,
      user: this.$store.state.currentUser,
      currentClientToken: this.$store.state.currentUser.zincClientToken,
      isLoading: false,
      addaxCurrencies: addaxCurrencies,
      marketplaces: marketplaces,
      searchOrderId: '',
      searchEbayTransactionId: '',
      searchMerchantOrderId: '',
      foundOrder: '',
      getAddaxChildOrder: false,
    }
  },
  created() {
    if (this.$route.query.order_id) {
      this.searchOrderId = this.$route.query.order_id
    }

    if (this.$route.query.ebay_transaction_id) {
      this.searchEbayTransactionId = this.$route.query.ebay_transaction_id
    }

    if (this.$route.query.merchant_order_id) {
      this.searchMerchantOrderId = this.$route.query.merchant_order_id
    }

    if (this.$route.query.addax_child) {
      this.getAddaxChildOrder = this.$route.query.addax_child
    }

    if (
      this.searchOrderId ||
      this.searchEbayTransactionId ||
      this.searchMerchantOrderId
    ) {
      this.getOrderData(
        this.searchOrderId,
        this.searchEbayTransactionId,
        this.searchMerchantOrderId,
        this.getAddaxChildOrder
      )
    }
  },
  methods: {
    get: _.get,
    has: _.has,
    moment: moment,
    getLogPath(order) {
      try {
        return (order.order_response || order.error_response)._zinc_logfile
          .split('/')
          .slice(-1)[0]
      } catch (e) {
        return null
      }
    },
    getOrderData(
      searchOrderId,
      searchEbayTransactionId,
      searchMerchantOrderId,
      getAddaxChildOrder
    ) {
      if (
        !(searchOrderId || searchEbayTransactionId || searchMerchantOrderId)
      ) {
        return false
      }

      this.isLoading = true

      var routerArgs = {}

      var qsaBits = []
      if (searchOrderId) {
        qsaBits.push(`order_id=${searchOrderId}`)
        routerArgs['order_id'] = searchOrderId
      }
      if (searchEbayTransactionId) {
        qsaBits.push(`ebay_transaction_id=${searchEbayTransactionId}`)
        routerArgs['ebay_transaction_id'] = searchEbayTransactionId
      }
      if (searchMerchantOrderId) {
        qsaBits.push(`merchant_order_id=${searchMerchantOrderId}`)
        routerArgs['merchant_order_id'] = searchMerchantOrderId
      }
      if (getAddaxChildOrder) {
        qsaBits.push(`addax_child=true`)
        routerArgs['addax_child'] = true
      }

      router.push({ path: this.$route.path, query: routerArgs })

      var qsa = qsaBits.join('&')

      var headers = {
        Authorization: `Basic ${btoa(this.currentClientToken)}:`,
      }

      this.$http
        .get(`/api/v0/adminFindOrder?${qsa}`)
        .then(response => {
          this.isLoading = false
          this.foundOrder = response.body
        })
        .catch(response => {
          this.$auth.logout()
        })
    },
    viewOrder: function(zincOrderId) {
      // event.preventDefault()
      this.$router.push({
        name: 'view-order',
        params: {
          zincUserId: this.user.zincUserId,
          zincOrderId: zincOrderId,
        },
      })
    },
  },
}
</script>


<style lang="scss">

</style>
